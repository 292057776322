import { GA4Report, GetGA4Info } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA
const url = process.env.REACT_APP_GA4_URL
const apiKey = process.env.REACT_APP_API_KEY

export const ToolsIntegrationTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Google Ads and Search Console are integrated with your Google Analytics 4 property",
        no_data: "No data available.",
        no_permission: "No permission",
        test1_summary: `Google Ads is not linked to your Google Analytics 4 property. To better understand user acquisition, we recommended integrating the two tools together. Learn more about <a href='https://support.google.com/analytics/answer/9379420' target="blank" >GA4-Google Ads integration</a>.`,
        test2_summary: `Search Console is not linked to your Google Analytics 4 property. To better understand user acquisition, we recommended claiming your search console property and integrate it with your Google Analytics property. Learn more about <a href='https://support.google.com/analytics/answer/10737381' target="blank" >GA4-Search Console integration</a>.`,
        test1_extra: "Linking Google Analytics 4 to your Google Ads account allows you to see your Google Ads campaigns in the Acquisition overview and Attribution reports, access new Google Ads dimensions in the User acquisition report, import Analytics conversions into your Google Ads account, and enhance your Google Ads remarketing with Analytics audience data.",
        test2_extra: "Integrate GA4 with Search Console so you can analyze organic search related to your site, where you site is ranked in search results, which queries lead to clicks, and how those clicks translate to user behavior.",
    }
    let paramsAll = { key: apiKey }

    // test 1: google link
    const googleAdsLinksList = await GetGA4Info(`${url}/${propertyId}/googleAdsLinks`,
        paramsAll, accessToken)

    let test1 = false, test2 = false;
    if (googleAdsLinksList.code) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'GA08', tool: 'GA4', difficulty_level: 1, fail_summary: `${reasons.no_permission} for google ads links`, pass_summary: '', fail_extra: '' }
        return data;
    }
    else if (JSON.stringify(googleAdsLinksList) === '{}') {
        test1 = false;
    } else { test1 = true; }

    // test 2: for search console 
    const body = {
        "dateRanges": [{ "startDate": "60daysAgo", "endDate": "yesterday" }],
        "dimensions": [{ "name": "date" }],
        "metrics": [{ "name": "organicGoogleSearchImpressions" }]
    };
    let result = await GA4Report(`${URL}/${propertyId}:runReport`, body, accessToken)

    if (result.code) {
        if(result?.response?.data?.error?.message==='Search Console fields require an active link to be used.'){
            test2=false
        } else {
            let data = { test_name: 'Tools Integration', status: false, test_id: 'GA08', tool: 'GA4', difficulty_level: 1, fail_summary: `${reasons.no_permission} for search console.`, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    else if (!result || !result.data.rows) {
        test2 = false
    } else {
         test2 = true
         }
    if (!test1 && !test2) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'GA08', tool: 'GA4', difficulty_level: 1, fail_summary: `${reasons.test1_summary} ${reasons.test2_summary}`, pass_summary: '', fail_extra: `${reasons.test1_extra} ${reasons.test2_extra}` }
        return data;
    }
    if (test1 && !test2) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'GA08', tool: 'GA4', difficulty_level: 1, fail_summary: `Google Ads is linked but ${reasons.test2_summary}`, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }
    if (!test1 && test2) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'GA08', tool: 'GA4', difficulty_level: 1, fail_summary: `Search Console is linked but ${reasons.test1_summary}`, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    let data = { test_name: 'Tools Integration', status: true, test_id: 'GA08', tool: 'GA4', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
