import { GA4Report } from '../../components/Api';
import { backendApi } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA
const serverUrl = process.env.REACT_APP_SERVER_URL

export const GTMDeploymentGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Google Tag Manager is installed on every page of your website.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Google Tag Manager is not detected on your website.",
        test2_summary: "We detected pages that are not installed with a GTM container",
        test3_summary: "We detected different GTM containers deployed across your pages",
        test4_summary: "We detected multiple GTM containers deployed on your pages",
        test1_extra: "Google Tag Manager allows for quick and easy deployment of tracking codes in a single place using built-in assets and custom templates-at no cost and no hard coding after Google Tag Manager is installed.",
        test2_extra: "Google Tag Manager allows for quick and easy deployment of tracking codes in a single place using built-in assets and custom templates-at no cost and no hard coding after Google Tag Manager is installed.",
        test3_extra: "Google Tag Manager allows for quick and easy deployment of tracking codes in a single place using built-in assets and custom templates-at no cost and no hard coding after Google Tag Manager is installed.",
        test4_extra: "Google Tag Manager allows for quick and easy deployment of tracking codes in a single place using built-in assets and custom templates-at no cost and no hard coding after Google Tag Manager is installed. "
    }
    const params = JSON.stringify({
        "dateRanges": [{ "startDate": "30daysAgo", "endDate": "yesterday", }],
        "dimensions": [{ "name": "hostName" }, { "name": "unifiedPagePathScreen" }],
        "metrics": [{ "name": "sessions" },],
        "orderBys": [{ "desc": true, "metric": { "metricName": "sessions" } }]
    });
    let start = new Date();

    let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData1?.code) {
        let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!resultData1 || !resultData1?.data || !resultData1?.data?.rows) {
        let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData1?.data?.rows;
    if (obj.length < 1) {
        let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    // getting all host name
    let hostNameAll = []
    obj.forEach(element => {
        hostNameAll.push(element.dimensionValues[0].value)
    });
    // getting unique host name
    var hostNameUnique = new Set(hostNameAll);
    var hostNameUniqueList = Array.from(hostNameUnique);;
    let sum = 0;
    obj.forEach(element => {
        sum += parseInt(element.metricValues[0].value);
    });

    let hostNameTop5 = [];
    let hostNameNext = []
    let newHostNameList = []
    hostNameUniqueList.forEach(hostname => {
        let sum1 = 0;
        obj.forEach(element => {
            if (hostname === element.dimensionValues[0].value) {
                sum1 += parseInt(element.metricValues[0].value);
            }
        });
        // more than 10% traffic on host
        if (sum1 > sum * 0.1) {
            let leng = obj.length, count = 0;
            for (let l = 0; l < leng; l++) {
                if (hostname === obj[l].dimensionValues[0].value && count < 5) {
                    hostNameTop5.push([hostname, obj[l].dimensionValues[1].value])
                    count++;
                }
                else if (hostname === obj[l].dimensionValues[0].value && count == 5) {
                    hostNameNext.push([hostname, obj[l].dimensionValues[1].value])
                }
            };
            newHostNameList.push(hostname);
        }
    })

    let listOfPageCrawled = [], breakCondition = false;
    // Test 1
    for (let b = 0; b < newHostNameList.length; b++) {
        let checkList = []
        for (let k = 0; k < hostNameTop5.length; k++) {
            if (newHostNameList[b] === hostNameTop5[k][0]) {
                if (!hostNameTop5[k][0].startsWith('(') && !hostNameTop5[k][0].endsWith(')') && !hostNameTop5[k][1].startsWith('(') && !hostNameTop5[k][1].endsWith(')')) {
                    let pagepath = `https://${hostNameTop5[k][0]}${hostNameTop5[k][1]}`
                    let body = { host: pagepath }
                    let getData = await backendApi(`${serverUrl}api/user/webscrap`, body, accessToken)
                    let newDate = new Date()
                    let diffMs = Math.abs(new Date(newDate) - start);
                    let seconds = Math.floor((diffMs / 1000));
                    breakCondition = (seconds > 120)

                    if (getData.code === 200 && !breakCondition) {
                        if (breakCondition == false) {
                            listOfPageCrawled.push(hostNameTop5[k]);
                        }
                        // test 2 
                        if (!getData.gtm) {
                            let test2_summary_data = `We detected pages that do not have a GTM container ${hostNameTop5[k][0]}${hostNameTop5[k][1]}.`

                            let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: test2_summary_data, pass_summary: '', fail_extra: reasons.test2_extra }
                            return data;
                        }
                        // test 4
                        if (getData.container_count > 1) {
                            let test4_summary_data = `${reasons.test4_summary} ${getData.gtm_container}.`

                            let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: test4_summary_data, pass_summary: '', fail_extra: reasons.test4_extra }
                            return data;
                        }
                        checkList.push(getData.gtm_container)
                    } else {
                        if (!breakCondition) {
                            if (getData.code == 404) { console.log("404") }
                            continue;
                        } else {
                            console.log("timeout 120 secs")
                            let checkFinal = new Set(checkList);
                            //test 3 checking
                            if (checkFinal.length > 1) {
                                let test3_summary_data = `We detected different GTM containers deployed across your pages ${checkFinal}.`

                                let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: test3_summary_data, pass_summary: '', fail_extra: reasons.test3_extra }
                                return data;
                            } else {
                                if (listOfPageCrawled.length > 0) {
                                    let pass_summary_data = `Google Tag Manager is deployed in the ${listOfPageCrawled.length} pages we crawled.`
                                    let data = { test_name: 'Google Tag Manager Deployment', status: true, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: pass_summary_data, fail_extra: '' }
                                    return data;
                                } else {
                                    let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: 'Check My Analytics could not crawl and check any of the pages listed in your analytics property.', pass_summary: '', fail_extra: '' }
                                    return data;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    if (!breakCondition) {
        for (let b = 0; b < newHostNameList.length; b++) {
            let checkList = []
            for (let k = 0; k < hostNameNext.length; k++) {
                if (newHostNameList[b] === hostNameNext[k][0]) {
                    if (!hostNameNext[k][0].startsWith('(') && !hostNameNext[k][0].endsWith(')') && !hostNameNext[k][1].startsWith('(') && !hostNameNext[k][1].endsWith(')')) {
                        let pagepath = `https://${hostNameNext[k][0]}${hostNameNext[k][1]}`
                        let body = { host: pagepath }
                        let getData = await backendApi(`${serverUrl}api/user/webscrap`, body, accessToken)
                        let newDate = new Date()
                        let diffMs = Math.abs(new Date(newDate) - start);
                        let seconds = Math.floor((diffMs / 1000));
                        breakCondition = (seconds > 120)
                        if (getData.code === 200 && !breakCondition) {
                            listOfPageCrawled.push(hostNameNext[k]);
                            // test 2 
                            if (!getData.gtm) {
                                let test2_summary_data = `We detected pages that do not have a GTM container ${hostNameNext[k][0]}${hostNameNext[k][1]}.`

                                let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: test2_summary_data, pass_summary: '', fail_extra: reasons.test2_extra }
                                return data;
                            }
                            // test 4
                            if (getData.container_count > 1) {
                                let test4_summary_data = `${reasons.test4_summary} ${getData.gtm_container}.`

                                let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: test4_summary_data, pass_summary: '', fail_extra: reasons.test4_extra }
                                return data;
                            }
                            else {
                                checkList.push(getData.gtm_container)
                            }
                        } else {
                            if (!breakCondition) {
                                if (getData.code == 404) { console.log("404") }
                                continue;
                            }
                            console.log("timeout 120 secs")
                            var checkFinal = new Set(checkList);
                            //test 3 checking
                            if (checkFinal.length > 1) {
                                let test3_summary_data = `We detected different GTM containers deployed across your pages ${checkFinal}.`

                                let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: test3_summary_data, pass_summary: '', fail_extra: reasons.test3_extra }
                                return data;
                            } else {
                                if (listOfPageCrawled.length > 0) {
                                    let pass_summary_data = `Google Tag Manager is deployed in the ${listOfPageCrawled.length} pages we crawled.`
                                    let data = { test_name: 'Google Tag Manager Deployment', status: true, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: pass_summary_data, fail_extra: '' }
                                    return data;
                                } else {
                                    let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: 'Check My Analytics could not crawl and check any of the pages listed in your analytics property.', pass_summary: '', fail_extra: '' }
                                    return data;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    if (listOfPageCrawled.length > 0) {
        let pass_summary_data = `Google Tag Manager is deployed in the ${listOfPageCrawled.length} pages we crawled.`
        let data = { test_name: 'Google Tag Manager Deployment', status: true, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: pass_summary_data, fail_extra: '' }
        return data;
    } else {
        let data = { test_name: 'Google Tag Manager Deployment', status: false, test_id: 'GA21', tool: 'GA4', difficulty_level: 2, fail_summary: 'Check My Analytics could not crawl and check any of the pages listed in your analytics property.', pass_summary: '', fail_extra: '' }
        return data;
    }
}