import { getUAReport, UAReport } from '../../components/Api';
const url = process.env.REACT_APP_ACCOUNT_API
const batchUrl = process.env.REACT_APP_BATCH_API

export const ToolsIntegrationTest = async (accountId, webPropertyId, profileId, accessToken) => {
    let reasons = {
        pass_summary: "Google Ads and Search Console are integrated with your Universal Analytics view",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Google Ads is not linked to your Google Analytics property. To better understand user acquisition, we recommended integrating the two tools together. Learn more about it <a href='https://support.google.com/analytics/answer/9379420' target="_blank" >here</a>.`,
        test2_summary: `You have not linked Search Console to your Google Analytics property. To better understand user acquisition, we recommended claiming your search console property and integrates it with your Analytics property. Learn more about it <a href='https://support.google.com/analytics/answer/1308621' target="_blank" >here</a>.`,
        test1_extra: "Linking Universal Analytics property to your Google Ads account allows you to see ad and site performance data in the Google Ads reports, import Analytics goals and ecommerce transactions into your Google Ads account, import cross-device conversions into your Google Ads account when you activate Google signals, import Analytics metrics like Bounce Rate, Avg. Session Duration, and Pages/Session into your Google Ads account, enhance your Google Ads remarketing with Analytics Remarketing and Dynamic Remarketing, and get richer data in the Analytics Multi-Channel Funnels reports.",
        test2_extra: "Integrate Universal Analytics with Search Console so you can analyze organic search related to your site, where you site is ranked in search results, which queries lead to clicks, and how those clicks translate to user behavior."
    }
    let resultData = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/entityAdWordsLinks`, accessToken)

    let test1 = false, test2 = false;
    if (!resultData || JSON.stringify(resultData) === "{}") {
        test1 = false;
    } else { test1 = true }

    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": profileId,
                    "metrics": [{ "expression": "ga:organicSearches" }],
                    "dimensions": [{ "name": "ga:date" }],
                    "dateRanges": [
                        {
                            "startDate": "60daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });
    let searchConsole = await UAReport(batchUrl, params, accessToken)
    if (!searchConsole) {
        test2 = false;
    } else {
        test2 = true
    }
    if (!test1 && !test2) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'UA08', tool: 'UA', difficulty_level: 1, fail_summary: `${reasons.test1_summary} ${reasons.test2_summary}`, pass_summary: '', fail_extra: `${reasons.test1_extra} ${reasons.test2_extra}` }
        return data;
    }
    else if (test1 && !test2) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'UA08', tool: 'UA', difficulty_level: 1, fail_summary: `Google Ads is linked but ${reasons.test2_summary}`, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    } else if (!test1 && test2) {
        let data = { test_name: 'Tools Integration', status: false, test_id: 'UA08', tool: 'UA', difficulty_level: 1, fail_summary: `Search Console is linked but ${reasons.test1_summary}`, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }

    let data = { test_name: 'Tools Integration', status: true, test_id: 'UA08', tool: 'UA', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
