import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PageHead = () => {
  return (
    <Box className="pageHead-section">
      <Container>
        <Typography className="pageTitle" variant="h1">
          Contact us
        </Typography>
      </Container>
    </Box>
  );
};

export default PageHead;
