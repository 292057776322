import {useEffect, React} from 'react';
import {useTheme, Box} from '@mui/material';
import Main from '../../layouts/Main/Main';
import PageHead from './components/PageHead';
import Content from './components/Content';

const Contact = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Main>
      <Box>
        <Box>
          <PageHead />
        </Box>
        <Box>
          <Content />
        </Box>
      </Box>
    </Main>
  );
};

export default Contact;
