/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {Box, Typography, Avatar, Grid, Container} from '@mui/material';
import dataPrivacy_icon1 from '../../../../assets/images/dataprivacy_icon_1.png';
import dataPrivacy_icon2 from '../../../../assets/images/dataprivacy_icon_2.png';
import dataPrivacy_icon3 from '../../../../assets/images/dataprivacy_icon_3.png';

const mock = [
  {
    title: 'Our Commitment to Data Privacy',
    subtitle:
      'We use Industry standard OAuth 2 to access your data.',
    avatar: dataPrivacy_icon1,
  },
  {
    title: 'No Storage or Reselling of Your Analytics Data',
    subtitle:
      'Your Data Remains Private and Confidential.',
    avatar: dataPrivacy_icon2,
  },
  {
    title: 'Compliance with Data Protection Laws and Regulations',
    subtitle:
      'We are GDPR and California Privacy Act compliant.',
    avatar: dataPrivacy_icon3,
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    ),
  },
];

const Services = () => {
  const theme = useTheme();
  return (
    <Box>
      <Container className='space'>
        <Box marginBottom={4}>
            <Box>
              <Typography
                variant="h3"
                align={'center'}
                marginBottom={1.2}
                sx={{
                  fontWeight: 700,
                }}
              >
                Data Privacy
              </Typography>
              <Typography
                component="p"
                fontSize={{xs:16, sm:18, md:20}}
                sx={{ 
                  fontWeight: 400,
                  marginX: 'auto',
                  maxWidth: 800
                }}
                align={'center'}
              >
                Your data is safe with us
              </Typography>
            </Box>
        </Box>
        <Grid container spacing={2}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Box>
                <Box 
                  textAlign={'center'}
                  paddingX={{sm: 1, md: 4, lg: 5}}
                  marginBottom={{xs: 2, sm: 0}}
                >
                  <Box
                    marginBottom={2}
                  >
                    <Box
                      maxWidth={1}
                      width={'auto'}
                      height={{xs:80, sm:100}}
                      component={'img'}
                      src={item.avatar}
                    />
                  </Box>
                  <Typography
                    variant={'h4'}
                    fontSize={{xs:22, md:25}}
                    marginBottom={2}
                    fontWeight={600}
                  >
                    {item.title}
                  </Typography>
                  <Typography>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Services;
