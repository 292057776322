import {useEffect, React} from 'react';
import Main from '../../layouts/Main';
import {Box, Button, Typography, Container} from '@mui/material';
import PageHead from './components/PageHead';
import Content from './components/content';
import PoweredBy from './../Poweredby/PoweredBy';

const ConfirmDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return(
        <Main>
            <Box>
                <Box>
                    <PageHead />
                </Box>
                <Box>
                    <Content />
                </Box>
                <Box>
                    <PoweredBy />
                </Box>
            </Box>
      </Main>
    );
};

export default ConfirmDetails;