import { getUAReport } from '../../components/Api';
const url = process.env.REACT_APP_ACCOUNT_API
export const CustomDefinitionsTest = async (accountId, webPropertyId, accessToken) => {
  let reasons = {
    pass_summary: "Your Universal Analytics property is using custom dimensions and/or metrics.",
    no_data: "No data available.",
    no_permission: "No permission.",
    test1_summary: `Your Universal Analytics property is not using any custom dimensions or metrics. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`,
    test2_summary: `Your Universal Analytics property is not using any custom dimensions or metrics. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`,
    test1_extra: "Custom definitions can be extremely useful in analyzing data that Analytics doesn't automatically collect. We highly recommend creating dimensions and metrics from your event parameters and user properties so you can easily access data in reports and explorations.",
    test2_extra: "Custom definitions can be extremely useful in analyzing data that Analytics doesn't automatically collect. We highly recommend creating dimensions and metrics from your event parameters and user properties so you can easily access data in reports and explorations."
  }
  let customDimensions = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/customDimensions`, accessToken)
  let customMetrics = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/customMetrics`, accessToken)

  let resultDimensions = true
  let resultMetrics = true

  if (customDimensions?.totalResults > 0) {
    for (let i = 0; i < customDimensions.items.length; i++) {
      let active = customDimensions.items[i]?.active
      if (!active) {
        resultDimensions = false
      }
    }
  }

  if (customMetrics?.totalResults > 0) {
    for (let i = 0; i < customMetrics.items.length; i++) {
      let active = customMetrics.items[i]?.active
      if (!active) {
        resultMetrics = false
      }
    }
  }

  if (!(customDimensions?.totalResults > 0) && !(customMetrics?.totalResults > 0)) {
    let data = { test_name: 'Custom Definitions', status: false, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: `Your Universal Analytics property is not using any custom dimensions and metrics. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`, pass_summary: '', fail_extra: reasons.test1_extra }
    return data
  } else if ((customDimensions?.totalResults > 0) && !(customMetrics?.totalResults > 0)) {
    let data = { test_name: 'Custom Definitions', status: false, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: `Your Universal Analytics property is not using any custom metrics. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`, pass_summary: '', fail_extra: reasons.test1_extra }
    return data;
  } else if (!(customDimensions?.totalResults > 0) && (customMetrics?.totalResults > 0)) {
    let data = { test_name: 'Custom Definitions', status: false, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: `Your Universal Analytics property is not using any custom dimensions. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`, pass_summary: '', fail_extra: reasons.test1_extra }
    return data;
  } else if ((customDimensions?.totalResults > 0) && !resultDimensions && (customMetrics?.totalResults > 0) && !resultMetrics) {
    let data = { test_name: 'Custom Definitions', status: false, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: `All custom dimensions and custom metrics are not active. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`, pass_summary: '', fail_extra: reasons.test1_extra }
    return data;
  } else if ((customDimensions?.totalResults > 0) && !resultDimensions) {
    let data = { test_name: 'Custom Definitions', status: false, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: `All custom dimensions are not active. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`, pass_summary: '', fail_extra: reasons.test1_extra }
    return data;
  }
  else if ((customMetrics?.totalResults > 0) && !resultMetrics) {
    let data = { test_name: 'Custom Definitions', status: false, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: `All custom metrics are not active. Learn more about custom definitions <a href='https://support.google.com/analytics/answer/2709829' target="_blank" >here</a>.`, pass_summary: '', fail_extra: reasons.test1_extra }
    return data;
  }

  if ((customDimensions?.totalResults > 0 && customMetrics?.totalResults > 0) && resultMetrics && resultDimensions) {
    let data = { test_name: 'Custom Definitions', status: true, test_id: 'UA07', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
  }

}
