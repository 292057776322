import { GA4Report, GetGA4Info } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA
const adminURL = process.env.REACT_APP_GA4_URL
const apiKey = process.env.REACT_APP_API_KEY

export const ConversionsTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Conversion events are set and active in your Google Analytics 4 property.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `There are no conversion events set in your Google Analytics 4 property. Learn more about <a href='https://support.google.com/analytics/answer/12844695' target="_blank" >Google Analytics 4 conversion events </a>.`,
        test2_summary: "These conversion events are not reporting any conversion in the last 30 days:",
        test1_extra: "Conversion events provide insights into interactions that are important to your business and allow you to use that data for your marketing efforts.",
        test2_extra: `Make sure that the conversion events match the existing events on which they are based. Learn more about <a href='https://support.google.com/analytics/answer/12844695' target='_blank' >Google Analytics 4 conversion events </a>.`,
    }

    let paramsAll = { key: apiKey }
    const resultData = await GetGA4Info(`${adminURL}/${propertyId}/conversionEvents`,
        paramsAll, accessToken)

    if (resultData.code) {
        let data = { test_name: 'Conversions', status: false, test_id: 'GA04', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!resultData || !resultData.conversionEvents) {
        let data = { test_name: 'Conversions', status: false, test_id: 'GA04', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData?.conversionEvents
    // test 1 checking for conversion event name or set 
    let conversionList = []
    for (let i = 0; i < obj.length; i++) {
        if (obj[i]?.custom == true) {
            conversionList.push(obj[i].eventName)
        }
    }
    if (conversionList.length < 1) {
        let data = { test_name: 'Conversions', status: false, test_id: 'GA04', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    else {
        let conversionFailList = []
        for (let i = 0; i < conversionList.length; i++) {
            const params1 = JSON.stringify({
                "dateRanges": [
                    {
                        "startDate": "30daysAgo",
                        "endDate": "yesterday",
                    }
                ],
                "metrics": [
                    {
                        "name": `conversions:${conversionList[i]}`
                    }
                ]
            });

            let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, params1, accessToken)
            if (resultData1.code) {
                let data = { test_name: 'Conversions', status: false, test_id: 'GA04', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
                return data;
            }
            if (!resultData1 || !resultData1.data || !resultData1.data.rows) {
                // checking for the conversion event has at least 1 conversion in last 30 days
                conversionFailList.push(conversionList[i])
            }
        }

        // test 2 
        if (conversionFailList.length > 0) {
            let test2_summary_data = `${reasons.test2_summary} ${conversionFailList}.`
            let data = { test_name: 'Conversions', status: false, test_id: 'GA04', tool: 'GA4', difficulty_level: 1, fail_summary: test2_summary_data, pass_summary: '', fail_extra: reasons.test2_extra }
            return data;
        } else {
            let data = { test_name: 'Conversions', status: true, test_id: 'GA04', tool: 'GA4', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
            return data;
        }
    }
}
