import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PageHead = () => {
  return (
    <Box className="pageHead-section">
      <Container>
        <Typography className="pageTitle" variant="h2" fontSize={{xs:24, sm:30, sm:36}} fontWeight={600} lineHeight={1.4} minHeight={{sm:170}} display={'flex'} alignItems={'center'}>
          Tell us a little more about your Google analytics configuration
        </Typography>
      </Container>
    </Box>
  );
};

export default PageHead;
