import { UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API
export const CampaignTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "Campaign tracking is properly set up. UTM parameters are properly used to track visitors coming from marketing campaigns. ",
        no_data: "No data available",
        no_permission: "No permission.",
        test1_summary: `UTM parameters are not used. Learn more about custom URLs <a href='https://support.google.com/analytics/answer/1033867' target="_blank" >here</a>.`,
        test2_summary: "Duplicates have been detected in source/medium and/or campaign. ",
        test3_summary: "Click parameters, e.g. fbclid, gclid, have been detected in your Google Analytics data.",
        test1_extra: "Custom campaigns (UTM parameters) allow you to track and measure the effectiveness of your marketing campaigns and traffic sources. This information can be extremely valuable in identifying where future marketing spending should go.",
        test2_extra: "Custom campaigns (UTM parameters) allow you to track and measure the effectiveness of your marketing campaigns and traffic sources. This information can be extremely valuable in identifying where future marketing spending should go.",
        test3_extra: "Custom campaigns (UTM parameters) allow you to track and measure the effectiveness of your marketing campaigns and traffic sources. This information can be extremely valuable in identifying where future marketing spending should go."
    }
    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:campaign" }],
                    "dateRanges": [
                        {
                            "startDate": "60daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });
    const sourceMediumParams = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:sourceMedium" }],
                    "dateRanges": [
                        {
                            "startDate": "60daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });
    let resultData = await UAReportBehaviourLandings(url, params, accessToken)

    let sourceMediumData = await UAReportBehaviourLandings(url, sourceMediumParams, accessToken);

    if (resultData || sourceMediumData) {
        if (resultData.code || sourceMediumData.code) {
            let data = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if ((!resultData || Object.keys(resultData).length === 0 || !resultData?.rowCount) || (!sourceMediumData || Object.keys(sourceMediumData).length === 0 || !sourceMediumData?.rowCount)) {
        let data = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    const campaignIds = ["?fbclid", "?gclid", "?dc_cid", "?cid", "tid", "?msclkid", "?utm_medium", "?utm_source", "?utm_term", "?utm_campaign", "?utm_content", "?utm_id"];
    // Test 1: utm parameter are set  
    if ((resultData.rowCount && resultData.rows) || (sourceMediumData.rowCount && sourceMediumData.rows)) {
        if (resultData.rows[0].dimensions) {
            let test1 = 0, test3 = 1;
            let sourceMedium = [], campaign = [];
            let resultObj = resultData.rows;
            let sourceObj = sourceMediumData.rows;

            resultObj.forEach(element => {
                if (element.dimensions[1] != '(not set)') {
                    campaign.push(element.dimensions[0].toLowerCase().replaceAll(" ", ""))
                }
            });
            sourceObj.forEach(element => {
                sourceMedium.push(element.dimensions[0].toLowerCase().replaceAll(" ", ""))
            });
            if (campaign.length > 0 && sourceMedium.length > 0) {
                test1 = 1
            }

            // Test 1:
            if (!test1) {
                let data1 = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
                return data1;
            }

            // Test 2: 
            if (hasDuplicates(sourceMedium) || hasDuplicates(campaign)) {
                let data1 = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test2_summary, pass_summary: '', fail_extra: reasons.test2_extra }
                return data1;
            }
            const paramsPagePath = JSON.stringify({
                "reportRequests":
                    [
                        {
                            "viewId": viewId,
                            "metrics": [{ "expression": "ga:hits" }],
                            "dimensions": [{ "name": "ga:pagePath" },
                            { "name": "ga:screenName" }],
                            "dateRanges": [
                                {
                                    "startDate": "60daysAgo",
                                    "endDate": "yesterday"
                                }
                            ]
                        }
                    ]
            });

            let pagePathResult = await UAReportBehaviourLandings(url, paramsPagePath, accessToken)
            if (pagePathResult?.rowCount && pagePathResult?.rows) {
                let Obj = pagePathResult.rows;
                Obj.forEach(element => {
                    if (element.dimensions[0] != '') {
                        let dimensions = element.dimensions[0]
                        let isTrue = campaignIds.some(v => dimensions?.includes(v))
                        if (isTrue) {
                            test3 = 0;
                        }
                    }
                });
            } else {
                let data = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: `${reasons.no_data} page path`, pass_summary: '', fail_extra: '' }
                return data;
            }

            // Test 3:
            if (!test3) {
                let data1 = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test3_summary, pass_summary: '', fail_extra: reasons.test3_extra }
                return data1;
            } else {
                let data = { test_name: 'Campaigns', status: true, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
                return data;
            }
        }
        else {
            let data = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
            return data;
        }
    }
    else {
        let data = { test_name: 'Campaigns', status: false, test_id: 'UA10', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
}
