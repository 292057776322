import React from 'react';
import poweredByLogo from './../../assets/images/powered_by_logo.png';
import arrowShap2 from './../../assets/images/testimanials_arrowshap2.png';
import {Container, Typography, Box } from '@mui/material';

const PoweredBy = () => {
    return (
        <Box className="poweredby-section">
            <Container className='b-space'>
                <Box paddingX={{md:10}}>
                    <Box className="bg-primary-gray" padding={{xs:4, sm:6, md:8}}>
                        <Box>
                            <Typography
                            className='text-primary'
                            variant="h4"
                            gutterBottom
                            fontSize={26}
                            sx={{
                                fontWeight: 700,
                            }}
                            >
                            Powered by
                            </Typography>
                        </Box>
                        <Box>
                            <Box>
                                <Box
                                    component={'img'}
                                    src={poweredByLogo}
                                    width={{xs:200, sm:268}}
                                    marginTop={0.5}
                                />
                                <Box
                                    className='powered-shap'
                                    component={'img'}
                                    src={arrowShap2}
                                    maxWidth={1}
                                />
                            </Box>
                            <Box position={'relative'} paddingLeft={{xs:0, md:5}} marginTop={3}>
                                    <Typography
                                    variant="p"
                                    component="p"
                                    fontSize={{ xs: 16, sm: 19 }}
                                    fontWeight={300}
                                    // gutterBottom
                                    color="white"
                                    >We are a global digital marketing agency obsessed with finding better ways to improve our clients’ digital marketing performance. We bring an engineering mindset to marketing solutions and built Check My Analytics out of frustration of having to perform the same checks again and again. We are a Google Marketing Platform certified company. Our analytics team includes ex-Googlers and trainers certified by Google, who have migrated hundreds of websites to Google Analytics 4 (GA4).</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
        );
    };
export default PoweredBy;