import { GA4Report } from '../../components/Api';

const URL = process.env.REACT_APP_GA4_URL_DATA

export const ReferralDropOffTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "There is no significant decrease of traffic from your top referral sources in the past 7 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "There has been a significant decrease of traffic from these top referral sources in the past 7 days: [INSERT REFERRAL SOURCE/S]. This may indicate that they are no longer linking to your site.",
        test1_extra: "If a major referral source removed links to your site, you will no longer be receiving traffic from that source. If the traffic from that source was high-quality (that is, high conversion rate), then you may want to attempt to get your site relinked from that source.",
    }
    const params = JSON.stringify({
        "dimensions": [
            { "name": "sessionSource" }
        ],
        "metrics": [
            { "name": "sessions" }
        ],
        "dateRanges": [{
            "startDate": "60daysAgo",
            "endDate": "yesterday"
        }
        ]
    });

    let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData1.code) {
        let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'GA18', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data1;
    }
    if (!resultData1 || !resultData1.data || !resultData1.data.rows) {
        let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'GA18', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    let obj = resultData1.data.rows
    let totals = 0;
    obj.forEach(element => {
        totals += parseInt(element.metricValues[0].value)
    });

    const params1 = JSON.stringify({
        "dimensions": [
            { "name": "sessionSource" }
        ],
        "metrics": [
            { "name": "sessions" }
        ],
        "dateRanges": [{
            "startDate": "7daysAgo",
            "endDate": "yesterday"
        }
        ]
    });

    let resultData2 = await GA4Report(`${URL}/${propertyId}:runReport`, params1, accessToken)
    if (resultData2.code) {
        let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'GA18', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data1;
    }
    if (!resultData2 || !resultData2?.data || !resultData2?.data?.rows) {
        let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'GA18', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    let obj1 = resultData2.data.rows
    // checking for more than 10% traffic in last 60days
    let topReferral = []
    obj.forEach(element => {
        if (parseFloat(element.metricValues[0].value) > parseFloat(totals * 0.1)) {
            obj1.forEach((elm1) => {
                if (elm1.dimensionValues[0].value === element.dimensionValues[0].value) {
                    // checking for 50 % less session in last 7 days from last 60days average rate
                    if (parseFloat((element.metricValues[0].value / 60) * 0.5) > parseFloat(elm1.metricValues[0].value / 7)) {
                        topReferral.push(element.dimensionValues[0].value)
                    }
                }
            })
        }
    });
    if (topReferral.length > 0) {
        let test1_summary_data = `There has been a significant decrease of traffic from these top referral sources in the past 7 days: ${topReferral}. This may indicate that they are no longer linking to your site.`
        let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'GA18', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
        return data1;
    }
    let data1 = { test_name: 'Referral Drop Off', status: true, test_id: 'GA18', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
