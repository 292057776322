import React from 'react';
import {Box, Typography, Container} from '@mui/material';
import Carousel from 'react-multi-carousel';
import ClientLogo1 from './../../../../assets/images/client-logo/kayak-01.png';
import ClientLogo2 from './../../../../assets/images/client-logo/Loreal-02.png';
import ClientLogo3 from './../../../../assets/images/client-logo/visa-03.png';
import ClientLogo4 from './../../../../assets/images/client-logo/w-04.png';
import ClientLogo5 from './../../../../assets/images/client-logo/carrefour-05.png';
import ClientLogo6 from './../../../../assets/images/client-logo/pizzahut-06.png';
import ClientLogo7 from './../../../../assets/images/client-logo/dell-07.png';
import ClientLogo8 from './../../../../assets/images/client-logo/rsna-08.png';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1800 },
    items: 8,
    slidesToSlide: 1
  },
  largeDesktop: {
    breakpoint: { max: 1800, min: 1200 },
    items: 6,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 5,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 4,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 1
  }
};

const ClientsLogo = () => {

  return (
    <Box paddingY={{xs:5, sm:7, md:10}}>
      <Box>
        <Box marginBottom={{xs:5, sm:7}}>
          <Container>
            <Box>
              <Typography
                variant="h4"
                fontSize={{xs:26, sm:30}}
                gutterBottom
                sx={{
                  textAlign: 'center',
                  fontWeight: 700
                }}
              >
                Used by the best
              </Typography>
            </Box>
          </Container>
        </Box>
        <Box>
        <Carousel 
          className='clientLogo-slider' 
          infinite={true}
          responsive={responsive} 
          showDots={false} 
          autoPlay={true}
          swipeable={false}
          draggable={false} 
          removeArrowOnDeviceType={["tablet", "mobile", "desktop", "largeDesktop", "superLargeDesktop"]}
          >
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo1}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo2}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo3}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo4}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo5}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo6}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo7}
                maxWidth={1}
              />
            </Box>
            <Box className="client-logo">
              <Box
                borderRadius={1}
                component={'img'}
                src={ClientLogo8}
                maxWidth={1}
              />
            </Box>
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientsLogo;
