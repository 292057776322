import { UAReportBehaviourLandings } from '../../components/Api';

const url = process.env.REACT_APP_BATCH_API

export const ReferralDropOffTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "There is no significant decrease of traffic from your top referral sources in the past 7 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "There has been a significant decrease of traffic from these top referral sources in the past 7 days: [INSERT REFERRAL SOURCE/S]. This may indicate that they are no longer linking to your site.",
        test1_extra: "If a major referral source removed links to your site, you will no longer be receiving traffic from that source. If the traffic from that source was high-quality (that is, high conversion rate), then you may want to attempt to get your site relinked from that source."
    }

    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:sessions" }],
                    "dimensions": [
                        { "name": "ga:source" }
                    ],
                    "dateRanges": [{
                        "startDate": "60daysAgo",
                        "endDate": "yesterday"
                    },
                    {
                        "startDate": `7daysAgo`,
                        "endDate": `yesterday`
                    }
                    ],
                    "orderBys": [
                        {
                            "fieldName": "ga:sessions",
                            "sortOrder": "DESCENDING"
                        }
                    ]
                }
            ]
    });

    let resultData1 = await UAReportBehaviourLandings(url, params, accessToken)
    if (resultData1) {
        if (resultData1.code) {
            let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'UA18', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data1;
        }
    }
    if (!resultData1 || !resultData1.rows || Object.keys(resultData1.rows).length === 0) {
        let data1 = { test_name: 'Referral Drop Off', status: false, test_id: 'UA18', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    // total sessions
    let sum = resultData1.totals[0].values[0];
    let lenArray;
    if (resultData1.rows.length >= 10) {
        lenArray = 10
    } else {
        lenArray = resultData1.rows.length;
    }
    let topReferral = []
    for (let j = 0; j < lenArray; j++) {
        // checking for more than 10% traffic 
        if (parseInt(resultData1.rows[j].metrics[0].values[0]) > (parseInt(sum) * 0.1)) {
            let last60days = resultData1.rows[j].metrics[0].values[0];//last60days
            let last7days = resultData1.rows[j].metrics[1].values[0];//last7days
            let check = (last60days / 60) * 0.5 > (last7days / 7) ? false : true;
            if (!check) {
                topReferral.push(resultData1.rows[j].dimensions[0])
            }
        }
    }
    if (topReferral.length > 0) {
        let test1_summary_data = `There has been a significant decrease of traffic from these top referral sources in the past 7 days: ${topReferral}. This may indicate that they are no longer linking to your site.`
        let data = {
            test_name: 'Referral Drop Off',
            status: false, test_id: 'UA18', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
        }
        return data;
    }
    let data1 = { test_name: 'Referral Drop Off', status: true, test_id: 'UA18', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
