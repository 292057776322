import { UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API

export const BounceSpikeTest = async (viewId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }
    let reasons = {
        pass_summary: "There are no significant changes in bounce rates in the past 60 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: " A spike in bounce rates has been detected over the past [INSERT DATES] at [INSERT BOUNCE %]. Ensure that your website is able to load and is not returning errors. ",
        test1_extra: "A bounce spike indicates that the performance of one or more of your landing pages has changed dramatically. A positive spike might indicate that a landing page has become less optimised, that a new traffic source is sending low-quality leads, or that a landing page is having technical difficulties. A negative bounce spike might indicate that recent changes to a landing page has increased its stickiness or that a source of low-quality leads is no longer sending as much traffic to your site. "
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:bounces" },
                    { "expression": "ga:bounceRate" }],
                    "dimensions": [{ "name": "ga:date" }],
                    "dateRanges": [{
                        "startDate": `60daysAgo`,
                        "endDate": `yesterday`
                    }],
                    "orderBys": [
                        {
                            "fieldName": "ga:date",
                            "sortOrder": "DESCENDING"
                        }
                    ]
                }
            ]
    });

    let resultData = await UAReportBehaviourLandings(url, params, accessToken);
    if (resultData) {
        if (resultData.code) {
            let data = { test_name: 'Bounce Spike', status: false, test_id: 'UA13', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData || Object.keys(resultData).length === 0 || !resultData?.rows) {
        let data = { test_name: 'Bounce Spike', status: false, test_id: 'UA13', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    // 1 day and 20%
    let DataAll = resultData.rows;
    if (DataAll.length < 1) {
        let data = { test_name: 'Bounce Spike', status: false, test_id: 'UA13', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    for (let i = 0; i < 53; i++) {
        if (DataAll[i]?.metrics && DataAll[i + 1]?.metrics && DataAll[i + 7]?.metrics) {
            const before1day = parseFloat(DataAll[i + 1].metrics[0].values[1]);
            const last1day = parseFloat(DataAll[i].metrics[0].values[1]);
            const before7day = parseFloat(DataAll[i + 7].metrics[0].values[1])
            let check = (last1day - before1day > before1day * 20 / 100) ? false : true;
            let check1 = (last1day - before7day > before7day * 20 / 100) ? false : true;
            if (!check && !check1) {
                let test1_summary_data;
                test1_summary_data = `A spike in bounce rates has been detected over the past 1 day(${createDate(DataAll[i].dimensions[0])}) at  ${(last1day).toFixed(2)}%. Ensure that your website is able to load and is not returning errors.`
                let data1 = {
                    test_name: 'Bounce Spike', status: check, test_id: 'UA13', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
                }
                return data1;
            }
        }
    }
    // 3 days and 30%
    for (let i = 0; i < 55; i++) {
        if (DataAll[i]?.metrics && DataAll[i + 1]?.metrics && DataAll[i + 2]?.metrics && DataAll[i + 3]?.metrics && DataAll[i + 4]?.metrics && DataAll[i + 5]?.metrics) {

            const before3days = parseFloat(DataAll[i + 3].metrics[0].values[1]) + parseFloat(DataAll[i + 4].metrics[0].values[1]) + parseFloat(DataAll[i + 5].metrics[0].values[1]);

            const last3days = parseFloat(DataAll[i].metrics[0].values[1]) + parseFloat(DataAll[i + 1].metrics[0].values[1]) + parseFloat(DataAll[i + 2].metrics[0].values[1]);

            let check2 = (parseFloat(last3days - before3days)) > (parseFloat(before3days * 30 / 100)) ? false : true;

            if (!check2) {
                let test1_summary_data;
                test1_summary_data = `A spike in bounce rates has been detected over the past 3 days(${createDate(DataAll[i + 2].dimensions[0])}, ${createDate(DataAll[i + 1].dimensions[0])} and ${createDate(DataAll[i].dimensions[0])}) at  ${(last3days / 3).toFixed(2)}%. Ensure that your website is able to load and is not returning errors. `
                let data1 = {
                    test_name: 'Bounce Spike', status: false, test_id: 'UA13', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
                }
                return data1;
            }
        }
    }

    let data = { test_name: 'Bounce Spike', status: true, test_id: 'UA13', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
