import React from 'react';
import {Box, Grid, Typography, Container, Button} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Arrow from './../../../../assets/images/white-arrow.png';
import whatWeCheck from './../../../../assets/images/what_we_check.png';
import listIcon from './../../../../assets/images/list-icon.png';
import checkVector from './../../../../assets/images/check-for-vector.png';
import DummyReport from '../../../../assets/dummyreport.pdf'
import FileSaver from "file-saver";

const MookReport = () => {
  const downloadDummyReport = () => {
    FileSaver.saveAs(DummyReport,'DummyReport.pdf');
  }
  return (
    <Box marginBottom={{md:4}}>
      <Container className="space">
        <Box>
          <Box marginBottom={2}>
            <Box>
              <Typography
                variant="h3"
                color={'black'}
                gutterBottom
                sx={{
                  fontWeight: 700,
                }}
              >
                <Box component={'span'} sx={{position: 'relative'}}>
                  What we check for
                  <Box
                      sx={{position: 'absolute', bottom: -17, right: 68, width: 142}}
                      display={{xs: 'none', sm: 'block'}}
                      component={'img'}
                      src={checkVector}
                      maxWidth={1}
                    />
                </Box>
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={5}>
                <Box>
                  <List>
                    <ListItem sx={{paddingX: 0, paddingY: 0.3, marginBottom: 2, alignItems: 'flex-start'}}>
                      <ListItemIcon sx={{minWidth: {xs:26, sm:42}, marginRight: 2}}>
                        <Box width={{xs:32, sm:42}}>
                          <Box
                            maxWidth={1}
                            component={'img'}
                            src={listIcon}
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText className='font-small' sx={{color:'black'}}>
                        <strong>Google Analytics Setup:</strong> We check the accuracy of your Google Analytics code, ensuring that it is implemented correctly and tracking data accurately.
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{paddingX: 0, paddingY: 0.3, marginBottom: 2, alignItems: 'flex-start'}}>
                      <ListItemIcon sx={{minWidth: {xs:26, sm:42}, marginRight: 2}}>
                        <Box width={{xs:32, sm:42}}>
                          <Box
                            maxWidth={1}
                            component={'img'}
                            src={listIcon}
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText className='font-small' sx={{color:'black'}}>
                        <strong>Conversion Tracking:</strong> We check the implementation of funnels, goals, and Google Ads, ensuring that your conversion tracking is set up correctly and providing accurate data insights.
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{paddingX: 0, paddingY: 0.3, marginBottom: 2, alignItems: 'flex-start'}}>
                      <ListItemIcon sx={{minWidth: {xs:26, sm:42}, marginRight: 2}}>
                        <Box width={{xs:32, sm:42}}>
                          <Box
                            maxWidth={1}
                            component={'img'}
                            src={listIcon}
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText className='font-small' sx={{color:'black'}}>
                        <strong>Advanced Setup:</strong> We review ecommerce, site search and other advanced implementation setups, ensuring that it is correctly configured, providing valuable data on user behavior, and helping you make informed decisions.
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{paddingX: 0, paddingY: 0.3, marginBottom: 2, alignItems: 'flex-start'}}>
                      <ListItemIcon sx={{minWidth: {xs:26, sm:42}, marginRight: 2}}>
                        <Box width={{xs:32, sm:42}}>
                          <Box
                            maxWidth={1}
                            component={'img'}
                            src={listIcon}
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText className='font-small' sx={{color:'black'}}>
                        <strong>Custom Tracking:</strong> We check if custom campaign tracking, content grouping tracking, and custom properties are correctly set up, ensuring that you are capturing all the data you need to make informed decisions.
                      </ListItemText>
                    </ListItem>
                    <ListItem sx={{padding: 0, alignItems: 'flex-start'}}>
                      <ListItemIcon sx={{minWidth: {xs:26, sm:42}, marginRight: 2}}>
                        <Box width={{xs:32, sm:42}}>
                          <Box
                            maxWidth={1}
                            component={'img'}
                            src={listIcon}
                          />
                        </Box>
                      </ListItemIcon>
                      <ListItemText className='font-small' sx={{color:'black'}}>
                        <strong>Traffic Pattern Analysis:</strong> We perform behavior tests to identify unusual traffic patterns, highlight pages or goals with large deviations, and help you identify opportunities to improve your website's performance.
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={7}
            >
              <Box 
                height={1} 
                width={1} 
                position={'relative'}
                paddingLeft={{md:3, lg:5}}
                >
                <Box textAlign={'center'}>
                  <Box
                    maxWidth={1}
                    component={'img'}
                    src={whatWeCheck}
                  />
                </Box>
                <Box position={'absolute'} bottom={{xs:20, sm:60}} right={{xs:10, sm:40}}>
                  <Button className='theme-btn' onClick={()=> downloadDummyReport()}>
                    Download Report 
                     <Box
                    component={'img'}
                    src={Arrow}
                    marginLeft={1}
                    width={25}
                  /></Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default MookReport;
