import axios from 'axios';

const apiKey = process.env.REACT_APP_API_KEY

// Common Function For getting UA Report
export const UAReport = async (url, body, accessToken) => {
  let bearer = 'Bearer ' + accessToken;
  let configData = {
    headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json'
    }
  };
  return await axios.post(`${url}`, body, configData)
    .then(res => {
      let rowCount = res.data.reports[0].data.rowCount
      return rowCount;
    })
    .catch((error) => {
      return error;
    })
}

// Edited by ajay
export const UAReportBehaviour = async (url, body, accessToken) => {

  let bearer = 'Bearer ' + accessToken;
  let configData = {
    headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json'
    }
  }
  return await axios.post(`${url}`, body, configData)
    .then(res => {
      let totals = res.data.reports[0].data.totals
      return totals;
    })
    .catch((error) => {
      return error
    })
}
// Edited by ajay
export const UAReportBehaviourLandings = async (url, body, accessToken) => {
  let bearer = 'Bearer ' + accessToken;
  let configData = {
    headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json'
    }
  };
  return await axios.post(`${url}`, body, configData)
    .then(res => {
      let totals = res.data.reports[0].data
      return totals;
    })
    .catch((error) => {
      return error
    })
}


// Common Function For getting report data
export const getUAReport = async (url, accessToken) => {
  let bearer = 'Bearer ' + accessToken;
  let configData = {
    headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json'
    }
  };
  return await axios.get(`${url}`, configData)
    .then(res => {
      let response = res.data
      return response;
    })
    .catch((error) => {
      return error.response.data.error.status
    })
}

// Common Function For getting Accoount, Property and view data
export const GetAccountInfo = async (url, accessToken) => {
  let bearer = 'Bearer ' + accessToken;
  let newConfig = {
    headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json'
    },
  }
  return await axios.get(`${url}`, newConfig)
    .then(res => {
      let response = res.data.items
      return response;
    })
    .catch((error) => {
      return error.response.data.error.status
    })
}

// Common Function For getting Accoount, Property 
export const GetGA4Info = async (url, params, accessToken) => {
  let bearer = 'Bearer ' + accessToken;
  let newConfig = {
    params: params,
    headers: {
      'Authorization': bearer,
      'Content-Type': 'application/json'
    },
  }
  return await axios.get(`${url}`, newConfig)
    .then(res => {
      let response = res
      return response.data;
    })
    .catch((error) => {
      return error
    })
}
// Common Function For getting UA Report
export const GA4Report = async (url, body, accessToken) => {
  let bearer = 'Bearer ' + accessToken;
  let params = {
    alt: 'json',
    key: apiKey
  }
  let header = {
    'Authorization': bearer,
    'Content-Type': 'application/json'
  }

  return await axios.post(`${url}`, body,
    { params: params, headers: header }
  )
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error
    })
}


export const backendApi = async (url, body, token) => {
  let headers = {
    'x-access-token': token,
    'Content-Type': 'application/json'
  }
  return await axios.post(url,
    body, { headers: headers })
    .then(res => {
      return res.data;
    })
    .catch((error) => {
      return error
    })
}

export const removeToken = async (url) => {
  return await axios.get(url)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error
    })
}