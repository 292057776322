import { getUAReport } from '../../components/Api';
import { UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_ACCOUNT_API
const URL = process.env.REACT_APP_BATCH_API
export const ConversionsTest = async (accountId, webPropertyId, profileId, accessToken) => {
    let reasons = {
        pass_summary: "Conversions are set and active in your Universal Analytics property.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "There are no goals set in your Universal Analytics property.",
        test2_summary: `The goals are not reporting any conversion in the last 30 days. Learn more about goals <a href='https://support.google.com/analytics/answer/1032415' target="_blank" >here</a>.`,
        test1_extra: "Goals provide insights into interactions that are important to your business and allow you to use that data for your marketing efforts.",
        test2_extra: ""
    }
    let resultData = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/profiles/${profileId}/goals`, accessToken)
    if (resultData) {
        if (resultData === 401 || resultData === 403) {
            let data = { test_name: 'Conversions', status: false, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData || Object.keys(resultData).length === 0 || !resultData.items) {
        let data = { test_name: 'Conversions', status: false, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    let resultObj = resultData?.items;
    let test1 = 0;
    // test 1
    for (let i = 0; i < resultObj.length; i++) {
        if (resultObj[i].active) {
            test1 = 1;
            break;
        }
    }
    if (test1 === 0) {
        let data = { test_name: 'Conversions', status: false, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }

    // test 2
    let insertItems = []
    for (let i = 0; i < resultObj.length; i++) {
        if (resultObj[i].active) {
            // for 30days
            const params1 = JSON.stringify({
                "reportRequests":
                    [
                        {
                            "viewId": profileId,
                            "metrics": [{ "expression": `ga:goal${resultObj[i].id}ConversionRate` }],
                            "dateRanges": [{ "startDate": `30daysAgo`, "endDate": `yesterday` }]
                        }
                    ]
            });
            let resultData1 = await UAReportBehaviourLandings(URL, params1, accessToken);
            if (resultData1) {
                if (resultData1.code) {
                    let data = { test_name: 'Conversions', status: false, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
                    return data;
                }
            }
            if (resultData1 && resultData1?.rows && Object.keys(resultData1?.rows).length !== 0) {
                if (!resultData1.rows[0].metrics[0].values[0]) {
                    insertItems.push(' ' + resultObj[i].name);
                }
            } else {
                insertItems.push(' ' + resultObj[i].name);
            }
        }
    }
    if (insertItems.length > 0) {
        let test2_summary_date = `The goals are not reporting any conversion in the last 30 days:  ${insertItems}. Learn more about goals <a href='https://support.google.com/analytics/answer/1032415' target="_blank" >here</a>.`
        let data = { test_name: 'Conversions', status: false, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: test2_summary_date, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }
    //test 3
    let test3 = 0;
    for (let i = 0; i < resultObj.length; i++) {
        if (resultObj[i].type !== "URL_DESTINATION" && resultObj[i].type !== "EVENT") {
            test3 = 1;
            break;
        }
    }
    if (!test3) {
        let data = { test_name: 'Conversions', status: true, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: "", pass_summary: `${reasons.pass_summary} Not every goal type of UA has a direct equivalence in GA4. Additional configuration and implementation will be required to create the equivalent conversions in GA4.`, fail_extra: "" }
        return data;
    } else {
        let data = { test_name: 'Conversions', status: true, test_id: 'UA04', tool: 'UA', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
        return data;
    }
}
