import { UAReport } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API

export const ContentGroupTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "Related pages in your website are categorized into custom groups.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Related pages in your website are not categorized into custom groups. Learn more about  <a href='https://support.google.com/analytics/answer/2853423' target="_blank" >content grouping here</a>.`,
        test1_extra: "With content groups, you can categorize pages and screens into custom buckets so you can see metrics for related groups of information."
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:contentGroup1" }],
                    "dateRanges": [
                        {
                            "startDate": "60daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });

    let resultData = await UAReport(url, params, accessToken)
    if (resultData) {
        if (resultData.code) {
            let data = { test_name: 'Content Group', status: false, test_id: 'UA09', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData) {
        let data = { test_name: 'Content Group', status: false, test_id: 'UA09', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (resultData < 1) {
        let data = { test_name: 'Content Group', status: false, test_id: 'UA09', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    let data = { test_name: 'Content Group', status: true, test_id: 'UA09', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
