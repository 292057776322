import { UAReport } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API

export const EventTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "Your Universal Analytics property is collecting events data.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Your Universal Analytics property is not collecting events data. (Learn more about events <a href='https://support.google.com/analytics/answer/1033068' target="_blank" >here</a>.)`,
        test1_extra: `Events help you measure and report on additional features and behavior that Google Analytics does not collect automatically. (See Google developer <a href='https://developers.google.com/analytics/devguides/migration/measurement/events#universal_analytics' target="_blank" >guide</a>.)`
    }

    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:eventCategory" }]
                }
            ]
    });

    let resultData = await UAReport(url, params, accessToken)
    if (resultData) {
        if (resultData.code) {
            let data = { test_name: 'Events', status: false, test_id: 'UA03', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData) {
        let data = { test_name: 'Events', status: false, test_id: 'UA03', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (resultData < 1) {
        let data = { test_name: 'Events', status: false, test_id: 'UA03', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    let data = { test_name: 'Events', status: true, test_id: 'UA03', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}