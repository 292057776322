import { GA4Report, GetGA4Info } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA
const adminURL = process.env.REACT_APP_GA4_URL
const apiKey = process.env.REACT_APP_API_KEY

export const ConversionRateTestGA4 = async (propertyId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }

    let reasons = {
        pass_summary: "There are no significant changes in your conversion rates.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Your conversion rate is decreasing at an extraordinary rate of [INSERT % VALUE] on [INSERT DATE/S] from the median rate. Recent changes may have decreased conversion rate, or there may be an error preventing conversion.",
        test1_extra: "A decreasing conversion rate may indicate an increase in low-quality traffic, the site's funnels becoming less optimised, or technical difficulties on the site.",
    }

    let paramsAll = { key: apiKey }
    const resultData = await GetGA4Info(`${adminURL}/${propertyId}/conversionEvents`,
        paramsAll, accessToken)

    if (resultData.code) {
        let data = { test_name: 'Conversions', status: false, test_id: 'GA20', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!resultData || !resultData.conversionEvents) {
        let data = { test_name: 'Conversions', status: false, test_id: 'GA20', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData?.conversionEvents
    // test 1 checking for conversion event name or set 
    let conversionList = []
    for (let i = 0; i < obj.length; i++) {
        if (obj[i]?.custom == true) {
            conversionList.push(obj[i].eventName)
        }
    }
    if (conversionList?.length < 1) {
        let data = { test_name: 'Conversions', status: false, test_id: 'GA20', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '' }
        return data;
    }
    else {
        let Goals = []
        // for all conversions events 
        let len = conversionList?.length
        for (let i = 0; i < len; i++) {
            const params1 = JSON.stringify({
                "dateRanges": [
                    {
                        "startDate": `60daysAgo`,
                        "endDate": `yesterday`
                    }
                ],
                "dimensions": [
                    { "name": "date" },
                ],
                "metrics": [
                    { "name": `sessionConversionRate:${conversionList[i]}` }
                ],
                "keepEmptyRows": true,
                "orderBys": [
                    {
                        "dimension": {
                            "dimensionName": "date",
                        },
                        "desc": true
                    }
                ]
            });

            let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, params1, accessToken)
            if (resultData1.code) {
                continue;
            }
            if (!resultData1 || !resultData1?.data || !resultData1?.data?.rows) {
                continue;
            }
            let obj = resultData1.data.rows;
            let is3Day = true;
            // 1day and 20% negative
            for (let k = 0; k < 53; k++) {
                if (obj[k]?.metricValues && obj[k + 1]?.metricValues && obj[k + 7]?.metricValues) {
                    let last1day = parseFloat(obj[k]?.metricValues[0]?.value)
                    let before1day = parseFloat(obj[k + 1]?.metricValues[0]?.value)
                    let before7day = parseFloat(obj[k + 7]?.metricValues[0]?.value)

                    // last 1 day to before 1 day and last 1 day to before 7day 
                    if (((before1day - last1day) > before1day * 0.2) && ((before7day - last1day) > before7day * 0.2)) {
                        Goals.push(`<br/><span>&bullet;</span> ${((before1day - last1day) * 100 / before1day).toFixed(2)}% on 1 day(${createDate(obj[k]?.dimensionValues[0]?.value)}) for ${conversionList[i]}`)
                        is3Day = false
                        break;
                    }
                }
            }
            // 3days and 20% negative
            if (is3Day) {
                for (let j = 0; j < 55; j++) {
                    if (obj[j]?.metricValues && obj[j + 1]?.metricValues && obj[j + 3]?.metricValues && obj[j + 2]?.metricValues && obj[j + 4]?.metricValues && obj[j + 5]?.metricValues) {

                        let last3days = parseFloat(obj[j].metricValues[0].value) + parseFloat(obj[j + 1].metricValues[0].value) + parseFloat(obj[j + 2].metricValues[0].value)

                        let before3days = parseFloat(obj[j + 3].metricValues[0].value) + parseFloat(obj[j + 4].metricValues[0].value) + parseFloat(obj[j + 5].metricValues[0].value)

                        if (before3days - last3days > before3days * 0.2) {
                            Goals.push(`<br/><span>&bullet;</span> ${((before3days - last3days) * 100 / before3days).toFixed(2)}% on 3 days(${createDate(obj[j + 2]?.dimensionValues[0]?.value)}, ${createDate(obj[j + 1]?.dimensionValues[0]?.value)} and ${createDate(obj[j]?.dimensionValues[0]?.value)}) for ${conversionList[i]}`)
                            break;
                        }
                    }
                }
            }
        }
        if (Goals.length > 0) {
            let test1_summary_data = `Your conversion rate is decreasing at an extraordinary rate of ` + `${Goals}` + `<br/>Recent changes may have decreased conversion rate, or there may be an error preventing conversion.`
            let data1 = {
                test_name: 'Conversion Rate', status: false, test_id: 'GA20', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data,
                pass_summary: '', fail_extra: reasons.test1_extra
            }
            return data1;
        }
        let data1 = { test_name: 'Conversion Rate', status: true, test_id: 'GA20', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
        return data1;
    }
}
