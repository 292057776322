import React from 'react';
import { useTheme, Box } from '@mui/material';
import Main from '../../layouts/Main';
import ResultsTabs from './components/result-tabs';
import PoweredBy from './../Poweredby/PoweredBy'

const Faq = () => {
  const theme = useTheme();
  return (
    <Main>
      <Box>
        <Box>
          <ResultsTabs />
        </Box>
        <Box>
          <PoweredBy />
        </Box>
      </Box>
    </Main>
  );
};

export default Faq;
