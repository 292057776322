import React from 'react';
import {Box, Avatar, Grid, Typography, Container} from '@mui/material';
import client1 from './../../../../assets/images/testimonials_client_01.png';
import client2 from './../../../../assets/images/testimonials_client_02.png';
import client3 from './../../../../assets/images/testimonials_client_03.png';
import headShap from './../../../../assets/images/testimanials_head_shap.svg';
import headShapWhite from './../../../../assets/images/testimanials_head_shap_white.svg';
import arrowShap1 from './../../../../assets/images/testimanials_arrowshap1.png';
import arrowShap2 from './../../../../assets/images/testimanials_arrowshap2.png';
import 'react-multi-carousel/lib/styles.css';

const Testimonials = () => {
  return (
    <Box marginBottom={{xs:4, md:8}}>
      <Container className='b-space'>
        <Box marginBottom={{xs:2, sm:4}}>
          <Box paddingBottom={{xs:1, sm:2}}>
            <Typography
              variant="h4"
              fontSize={{xs:30, sm:34}}
              textAlign={'center'}
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              Testimonials
            </Typography>
          </Box>
        </Box>
        <Box className="testimonial-slider">
            <Grid container spacing={{xs:3, md:4, lg:8}}>
              <Grid item xs={12} md={4}>
                <Box height={'100%'}>
                    <Box className="testimonial-card left">
                      <Box className="testimonial-head">
                        <Box>
                          <Box className='client-img' component={Avatar}>
                            <Box
                            component={'img'}
                            src={client1}
                            maxWidth={1}
                            />
                          </Box>
                          <Box className='head-shap'
                              >
                            <Box
                              component={'img'}
                              src={headShap}
                              maxWidth={1}
                            />
                          </Box>
                        </Box>
                        <Box className="client-details">
                          <Typography className='title'>
                            Eric Ramos
                          </Typography>
                          <Typography className='subtitle'>
                            Director of Analytics at BOL Agency
                          </Typography>
                        </Box>
                        <Box
                            className='head-arrowshap'
                            component={'img'}
                            src={arrowShap1}
                            maxWidth={1}
                          />
                      </Box>
                      <Box className="testimonial-body">
                        <Typography variant={'p'}>
                          CheckMyAnalytics is great to get a quick diagnostic of my client's data, and run initial configuration review. This a great start for me to know what to check in GA.
                        </Typography>
                      </Box>
                    </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box height={'100%'}>
                    <Box className="testimonial-card middle">
                      <Box className="testimonial-head">
                        <Box>
                          <Box className='client-img' component={Avatar}>
                              <Box
                              component={'img'}
                              src={client2}
                              maxWidth={1}
                              />
                          </Box>
                          <Box className='head-shap'
                              >
                            <Box component={'img'}
                              src={headShapWhite}
                              maxWidth={1}
                            />
                          </Box>
                        </Box>
                        <Box className="client-details">
                          <Typography className='title'>
                            Tom De Moya
                          </Typography>
                          <Typography className='subtitle'>
                            Digital Analytics Architect Cox Automotive
                          </Typography>
                        </Box>
                          <Box
                            className='head-arrowshap2'
                            component={'img'}
                            src={arrowShap2}
                            maxWidth={1}
                          />
                      </Box>
                      <Box className="testimonial-body">
                        <Typography variant={'p'}>
                          Check My Analytics helped me identify an implementation issue that was causing incorrect data reporting. I was able to quickly fix the issue and get back on track with my data analysis.
                        </Typography>
                      </Box>
                    </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box height={'100%'}>
                    <Box className="testimonial-card right">
                      <Box className="testimonial-head">
                        <Box>
                          <Box className='client-img' component={Avatar}>
                              <Box
                              component={'img'}
                              src={client3}
                              maxWidth={1}
                              />
                          </Box>
                          <Box className='head-shap'
                              >
                            <Box component={'img'}
                              src={headShap}
                              maxWidth={1}
                            />
                          </Box>
                        </Box>
                        <Box className="client-details">
                          <Typography className='title'>
                            Deion Brown
                          </Typography>
                          <Typography className='subtitle'>
                            e-commerce Coordinator
                          </Typography>
                        </Box>
                          <Box
                            className='head-arrowshap'
                            component={'img'}
                            src={arrowShap1}
                            maxWidth={1}
                          />
                      </Box>
                      <Box className="testimonial-body">
                        <Typography variant={'p'}>
                          As an Ecommerce coordinator, I don't have the time or headspace to become a Google Analytics expert. Check My Analytics provides me with the guidance and expertise I need to make informed decisions for the business, without sacrificing my time or budget.
                        </Typography>
                      </Box>
                    </Box>
                </Box>
              </Grid>
            </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
