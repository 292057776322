import { getUAReport } from '../../components/Api';
const url = process.env.REACT_APP_ACCOUNT_API
export const SiteSearchTest = async (accountId, webPropertyId, profileId, accessToken) => {
    let reasons = {
        pass_summary: "Site search has been set up in your Google Analytics view.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "The site search has not been fully configured. The parameters [INSERT PARAMETERS] were not properly configured. ",
    }
    let resultData = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/profiles/${profileId}`, accessToken)
    if (resultData) {
        if (resultData === 403) {
            let data = { test_name: 'Site Search', status: false, test_id: 'UA02', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData || Object.keys(resultData).length === 0) {
        let data = { test_name: 'Site Search', status: false, test_id: 'UA02', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    let check = resultData.siteSearchQueryParameters && resultData.stripSiteSearchQueryParameters && resultData.siteSearchCategoryParameters && resultData.stripSiteSearchCategoryParameters ? true : false

    let data1 = []
    if (!resultData.siteSearchQueryParameters) data1.push('Query Parameters')
    if (!resultData.stripSiteSearchQueryParameters) data1.push('Strip query parameters out of URL')
    if (!resultData.siteSearchCategoryParameters) data1.push('Site Search Categories')
    if (!resultData.stripSiteSearchCategoryParameters) data1.push('Category Parameter')

    let test1_summary_data = `The site search has not been fully configured. The parameters ${data1.length > 1 ? `${data1.slice(0, data1.length - 1)} and ${data1.slice(data1.length - 1, data1.length)}` : data1} were not properly configured. `
    if (!check) {
        let data = { test_name: 'Site Search', status: false, test_id: 'UA02', tool: 'UA', difficulty_level: 1, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }

    let data = { test_name: 'Site Search', status: true, test_id: 'UA02', tool: 'UA', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
