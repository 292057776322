import { GA4Report } from '../../components/Api';

const URL = process.env.REACT_APP_GA4_URL_DATA

export const LandingPagesTestGA4 = async (propertyId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }

    function createDate1() {
        let dates60 = []
        for (let day = 1; day <= 60; day++) {
            var today = new Date(new Date(Date.now() - day * 24 * 60 * 60 * 1000));
            var dd = (today.getDate());
            var mm = (today.getMonth() + 1); //January is 0!
            var yyyy = today.getFullYear();
            dd = dd < 10 ? '0' + dd : dd;
            mm = mm < 10 ? '0' + mm : mm;
            today = yyyy + mm + dd;
            dates60.push(today)
        }
        return dates60;
    }
    let reasons = {
        pass_summary: "There are no significant changes in bounce rates on any major landing pages.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "The engagement rate has increased rapidly on [INSERT DATE/S] on page [INSERT PAGE PATHS]. This may indicate that recent changes to that page have been effective in capturing users. ",
        test1_extra: "If a major landing page has an engagement rate spike, this indicates a significant change on that page. A positive spike might indicate that the page has become less optimised, that a new traffic source is sending low-quality leads to that page, or that the page is having technical difficulties. A negative spike might indicate that recent changes to the page has increased its stickiness or that a source of low-quality leads is no longer sending as much traffic to that page.",
    }

    const params1 = JSON.stringify({
        "dateRanges": [{ "startDate": "60daysAgo", "endDate": "yesterday", }],
        "dimensions": [{ "name": "landingPage" }],
        "metrics": [{ "name": "sessions" },],
        "limit": "10",
        "orderBys": [{ "desc": true, "metric": { "metricName": "sessions" } }]
    });

    let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, params1, accessToken)
    if (resultData1?.code) {
        let data1 = { test_name: 'Landing Pages', status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data1;
    }
    if (!resultData1 || !resultData1?.data || !resultData1?.data?.rows) {
        let data1 = { test_name: 'Landing Pages', status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    let obj = resultData1?.data?.rows;
    let sum = 0;
    obj.forEach(element => {
        sum += parseInt(element?.metricValues[0]?.value);
    });
    let landingPage = [], len = obj?.length;
    // top 10 landing pages

    for (let j = 0; j < len; j++) {
        // checking for 5% traffics/entrances
        if ((obj[j]?.metricValues[0]?.value) > (sum * 0.05)) {
            landingPage.push(obj[j]?.dimensionValues[0]?.value)
        }
    }

    let dates60 = createDate1();
    let resultPages = []
    if (landingPage?.length > 0) {
        const params = JSON.stringify({
            "dateRanges": [{ "startDate": "60daysAgo", "endDate": "yesterday", }],
            "dimensions": [{ "name": "date" }, { "name": "landingPage" }],
            "metrics": [{ "name": "engagementRate" },],
            // "keepEmptyRows": true,
            "dimensionFilter": {
                "filter": {
                    "fieldName": "landingPage",
                    "inListFilter": {
                        "values": landingPage,
                    },
                }
            },
        });

        let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
        if (resultData1?.code) {
            let data1 = { test_name: 'Landing Pages', status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data1;
        }
        if (!resultData1 || !resultData1?.data || !resultData1?.data?.rows) {
            let data1 = { test_name: 'Landing Pages', status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
            return data1;
        }
        let resultObj = resultData1?.data?.rows
        if (resultObj.length < 1) {
            let data1 = { test_name: 'Landing Pages', status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
            return data1;
        }
        for (let k = 0; k < landingPage?.length; k++) {
            let day1test = false;
            // 1 day
            for (let d = 0; d < dates60.length - 7; d++) {
                // day 1
                let last1day = 0, before1day = 0;
                for (let j = 0; j < resultObj?.length; j++) {
                    if (resultObj[j]?.dimensionValues[0].value === dates60[d] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                        last1day = parseFloat(resultObj[j]?.metricValues[0]?.value)
                        break;
                    }
                    last1day = 0;
                }
                // day 2
                for (let j = 0; j < resultObj?.length; j++) {
                    if (resultObj[j]?.dimensionValues[0].value === dates60[d + 1] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                        before1day = parseFloat(resultObj[j]?.metricValues[0]?.value)
                        break
                    }
                    before1day = 0;
                }
                // before 7 day
                let before7days12 = 0;
                for (let j = 0; j < resultObj?.length; j++) {
                    if (resultObj[j]?.dimensionValues[0].value === dates60[d + 7] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                        before7days12 = parseFloat(resultObj[j]?.metricValues[0]?.value)
                        break
                    }
                    before7days12 = 0;
                }
                // day 1 and day 2
                let check = (last1day - before1day) > before1day * 0.2 ? false : true;
                let check12 = (last1day - before7days12) > before7days12 * 0.2 ? false : true;
                if (!check && !check12) {
                    resultPages.push(`<br/><span>&bullet;</span> 1 day(${createDate(dates60[d])}) on page ${landingPage[k]}`)
                    day1test = true;
                    break;
                }
            }
            if (!day1test) {
                // 3 days
                for (let d = 0; d < dates60.length - 3; d++) {
                    let firstDay = 0, secondDay = 0, thirdDay = 0, fourthDay = 0, fifthDay = 0, sixDay = 0;
                    // day 1
                    for (let j = 0; j < resultObj?.length; j++) {
                        if (resultObj[j]?.dimensionValues[0].value === dates60[d] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                            firstDay = parseFloat(resultObj[j]?.metricValues[0]?.value)
                            break;
                        }
                        firstDay = 0;
                    }
                    // day 2
                    for (let j = 0; j < resultObj?.length; j++) {
                        if (resultObj[j]?.dimensionValues[0].value === dates60[d + 1] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                            secondDay = parseFloat(resultObj[j]?.metricValues[0]?.value)
                            break
                        }
                        secondDay = 0;
                    }

                    // day 3
                    for (let j = 0; j < resultObj?.length; j++) {
                        if (resultObj[j]?.dimensionValues[0].value === dates60[d + 2] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                            thirdDay = parseFloat(resultObj[j]?.metricValues[0]?.value)
                            break
                        }
                        thirdDay = 0;
                    }


                    // day 4
                    for (let j = 0; j < resultObj?.length; j++) {
                        if (resultObj[j]?.dimensionValues[0].value === dates60[d + 3] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                            fourthDay = parseFloat(resultObj[j]?.metricValues[0]?.value)
                            break
                        }
                        fourthDay = 0;
                    }

                    //day 5
                    for (let j = 0; j < resultObj?.length; j++) {
                        if (resultObj[j]?.dimensionValues[0].value === dates60[d + 4] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                            fifthDay = parseFloat(resultObj[j]?.metricValues[0]?.value)
                            break
                        }
                        fifthDay = 0;
                    }
                    //day 6
                    for (let j = 0; j < resultObj?.length; j++) {
                        if (resultObj[j]?.dimensionValues[0].value === dates60[d + 5] && resultObj[j]?.dimensionValues[1].value === landingPage[k]) {
                            sixDay = parseFloat(resultObj[j]?.metricValues[0]?.value)
                            break
                        }
                        sixDay = 0;
                    }

                    // 3day to 3days
                    let before3days = parseFloat(sixDay + fifthDay + fourthDay);
                    let last3days = parseFloat(thirdDay + secondDay + firstDay);
                    let check3 = (last3days - before3days) > before3days * 0.3 ? false : true;
                    if (!check3) {
                        resultPages.push(`<br/><span>&bullet;</span> 3 days(${createDate(dates60[d + 2])},${createDate(dates60[d + 1])},${createDate(dates60[d])}) on page ${landingPage[k]}`)
                        break;
                    }
                }
            }
        }
    } else {
        let data1 = { test_name: 'Landing Pages', status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.no_data, fail_extra: '' }
        return data1;
    }
    if (resultPages.length > 0) {
        let test1_summary_data = `The engagement rate has increased rapidly on` + `${resultPages}` + `<br/>This may indicate that recent changes to that page have been effective in capturing users.`
        let data = {
            test_name: 'Landing Pages',
            status: false, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
        }
        return data;
    }
    let data1 = { test_name: 'Landing Pages', status: true, test_id: 'GA16', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
