import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const DataFragmentationTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "No major data fragmentation has been detected.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Data fragmentation is observed for the following dimensions: [INSERT DIMENSION/S]. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.",
        test2_summary: "Data fragmentation is observed for the following dimensions: [INSERT DIMENSION/S]. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.",
        test1_extra: "Fragmentation in page paths, source/medium/campaign values, or event names is when data for the same dimension value is split across multiple rows in Google Analytics 4 reports. ",
        test2_extra: "Fragmentation in page paths, source/medium/campaign values, or event names is when data for the same dimension value is split across multiple rows in Google Analytics 4 reports. ",
    }
    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    const params = JSON.stringify({
        "dimensions": [
            { "name": "sessionCampaignName" }
        ],
        "metrics": [
            { "name": "sessions" }
        ],
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday"
            }
        ],
    });
    const sourceMediumParams = JSON.stringify({
        "dimensions": [
            { "name": "sessionSourceMedium" }
        ],
        "metrics": [
            { "name": "sessions" }
        ],
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday"
            }
        ],
    });
    const eventCategoryParams = JSON.stringify({
        "dimensions": [
            { "name": "eventName" }
        ],
        "metrics": [
            { "name": "sessions" }
        ],
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday"
            }
        ],
    });
    const pagePathParams = JSON.stringify({
        "dimensions": [
            { "name": "pagePath" }
        ],
        "metrics": [
            { "name": "sessions" }
        ],
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday"
            }
        ],
    });

    let pagePathResult = await GA4Report(`${URL}/${propertyId}:runReport`, pagePathParams, accessToken);
    let campaignData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken);
    let sourceMediumData = await GA4Report(`${URL}/${propertyId}:runReport`, sourceMediumParams, accessToken);
    let eventCategoryData = await GA4Report(`${URL}/${propertyId}:runReport`, eventCategoryParams, accessToken);

    if (campaignData.code || sourceMediumData.code || eventCategoryData.code || pagePathResult.code) {
        let data1 = { test_name: 'Data Fragmentation', status: false, test_id: 'GA19', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data1;
    }
    if ((!campaignData || !campaignData.data || !campaignData.data.rows) || (!sourceMediumData || !sourceMediumData.data || !sourceMediumData.data.rows) || (!eventCategoryData || !eventCategoryData.data || !eventCategoryData.data.rows) || (!pagePathResult || !pagePathResult.data || !pagePathResult.data.rows)) {
        let data1 = { test_name: 'Data Fragmentation', status: false, test_id: 'GA19', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    let campaignObj = campaignData.data.rows
    let sourcObj = sourceMediumData.data.rows
    let eventObj = eventCategoryData.data.rows
    let pagePathObj = pagePathResult.data.rows

    //test case 1:
    let pagePath = [], eventCategory = [], sourceMedium = [], campaign = [];
    campaignObj.forEach(element => {
        campaign.push(element?.dimensionValues[0]?.value.toLowerCase().replaceAll(" ", ""))
    });
    sourcObj.forEach(element => {
        sourceMedium.push(element?.dimensionValues[0]?.value.toLowerCase().replaceAll(" ", ""))
    });
    eventObj.forEach(element => {
        eventCategory.push(element?.dimensionValues[0]?.value.toLowerCase().replaceAll(" ", ""));
    });

    const campaignIds = ["?fbclid", "?gclid", "?dc_cid", "?cid", "tid", "?msclkid", "?utm_medium", "?utm_source", "?utm_term", "?utm_campaign", "?utm_content", "?utm_id"];
    let test2 = false
    pagePathObj.forEach(element => {
        let dimensions = element.dimensionValues[0].value.toLowerCase().replaceAll(" ", "")
        pagePath.push(dimensions);
        test2 = true
        let isTrue = campaignIds.some(v => dimensions?.includes(v))
        if (isTrue) {
            test2 = false
        }
    });

    if (hasDuplicates(campaign) || hasDuplicates(sourceMedium) || hasDuplicates(eventCategory) || hasDuplicates(pagePath)) {
        let test1_summary_data;
        if (hasDuplicates(campaign)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: campaign. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        if (hasDuplicates(sourceMedium)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: sourceMedium. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        if (hasDuplicates(eventCategory)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: eventCategory. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        if (hasDuplicates(pagePath)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: pagePath. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }

        let data = { test_name: 'Data Fragmentation', status: false, test_id: 'GA19', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }


    //test case 2:
    if (!test2) {
        let test2_summary_data = `Data fragmentation is observed for the following dimensions: pagePath. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        let data = { test_name: 'Data Fragmentation', status: false, test_id: 'GA19', tool: 'GA4', difficulty_level: 2, fail_summary: test2_summary_data, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }

    let data1 = { test_name: 'Data Fragmentation', status: true, test_id: 'GA19', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
