import { UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API

export const TrafficSpikeTest = async (viewId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }
    let reasons = {
        pass_summary: "There are no significant spikes in your month-on-month traffic in the past 60 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: " A positive traffic spike has been detected on [INSERT DATE/S] at [INSERT %]. Ensure that your infrastructure is ready to handle increased load.",
        test1_extra: "A traffic spike typically indicates that there has been a recent event which has affected how much traffic is arriving from one of your traffic sources. A negative spike might indicate the end of a marketing campaign, a link from a major referrer being removed or delisting from a search engine. A positive spike might indicate the start of a marketing campaign, a new source of traffic via referrals, or an improvement in ranking on a search engine."
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:sessions" }],
                    "dimensions": [{ "name": "ga:date" }],
                    "dateRanges": [{
                        "startDate": `60daysAgo`,
                        "endDate": `yesterday`
                    }],
                    "orderBys": [
                        {
                            "fieldName": "ga:date",
                            "sortOrder": "DESCENDING"
                        }
                    ]
                }
            ]
    });

    let resultData = await UAReportBehaviourLandings(url, params, accessToken);
    if (resultData) {
        if (resultData.code) {
            let data = { test_name: 'Traffic Spikes', status: false, test_id: 'UA15', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData || Object.keys(resultData).length === 0 || !resultData?.rows) {
        let data = { test_name: 'Traffic Spikes', status: false, test_id: 'UA15', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let DataAll = resultData.rows;
    if (DataAll.length < 1) {
        let data = { test_name: 'Traffic Spikes', status: false, test_id: 'UA15', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    // 1 day and 30%
    for (let i = 0; i < 53; i++) {
        if (DataAll[i]?.metrics && DataAll[i + 1]?.metrics && DataAll[i + 7]?.metrics) {
            const before1day = parseFloat(DataAll[i + 1]?.metrics[0].values[0]);
            const before7day = parseFloat(DataAll[i + 7]?.metrics[0].values[0])
            const last1day = parseFloat(DataAll[i]?.metrics[0].values[0]);

            // checking for traffic spike 3 days
            let check = (last1day - before1day) > (before1day * 30 / 100) ? false : true
            let check1 = (last1day - before7day) > (before7day * 30 / 100) ? false : true

            if (!check && !check1) {
                let test1_summary_data = `A positive traffic spike has been detected over the past 1 day(${createDate(DataAll[i].dimensions[0])}) at ${before1day > 0 ? parseFloat((last1day - before1day) * 100 / before1day).toFixed(2) : last1day}%. Ensure that your infrastructure is ready to handle increased load.`
                let data1 = { test_name: 'Traffic Spikes', status: false, test_id: 'UA15', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
                return data1;
            }
        }
    }
    // 3days and 50%
    for (let i = 0; i < 55; i++) {
        if (DataAll[i]?.metrics && DataAll[i + 1]?.metrics && DataAll[i + 2]?.metrics && DataAll[i + 3]?.metrics && DataAll[i + 4]?.metrics && DataAll[i + 5]?.metrics) {

            const before3days = parseFloat(DataAll[i + 3]?.metrics[0]?.values[0]) + parseFloat(DataAll[i + 4]?.metrics[0]?.values[0]) + parseFloat(DataAll[i + 5]?.metrics[0]?.values[0]);

            const last3days = parseFloat(DataAll[i]?.metrics[0]?.values[0]) + parseFloat(DataAll[i + 1]?.metrics[0]?.values[0]) + parseFloat(DataAll[i + 2]?.metrics[0]?.values[0]);

            // checking for bounce spike 3 days
            let check = (last3days - before3days) > (before3days * 50 / 100) ? false : true
            if (!check) {
                let test1_summary_data = `A positive traffic spike has been detected over the past 3 days(${createDate(DataAll[i + 2].dimensions[0])}, ${createDate(DataAll[i + 1].dimensions[0])} and ${createDate(DataAll[i].dimensions[0])}) at ${before3days > 0 ? parseFloat((last3days - before3days) * 100 / before3days).toFixed(2) : parseFloat(last3days / 3).toFixed(2)}%. Ensure that your infrastructure is ready to handle increased load.`
                let data1 = { test_name: 'Traffic Spikes', status: check, test_id: 'UA15', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
                return data1;
            }
        }
    }

    let data = { test_name: 'Traffic Spikes', status: true, test_id: 'UA15', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}