import { GA4Report, GetGA4Info } from '../../components/Api'
const GA4Url = process.env.REACT_APP_GA4_URL
const URL = process.env.REACT_APP_GA4_URL_DATA
const apiKey = process.env.REACT_APP_API_KEY

export const PropertyTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Your GA4 analytics property and its data streams have been properly set and the property collects data.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Your property settings are incomplete. In Google Analytics 4, click Admin > In the Property column, click Property Settings > Review and edit your property details.",
        test2_summary: `Your Google Analytics 4 property must have at least one data stream to collect data. See how to add a data stream <a href='https://support.google.com/analytics/answer/9304153' target="blank" >here</a>.`,
        test3_summary: `Your data stream settings must be reviewed. In Google Analytics 4, click Admin > In the Property column, click Data Streams > Select the data stream/s > Complete all fields. (See the complete guide <a href='https://support.google.com/analytics/answer/9304153' target="blank" >here</a>.)`,
        test4_summary: `Your data stream(s) is not active. See the options to set up data collection <a href='https://support.google.com/analytics/answer/9304153' target="blank" >here</a>.`,
        test1_extra: "Setting the best applicable industry category helps your property's performance in comparison to that of your industry peers. Whereas choosing the time zone and currency directly supports data collection and reporting.",
        test2_extra: "Data streams are sources of data sent to your GA4 property from either a website or an iOS or Android app.",
        test3_extra: "Properly defining the data stream name and Web stream URL helps organize your GA4 property structure.",
        test4_extra: `Make sure that the Measurement ID is applied to your GA4 configuration tag in <a href='https://support.google.com/tagmanager/answer/9442095' target="blank" >Google Tag Manager</a>  or added <a href='https://support.google.com/analytics/answer/12002338' target="blank" >directly to your website</a> to send data to your web stream.`
    }

    let params = { key: apiKey };

    // calling for getting property info
    let GA4Propertys = await GetGA4Info(`${GA4Url}/${propertyId}`, params, accessToken)
    if (GA4Propertys.code) {
        let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    } else if (!GA4Propertys) {
        let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let propertyDetaiils = GA4Propertys;
    // test 1
    if (!propertyDetaiils.name || !propertyDetaiils.industryCategory
        || !propertyDetaiils.currencyCode || !propertyDetaiils.timeZone) {
        let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    // test 2
    let webstreamdata = await GetGA4Info(`${GA4Url}/${propertyId}/dataStreams`, params, accessToken)
    if (!webstreamdata) {
        let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test2_summary, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }
    //test 3
    let dataStreamsType = webstreamdata.dataStreams
    let dataStreamsName = [];

    for (let i = 0; i < dataStreamsType.length; i++) {
        if (dataStreamsType[i].type === "WEB_DATA_STREAM") {
            if (dataStreamsType[i].displayName && dataStreamsType[i].webStreamData.defaultUri) {
                let name = /[^/]*$/.exec(dataStreamsType[i].name)[0];
                dataStreamsName.push(name)
            }
        }
    };
    if (dataStreamsName.length === 0) {
        let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test3_summary, pass_summary: '', fail_extra: reasons.test3_extra }
        return data;
    }
    // test 4  checking for status active (collecting data means active)
    const body = JSON.stringify({
        "dateRanges": [{ "startDate": "30daysAgo", "endDate": "yesterday" }],
        "dimensions": [{ "name": "streamId" }],
        "metrics": [{ "name": "active7DayUsers" }]
    });
    let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, body, accessToken)

    if (!resultData1 || !resultData1.data || !resultData1.data.rows) {
        let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test4_summary, pass_summary: '', fail_extra: reasons.test4_extra }
        return data;
    } else {
        for (let j = 0; j < resultData1.data.rows.length; j++) {
            if (dataStreamsName.includes(resultData1.data.rows[j]?.dimensionValues[0]?.value)) {
                if (!parseInt(resultData1.data.rows[j]?.metricValues[0]?.value) > 0) {
                    let data = { test_name: 'Property', status: false, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test4_summary, pass_summary: '', fail_extra: reasons.test4_extra }
                    return data;
                }
            }
        }
    }

    let data = { test_name: 'Property', status: true, test_id: 'GA01', tool: 'GA4', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
