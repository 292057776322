import { getUAReport } from '../../components/Api';
const url = process.env.REACT_APP_ACCOUNT_API
export const InternalTrafficTest = async (accountId, webPropertyId, profileId, accessToken) => {
    let reasons = {
        pass_summary: "Internal traffic is filtered out of your Universal Analytics property.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `No IP filter (for internal traffic) is applied on your reporting view. Learn more about <a href='https://support.google.com/analytics/answer/1034840' target="_blank" >excluding internal traffic here</a>.`,
        test1_extra: "Using IP view filters to prevent internal visits to your website is important to make sure you’re getting a clear idea of user behavior."
    }
    let Filters = await getUAReport(`${url}/${accountId}/filters`, accessToken)

    let ProfileData = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/profiles/${profileId}/profileFilterLinks`, accessToken)
    let result = false

    if (!Filters || !ProfileData) {
        let data = { test_name: 'Internal Traffic', status: false, test_id: 'UA05', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    if (Filters.totalResults > 0 && ProfileData.totalResults > 0) {
        for (let i = 0; i < Filters.items.length; i++) {
            for (let j = 0; j < ProfileData.items.length; j++) {
                if (Filters.items[i]?.name === ProfileData.items[j]?.filterRef.name) {
                    let field = Filters.items[i]?.excludeDetails ? Filters.items[i]?.excludeDetails.field : ''
                    if (field === 'GEO_IP_ADDRESS') {
                        result = true
                    }
                }
            }
        }
    }

    let resultData = (Filters.totalResults > 0 && ProfileData.totalResults > 0 && result) ? true : false

    if (!resultData) {
        let data = { test_name: 'Internal Traffic', status: false, test_id: 'UA05', tool: 'UA', difficulty_level: 1, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    let data = { test_name: 'Internal Traffic', status: true, test_id: 'UA05', tool: 'UA', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
