import { UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API
export const DataFragmentationTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "No major data fragmentation has been detected.",
        no_data: "No data available",
        no_permission: "No permission.",
        test1_summary: "Data fragmentation is observed for the following dimensions: [INSERT DIMENSION/S]. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.",
        test1_extra: "Fragmentation in page paths, source/medium/campaign values, or event names is when data for the same dimension value is split across multiple rows in Universal Analytics reports. "
    }


    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:campaign" }]
                }
            ]
    });
    const sourceMediumParams = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:sourceMedium" }]
                }
            ]
    });

    const eventCategoryParams = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:eventCategory" }]
                }
            ]
    });

    const pagePathParams = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dimensions": [{ "name": "ga:pagePath" }],
                    "dateRanges": [
                        {
                            "startDate": "60daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });

    let pagePathResult = await UAReportBehaviourLandings(url, pagePathParams, accessToken)
    let resultData = await UAReportBehaviourLandings(url, params, accessToken);
    let sourceMediumData = await UAReportBehaviourLandings(url, sourceMediumParams, accessToken);
    let eventCategoryData = await UAReportBehaviourLandings(url, eventCategoryParams, accessToken);

    if (resultData || sourceMediumData || eventCategoryData || pagePathResult) {
        if (resultData.code || sourceMediumData.code || eventCategoryData.code) {
            let data1 = { test_name: 'Data Fragmentation', status: false, test_id: 'UA19', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data1;
        }
    }
    if ((!resultData || Object.keys(resultData).length === 0 || resultData?.rowCount < 1 || !resultData?.rows) || (!sourceMediumData || Object.keys(sourceMediumData).length === 0 || sourceMediumData?.rowCount < 1 || !sourceMediumData?.rows) || (!eventCategoryData || Object.keys(eventCategoryData).length === 0 || eventCategoryData?.rowCount < 1 || !eventCategoryData?.rows) || (!pagePathResult || Object.keys(pagePathResult).length === 0 || pagePathResult?.rowCount < 1 || !pagePathResult?.rows)) {
        let data1 = { test_name: 'Data Fragmentation', status: false, test_id: 'UA19', tool: 'UA', difficulty_level: 2, fail_summary: `${reasons.no_data} ${!eventCategoryData?.rows ? 'for eventCategory.' : '.'}`, pass_summary: '', fail_extra: '' }
        return data1;
    }
    //test case 1:
    let eventCategory = [], sourceMedium = [], campaigns = [], pagePath = [];

    let rowCount = resultData?.rowCount;
    for (let count = 0; count < rowCount; count++) {
        campaigns.push(resultData?.rows[count]?.dimensions[0]?.toLowerCase().replaceAll(" ", ""))
    }
    let sourceMediumCount = sourceMediumData?.rowCount;
    for (let j = 0; j < sourceMediumCount; j++) {
        if (sourceMediumData?.rows[j]?.dimensions[0] != '(not set)') {
            sourceMedium.push(sourceMediumData?.rows[j]?.dimensions[0]?.toLowerCase().replaceAll(" ", ""))
        }
    }
    let eventCategoryCount = eventCategoryData?.rowCount;
    for (let k = 0; k < eventCategoryCount; k++) {
        eventCategory.push(eventCategoryData?.rows[k]?.dimensions[0]?.toLowerCase().replaceAll(" ", ""))
    }

    const campaignIds = ["?fbclid", "?gclid", "?dc_cid", "?cid", "tid", "?msclkid", "?utm_medium", "?utm_source", "?utm_term", "?utm_campaign", "?utm_content", "?utm_id"];
    let test2 = false

    let pagePathResultCount = pagePathResult?.rowCount;
    for (let r = 0; r < pagePathResultCount; r++) {
        let dimensions = pagePathResult?.rows[r]?.dimensions[0].toLowerCase().replaceAll(" ", "")
        pagePath.push(dimensions)
        let isTrue = campaignIds.some(v => dimensions?.includes(v))
        test2 = true
        if (isTrue) {
            test2 = false;
        }
    }

    if (hasDuplicates(sourceMedium) || hasDuplicates(eventCategory) || hasDuplicates(campaigns) || hasDuplicates(pagePath)) {
        let test1_summary_data;
        if (hasDuplicates(sourceMedium)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: sourceMedium. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        if (hasDuplicates(campaigns)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: campaigns. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        if (hasDuplicates(eventCategory)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: eventCategory. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        if (hasDuplicates(pagePath)) {
            test1_summary_data = `Data fragmentation is observed for the following dimensions: pagePath. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        }
        let data = { test_name: 'Data Fragmentation', status: false, test_id: 'UA19', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }

    //test case 2:
    if (!test2) {
        let test1_summary_data = `Data fragmentation is observed for the following dimensions: pagePath. Audit and rectify the letter case, whitespaces, and other inconsistencies in values in the applicable tools/platforms.`
        let data = { test_name: 'Data Fragmentation', status: false, test_id: 'UA19', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    let data1 = { test_name: 'Data Fragmentation', status: true, test_id: 'UA19', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
