import { UAReportBehaviourLandings } from '../../components/Api';

const url = process.env.REACT_APP_BATCH_API
export const LandingPagesTest = async (viewId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }
    let reasons = {
        pass_summary: "There are no significant changes in bounce rates on any major landing pages.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "The bounce rate has increased rapidly on [INSERT DATE/S] on page [INSERT PAGE PATHS]. Ensure that page is able to be loaded and is not returning errors.",
        test1_extra: "If a major landing page has an engagement rate spike, this indicates a significant change on that page. A positive spike might indicate that the page has become less optimised, that a new traffic source is sending low-quality leads to that page, or that the page is having technical difficulties. A negative spike might indicate that recent changes to the page has increased its stickiness or that a source of low-quality leads is no longer sending as much traffic to that page."
    }
    const params1 = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [
                        { "expression": "ga:entrances" },
                    ],
                    "dimensions": [
                        { "name": "ga:landingPagePath" }
                    ],
                    "dateRanges": [{
                        "startDate": "60daysAgo",
                        "endDate": "yesterday",
                    }
                    ],
                    "pageSize": 10,
                    "orderBys": [
                        {
                            "fieldName": "ga:entrances",
                            "sortOrder": "DESCENDING"
                        }
                    ]
                }
            ]
    });

    let resultData1 = await UAReportBehaviourLandings(url, params1, accessToken)
    if (resultData1) {
        if (resultData1.code) {
            let data1 = { test_name: 'Landing Pages', status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data1;
        }
    }
    if (!resultData1 || !resultData1?.rows || !resultData1?.rows?.length) {
        let data1 = { test_name: 'Landing Pages', status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    // total entrances
    let sum = resultData1?.totals ? resultData1?.totals[0]?.values[0] : 0;
    if (sum === 0) {
        let data1 = { test_name: 'Landing Pages', status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    let landingPage = []
    resultData1?.rows?.forEach(element => {
        if ((element?.metrics[0].values[0]) > (sum * 0.05))
            landingPage.push(element?.dimensions[0])
    });
    let resultPages = []
    if (landingPage.length > 0) {
        const params = JSON.stringify({
            "reportRequests":
                [
                    {
                        "viewId": viewId,
                        "metrics": [
                            { "expression": "ga:entrances" },
                            { "expression": "ga:bounceRate" },
                        ],
                        "dimensions": [
                            { "name": "ga:date" },
                            { "name": "ga:landingPagePath" }
                        ],
                        "dateRanges": [{
                            "startDate": "60daysAgo",
                            "endDate": "yesterday",
                        }
                        ],
                        "dimensionFilterClauses": [
                            {
                                "filters": [
                                    {
                                        "dimensionName": "ga:landingPagePath",
                                        "operator": "IN_LIST",
                                        "expressions": landingPage,
                                    }
                                ]
                            }
                        ],
                    }
                ]
        });

        let resultData1 = await UAReportBehaviourLandings(url, params, accessToken)
        if (resultData1) {
            if (resultData1.code) {
                let data1 = { test_name: 'Landing Pages', status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
                return data1;
            }
        }
        if (!resultData1 || !resultData1?.rows || !resultData1?.rows?.length) {
            let data1 = { test_name: 'Landing Pages', status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
            return data1;
        }

        // choosing date from resultData1
        let datesAll = []
        for (let j = resultData1?.rows?.length - 1; j >= 0; j--) {
            datesAll.push(resultData1?.rows[j]?.dimensions[0])
        }
        let dates60 = new Set(datesAll);
        dates60 = Array.from(dates60);

        for (let k = 0; k < landingPage?.length; k++) {
            let day1test = false;
            // 1 day
            for (let d = 0; d < dates60.length - 7; d++) {
                // day 1
                let last1day = 0, before1day = 0;
                for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                    if (resultData1?.rows[j]?.dimensions[0] === dates60[d] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                        last1day = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                        break;
                    }
                    last1day = 0;
                }
                // day 2
                for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                    if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 1] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                        before1day = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                        break;
                    }
                    before1day = 0;
                }
                // before 7 day
                let before7days12 = 0;
                for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                    if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 7] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                        before7days12 = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                        break;
                    }
                    before7days12 = 0;
                }
                // day 1 and day 2
                let check = (last1day - before1day) > before1day * 0.2 ? false : true;
                let check12 = (last1day - before7days12) > before7days12 * 0.2 ? false : true;
                if (!check && !check12) {
                    resultPages.push(`<br/><span>&bullet;</span> 1 day(${createDate(dates60[d])}) on page ${landingPage[k]}`)
                    day1test = true
                    break;
                }
            }
            if (!day1test) {
                // 3 days
                for (let d = 0; d < dates60.length - 3; d++) {
                    let firstDay = 0, secondDay = 0, thirdDay = 0, fourthDay = 0, fifthDay = 0, sixDay = 0;
                    // day 1
                    for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                        if (resultData1?.rows[j]?.dimensions[0] === dates60[d] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                            firstDay = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                            break;
                        }
                        firstDay = 0;
                    }
                    // day 2
                    for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                        if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 1] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                            secondDay = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                            break;
                        }
                        secondDay = 0;
                    }
                    // day 3
                    for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                        if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 2] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                            thirdDay = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                            break;
                        }
                        thirdDay = 0;
                    }
                    // day 4
                    for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                        if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 3] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                            fourthDay = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                            break;
                        }
                        fourthDay = 0;
                    }
                    //day 5
                    for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                        if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 4] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                            fifthDay = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                            break
                        }
                        fifthDay = 0;
                    }
                    //day 6
                    for (let j = resultData1?.rows?.length - 1; j > 0; j--) {
                        if (resultData1?.rows[j]?.dimensions[0] === dates60[d + 5] && resultData1?.rows[j]?.dimensions[1] === landingPage[k]) {
                            sixDay = parseFloat(resultData1?.rows[j]?.metrics[0]?.values[1])
                            break;
                        }
                        sixDay = 0;
                    }
                    let before3days = parseFloat(sixDay + fifthDay + fourthDay);
                    let last3days = parseFloat(thirdDay + secondDay + firstDay);
                    let check3 = (last3days - before3days) > before3days * 0.3 ? false : true;
                    if (!check3) {
                        resultPages.push(`<br/><span>&bullet;</span> 3 days(${createDate(dates60[d + 2])}, ${createDate(dates60[d + 1])} and ${createDate(dates60[d])}) on page ${landingPage[k]}`)
                        break;
                    }
                }
            }
        }
    } else {
        let data1 = { test_name: 'Landing Pages', status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    if (resultPages.length > 0) {
        let test1_summary_data = `The bounce rate has increased rapidly on` + `${resultPages}` + `<br/>Ensure that page is able to be loaded and is not returning errors. `
        let data = {
            test_name: 'Landing Pages',
            status: false, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
        }
        return data;
    }
    let data1 = { test_name: 'Landing Pages', status: true, test_id: 'UA16', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
