import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import {  useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import Login from '../../../../components/Login'
import { useNavigate, useLocation } from "react-router-dom";
import { removeToken, backendApi } from '../../../../components/Api';

const Header = ({ onSidebarOpen,userDetailsdata, colorInvert = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { mode } = theme.palette;
  
  const location = useLocation();
  const clientId = process.env.REACT_APP_CLIENT_ID
  const apiKey = process.env.REACT_APP_API_KEY
  const serverUrl = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        apiKey: apiKey,
        scope: 'https://www.googleapis.com/auth/analytics.readonly',
        plugin_name: 'streamy'
      });
    };
    gapi.load('client:auth2', initClient);
  });
  let userDetails = userDetailsdata ? userDetailsdata : ''

  const logout = async () => {

    const body =
      {
        email: userDetails?.email,
      }
     let logoutData = await backendApi(`${serverUrl}api/auth/logout`, body)
     removeAccess()
    if (gapi?.auth2?.getAuthInstance()) {
      let auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        localStorage.clear()
        navigate('/', { state: {} })
      });
    } else {
      localStorage.clear()
      navigate('/', { state: {} })
    }
  };

 

  document.addEventListener("mousemove", () => {
    localStorage.setItem('lastActvity', new Date())
  });
  document.addEventListener("click", () => {
    localStorage.setItem('lastActvity', new Date())
  });

  let timeInterval = setInterval(() => {
    let lastAcivity = localStorage.getItem('lastActvity')
    var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
    var seconds = Math.floor((diffMs / 1000));
    var minute = Math.floor((seconds / 60));
    if (minute == 30) {
      clearInterval(timeInterval)
      let logOutButton = document.getElementById('logoutButton')
      if (logOutButton != null || logOutButton != 'null' || logOutButton != 'undefined') {
        document.getElementById('logoutButton').click();
      }
      if (userDetails != '')
        logout()
    }
  }, 1000)

  let expireAt = location?.state?.expire_at ? location?.state?.expire_at : localStorage.getItem('expire_at')

  useEffect(() => {
    if ((expireAt != undefined || expireAt != null) && Math.round(new Date().getTime() / 1000) > expireAt) {
      let logOutButton = document.getElementById('logoutButton')
      if (logOutButton != null || logOutButton != 'null' || logOutButton != 'undefined') {
        document.getElementById('logoutButton').click();
      }
      logout()
      console.log("user successfully logout")
    }
  });
  let accessToken = location?.state?.accessToken ? location?.state?.accessToken : localStorage.getItem('access_token')

  useEffect(() => {
    if (userDetails === 'null' || userDetails === 'undefined' || accessToken === 'null' || accessToken === 'undefined') {
      logout()
    }
  }, []);


  const removeAccess = async () => {
    let url = await removeToken(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`)
  }
  return (
    <>
      <Box className="header-box"
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={1}
        height={{ xs: 60, md: 80 }}
        paddingY={1}
        boxShadow={'none'}
      >
        <Link component="a"
          to="/"

          className="main-logo"
        >
          <Box
            component={'img'}
            src={Logo}
            width={{ xs: 180, sm: 200, md: 250 }}
          />
        </Link>
        <Box className="main-menu" sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
          {window.location.pathname === '/analytics-report' ?
            <Box className="menu-item">
              <Link
                component="a"
                to="/"
              >Take Another Test
              </Link>
            </Box> : ''}
          <Box className="menu-item">
            <Link
              component="a"
              to="/contact"
            >Contact us
            </Link>
          </Box>
          {userDetails == '' || userDetails === 'null' || userDetails === 'undefined' || !userDetails ?
            <Box className="header-btn" marginLeft={2}>
              <Login endIcon={false}/>
            </Box> :
            <Box marginLeft={2}>
              <GoogleLogout
                // buttonText="Log out"
                className='googleButton'
                disabled={false}
                isSignedIn={false}
                onLogoutSuccess={logout}
                render={renderProps => (
                  <Button id="logoutButton" className='theme-btn lite-btn' onClick={renderProps.onClick} size="small">Log out</Button>)}
              />
            </Box>}
        </Box>
        <Box className="menuToggle" sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant={'outlined'}
            sx={{
              borderRadius: 2,
              minWidth: 'auto',
              padding: 1,
            }}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Box>
    </>
  );
};

Header.propTypes = {
  onSidebarOpen: PropTypes.func,
  colorInvert: PropTypes.bool,
};

export default Header;
