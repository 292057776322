import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Content = () => {
  return (
    <Box>
      <Box>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
        >
          General
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          IMWT Pty Ltd trading as In Marketing We Trust
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          By accessing this website, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained on <a target="_blank" rel="noreferrer" href="https://inmarketingwetrust.com.au/">https://inmarketingwetrust.com.au/</a> are protected by applicable copyright and trade mark law.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          1. Use Licence
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          Permission is granted to temporarily download one copy of the materials (information or software) on In Marketing We Trust's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
        </Typography>
        <Typography component={'ul'} marginBottom={2}>
          <li>modify or copy the materials;</li>
          <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
          <li>attempt to decompile or reverse engineer any software contained on In Marketing We Trust’s website;</li>
          <li>remove any copyright or other proprietary notations from the materials; or</li>
          <li>Transfer the materials to another person or “mirror” the materials on any other server.</li>
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          This license shall automatically terminate if you violate any of these restrictions and may be terminated by In Marketing We Trust at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          2. Disclaimer
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          The materials on In Marketing We Trust’s website are provided “as is”. In Marketing We Trust makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, In Marketing We Trust does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          3. Limitations
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          In no event shall In Marketing We Trust or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on In Marketing We Trust’s Internet site, even if In Marketing We Trust or a In Marketing We Trust authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          4. Revisions and Errors
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          The materials appearing on In Marketing We Trust’s website could include technical, typographical, or photographic errors. In Marketing We Trust does not warrant that any of the materials on its website are accurate, complete, or current. In Marketing We Trust may make changes to the materials contained on its website at any time without notice. In Marketing We Trust does however, attempt to keep all information about its services up-to-date.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          5. External Links
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          In Marketing We Trust has not reviewed all the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link materials is not endorsed by In Marketing We Trust. Use of any such linked website is at the user’s own risk.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          6. Terms of Use
        </Typography>
        <Typography component={'p'} marginBottom={2}>
          In Marketing We Trust may revise these terms of use for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
        </Typography>
        <Typography
          variant={'h5'}
          fontWeight={600}
          gutterBottom
          marginTop={4}
        >
          7. Governing Law
        </Typography>
        <Typography component={'p'}>
          Any claim relating to In Marketing We Trust’s website shall be governed by the laws of the State of New South Wales, Australia without regard to its conflict of law provisions.
        </Typography>
      </Box>
    </Box>
  );
};

export default Content;
