import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA
export const EnhancementTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Your Google Analytics 4 property is using enhanced measurements and collects events.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Enhanced measurement is not enabled in one of your data streams. See how to enable Enhanced Measurement a data stream <a href='https://support.google.com/analytics/answer/9216061' target="blank" >here</a>.`,
        test2_summary: "Only 1 Enhanced measurement event is currently active in the Google Analytics 4 property. In Google Analytics 4, click Admin > In the Property column, click Data Streams > Choose the Web data stream > Under Enhanced measurement, click the settings icon to edit individual options.",
        test1_extra: `<a href='https://support.google.com/analytics/answer/9216061' target="blank" >Enhanced measurement</a> allows you to report on content interactions in the Google Analytics 4 interface without the need for hard coding.`,
        test2_extra: `<a href='https://support.google.com/analytics/answer/9216061' target="blank" >Enhanced measurement</a> allows you to report on content interactions in the Google Analytics 4 interface without the need for hard coding.`,
    }
    const params = JSON.stringify({
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday",
            }
        ],
        "dimensions": [
            {
                "name": "eventName"
            }
        ],
        "metrics": [
            {
                "name": "eventCount"
            }
        ]
    });
    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData.code) {
        let data = { test_name: 'Enhancement Measurements', status: false, test_id: 'GA02', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!resultData || !resultData?.data || !resultData?.data?.rows) {
        let data = { test_name: 'Enhancement Measurements', status: false, test_id: 'GA02', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: ''}
        return data;
    }
    //test 2 check for any two events ... click, scroll
    let obj = resultData?.data?.rows
    let count = 0, test1 = false;
    for (let k = 0; k < resultData?.data?.rowCount; k++) {
        let check = obj[k]?.dimensionValues[0].value;
        if (check === "click" || check === "file_download" || check === "form_start" || check === "form_submit" || check === "scroll" || check === "video_complete" || check === "video_progress" || check === "video_start" || check === "view_search_results") {
            test1 = true;
            if (obj[k].metricValues[0].value > 0) {
                count++;
                if (count == 2) {
                    break;
                }
            }
        }
    };
    //test 1
    if (!test1) {
        let data = { test_name: 'Enhancement Measurements', status: false, test_id: 'GA02', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
    // test 2
    if (count < 2) {
        let data = { test_name: 'Enhancement Measurements', status: false, test_id: 'GA02', tool: 'GA4', difficulty_level: 1, fail_summary: reasons.test2_summary, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }

    let data = { test_name: 'Enhancement Measurements', status: true, test_id: 'GA02', tool: 'GA4', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;

}
