import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Divider, Button, Alert } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import FileSaver from "file-saver";
import { EmailShareButton } from "react-share";
import reportDownload from '../../../assets/images/report-download.png';
import reportPrint from '../../../assets/images/report-print.png';
import reportShare from '../../../assets/images/report-share.png';

const PageHead = (props) => {
  const location = useLocation();
  const [percentageData, setPercentage] = useState(props.percentageData);
  const [dynamicPercentage, setDynamicPercentage] = useState(0.00);
  const [dynamicText, setDynamicText] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [pdfName, setPdfName] = useState(props?.downloadData?.pdf_name);
  const [pdfPath, setPdfPath] = useState(props?.downloadData?.pdf_path);
  const [testName, setTestName] = useState('');
  const [dynamicTestName, setDynamicTestName] = useState('');

  let averageData = location?.state?.averageScore
  let updatedAccData = location?.state?.averageScore?.accountdata
  let propertyId = updatedAccData?.property
  let accountId = updatedAccData?.account
  let viewId =updatedAccData?.view
  let pdfcount = averageData?.pdfcount
  let averageScore = averageData?.average ? averageData?.average : 0.00
  let optionalCount = props?.optionalCount
  const [statusTrue, setStatusTrue] = useState(props?.statusTrue);
  const [statusFail, setStatusFail] = useState(props?.statusFail);
  useEffect(() => {
    setPercentage(props.percentageData)
    if (Object.keys(props?.downloadData).length !== 0) {
      setPdfName(props?.downloadData?.pdf_name)
      setPdfPath(props?.downloadData?.pdf_path)
      let name = props?.downloadData?.pdf_name
      setTestName(name ? name.split(".")[0] : dynamicTestName)
    }
    setStatusTrue(props?.statusTrue)
    setStatusFail(props?.statusFail)
  }, [props.percentageData, props?.downloadData,props?.statusTrue,props?.statusFail])

  useEffect(() => {
    setStatusTrue(props?.statusTrue)
    setStatusFail(props?.statusFail)
  }, [props?.statusTrue, props?.statusFail])


  let date_ob = new Date();
  let day = ("0" + date_ob.getDate()).slice(-2);
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let year = date_ob.getFullYear();
  let date = year + "" + month + "" + day;

  useEffect(() => {

    let percentageValue = parseFloat((parseFloat(statusTrue)* 100 / (parseFloat(statusTrue) + parseFloat(statusFail)))).toFixed(2)

    percentageValue = percentageValue !='NaN' ?  parseFloat(percentageValue).toFixed(2) : 0.00
    if (percentageValue > parseFloat(averageScore)) {
      let aboveScore = (percentageValue - parseFloat(averageScore)).toFixed(2)
      setDynamicText(`You scored ${parseFloat(aboveScore).toFixed(2)} points better than the average score.`)
    } else {
      let belowScore = (parseFloat(averageScore) - percentageValue).toFixed(2)
      setDynamicText(`You scored ${parseFloat(belowScore).toFixed(2)} points less than the average score.`)
    }
    setDynamicPercentage((((parseFloat(statusTrue)) / (parseFloat(statusTrue) + parseFloat(statusFail))) * 100).toFixed(2))
    setDynamicTestName(propertyId?.startsWith('properties') ? `CMA-GA4-${propertyId.split("/").pop()}-${date}-${pdfcount}` : `CMA-UA-${viewId}-${date}-${pdfcount}`)
  }, [props?.statusFail, props?.statusTrue])


  let accountDetails = props?.accountDetails
  const downloadReport = () => {
    FileSaver.saveAs(pdfPath, pdfName);
  }

  const printPage = () => {
    let pdf = window.open(pdfPath)
    pdf.print();
  }

  return (
    <Box className="bg-gray-400">
      <Container className='space'>
        <Box>
          <Box>
            <Box>
              <Box>
                <Typography
                  variant="h4"
                  fontSize={{ xs: 24, sm: 28, md: 34 }}
                  marginBottom={2}
                  align={'center'}
                  sx={{
                    fontWeight: 800,
                  }}
                >
                  {testName ? testName : dynamicTestName}
                </Typography>
                <Typography
                  variant="h5"
                  fontSize={{ xs: 20, sm: 22, md: 24 }}
                  align={'center'}
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Your analytics report score
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  fontSize={{ xs: 42, sm: 52, md: 62 }}
                  align={'center'}
                  sx={{
                    fontWeight: 800
                  }}
                >
                  {percentageData?.percentage ? percentageData?.percentage : dynamicPercentage !='NaN' ? dynamicPercentage : 0.00}
                  <Typography variant="p" fontSize={36} sx={{ fontWeight: 400 }}>%</Typography>
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  marginTop={2}
                  marginBottom={2}
                  fontSize={{ xs: 18, sm: 20 }}
                  sx={{ fontWeight: 400 }}
                  align={'center'}
                >
                  The account we are checking is
                </Typography>
              </Box>
            </Box>
            <Box>
              <Grid container spacing={{xs:4, sm:2}}>
                <Grid item xs={12} md={4}>
                  <Box width={1} height={1}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        fontSize={{ xs: 18, sm: 20 }}
                        sx={{ fontWeight: 600 }}
                        align={'center'}
                      >
                        Account
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        fontSize={{ xs: 24, sm: 28 }}
                        sx={{ fontWeight: 700, wordBreak: 'break-word' }}
                        align={'center'}
                      >
                        {updatedAccData?.account_name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box width={1} height={1}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >

                      <Typography
                        variant="p"
                        component="p"
                        fontSize={{ xs: 18, sm: 20 }}
                        sx={{ fontWeight: 600 }}
                        align={'center'}
                      >
                        Property
                      </Typography>
                      <Typography
                        variant="p"
                        component="p"
                        fontSize={{ xs: 24, sm: 28 }}
                        sx={{ fontWeight: 700, wordBreak: 'break-word' }}
                        align={'center'}
                      >
                        {updatedAccData?.property_name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  {!propertyId?.startsWith('properties') ?
                    <Box width={1} height={1}>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                      >

                        <Typography
                          variant="p"
                          component="p"
                          fontSize={{ xs: 18, sm: 20 }}
                          sx={{ fontWeight: 600 }}
                          align={'center'}
                        >
                          View
                        </Typography>
                        <Typography
                          variant="p"
                          component="p"
                          fontSize={{ xs: 24, sm: 28 }}
                          sx={{ fontWeight: 700, wordBreak: 'break-word' }}
                          align={'center'}
                        >
                          {updatedAccData?.view_name}
                        </Typography>
                      </Box>
                    </Box> : ""}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box marginY={4}>
            <Divider />
          </Box>
          <Box>
            <Box textAlign={'center'}>
              <Typography
                variant="p"
                component="p"
                marginBottom={{ xs: 3, sm: 5 }}
                fontSize={{ xs: 16, sm: 20 }}
                sx={{ fontWeight: 400 }}
                align={'center'}
              >
                {percentageData?.average ? percentageData?.text : dynamicText}
              </Typography>
              <Box>
                <Button className="theme-btn" component={Link} target="_blank" to="/contact">Contact us to improve your score</Button>
              </Box>
              <Box marginTop={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    color: 'text.primary',
                    justifyContent: 'center',
                    width: 1,
                  }}
                >
                  {percentageData?.percentage || percentageData?.percentage === 0 ?
                    <Box sx={{ marginX: 2, cursor: 'pointer' }} onClick={() => downloadReport()}>
                      <Box
                        component={'img'}
                        src={reportDownload}
                        width={32}
                      />
                    </Box>
                    :
                    <Box sx={{ marginX: 2, cursor: 'not-allowed' }}>
                      <Box
                        component={'img'}
                        src={reportDownload}
                        width={32}
                      />
                    </Box>
                  }
                  {percentageData?.percentage || percentageData?.percentage === 0 ?
                    <Box sx={{ marginX: 2, cursor: 'pointer' }} onClick={() => { printPage() }}>
                      <Box
                        component={'img'}
                        src={reportPrint}
                        width={32}
                      />
                    </Box>
                    :
                    <Box sx={{ marginX: 2, cursor: 'not-allowed' }}>
                      <Box
                        component={'img'}
                        src={reportPrint}
                        width={32}
                      />
                    </Box>
                  }
                  {percentageData?.percentage || percentageData?.percentage === 0 ?
                    <EmailShareButton
                      url={pdfPath}
                      subject={'Analytics Report'}
                      from={'report@cma.com.au'}
                      body={`Here is the report URL - `}
                    >
                      <Box sx={{ marginX: 2, cursor: 'pointer' }}>
                        <Box
                          component={'img'}
                          src={reportShare}
                          width={32}
                        />
                      </Box>
                    </EmailShareButton> :
                    <Box sx={{ marginX: 2, cursor: 'not-allowed' }}>
                      <Box
                        component={'img'}
                        src={reportShare}
                        width={32}
                      />
                    </Box>
                  }
                </Box>
                {alertMessage ?
                  <Box class="muiAlert-root-wrap">
                    <Alert severity="success">{alertMessage}</Alert>
                  </Box>
                  : ""}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PageHead;
