import { UAReportBehaviour } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API

export const MonthOnMonthBRTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "Month-on-month bounce rate is stable.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `The month-on-month bounce rate has increased by [INSTERT % CHANGE] for [INSERT MONTH]. This may indicate that the site is not serving content correctly. Learn more about bounce rate <a href='https://support.google.com/analytics/answer/1009409' target="_blank" >here</a>.`,
        test1_extra: "A bounce spike indicates that the performance of one or more of your landing pages has changed dramatically. It might indicate that a landing page has become less optimised, that a new traffic source is sending low-quality leads, or that a landing page is having technical difficulties. "
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:bounces" },
                    { "expression": "ga:bounceRate" }],
                    "dimensions": [{ "name": "ga:date" }],
                    "dateRanges": [
                        {
                            "startDate": "60daysAgo",
                            "endDate": "31daysAgo"
                        },
                        {
                            "startDate": "30daysAgo",
                            "endDate": "yesterday"
                        }]
                }
            ]
    });

    let resultData1 = await UAReportBehaviour(url, params, accessToken);
    if (resultData1) {
        if (resultData1.code) {
            let data = { test_name: 'Month On Month Bounce Rate', status: false, test_id: 'UA12', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData1 || Object.keys(resultData1).length === 0) {
        let data = { test_name: 'Month On Month Bounce Rate', status: false, test_id: 'UA12', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    const firstMonthRate = parseFloat(resultData1[0].values[1]);
    const secondMonthRate = parseFloat(resultData1[1].values[1]);

    if (firstMonthRate > 0.0 && secondMonthRate > 0.0) {
        // Test 1: checking for month on month bounce rate increase by 10%
        let check = ((secondMonthRate - firstMonthRate) > (firstMonthRate * 0.1)) ? false : true;
        if (!check) {
            let test1_summary_data;
            test1_summary_data = `The month-on-month bounce rate has increased by ${firstMonthRate > 0 ? (((secondMonthRate - firstMonthRate) * 100 / (firstMonthRate))).toFixed(2) : ((secondMonthRate)).toFixed(2)}% in last 30 days compared to previous period. This may indicate that the site is not serving content correctly. Learn more about bounce rate <a href='https://support.google.com/analytics/answer/1009409' target="_blank" >here</a>.`

            let data = { test_name: 'Month On Month Bounce Rate', status: false, test_id: 'UA12', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
            return data;
        }
    }
    else {
        let data = { test_name: 'Month On Month Bounce Rate', status: false, test_id: 'UA12', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let data = { test_name: 'Month On Month Bounce Rate', status: true, test_id: 'UA12', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}