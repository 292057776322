import { GA4Report, GetGA4Info } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA;
const url = process.env.REACT_APP_GA4_URL
const apiKey = process.env.REACT_APP_API_KEY

export const CustomDefinitionsTestGA4 = async (propertyId, accessToken) => {
    let pass_summary = "Your Google Analytics 4 property is using custom dimensions and/or metrics.";
    let test1_extra = "Custom definitions can be extremely useful in analyzing data that Analytics doesn't automatically collect. We highly recommend creating dimensions and metrics from your event parameters and user properties so you can easily access data in reports and explorations.";
    let test1_summary;
    let status = true;
    let paramsAll = { key: apiKey }
    const customDimensionsList = await GetGA4Info(`${url}/${propertyId}/customDimensions`,
        paramsAll, accessToken)

    // test 1:
    if (!customDimensionsList || !customDimensionsList.customDimensions) {
        test1_summary = `Your Google Analytics 4 property is not using any custom dimensions. (Learn more about <a href='https://support.google.com/analytics/answer/10075209' target="_blank" >Google Analytics 4 custom definitions</a>.)`
        status = false
        pass_summary = ''
    }

    const customMetricsList = await GetGA4Info(`${url}/${propertyId}/customMetrics`,
        paramsAll, accessToken)

    // test 2:
    if (!customMetricsList || !customMetricsList.customMetrics) {
        test1_summary = `Your Google Analytics 4 property is not using any custom metrics. (Learn more about <a href='https://support.google.com/analytics/answer/10075209' target="_blank" >Google Analytics 4 custom definitions</a>.)`
        status = false
        pass_summary = ''
    }
    if ((!customDimensionsList || !customDimensionsList.customDimensions) && (!customMetricsList || !customMetricsList.customMetrics)) {
        test1_summary = ` Your Google Analytics 4 property is not using any custom dimensions and metrics. (Learn more about <a href='https://support.google.com/analytics/answer/10075209' target="_blank" >Google Analytics 4 custom definitions</a>.)`
        status = false
        pass_summary = ''
    }

    let dimensionsList = customDimensionsList?.customDimensions ? customDimensionsList?.customDimensions : []
    if ((customDimensionsList || customDimensionsList.customDimensions) && (customMetricsList || customMetricsList.customMetrics)) {
        if (dimensionsList.length > 0) {
            for (let i = 0; i < dimensionsList.length; i++) {
                let parameterName = dimensionsList[i]?.parameterName ? dimensionsList[i]?.parameterName : ''
                if (parameterName !== '') {
                    const pagePathParams = JSON.stringify({
                        "dimensions": [
                            { "name": `customEvent:${parameterName}` }
                        ],
                        "metrics": [
                            { "name": "active28DayUsers" }
                        ],
                        "dateRanges": [
                            {
                                "startDate": "30daysAgo",
                                "endDate": "yesterday"
                            }
                        ],
                    });
                    let result = await GA4Report(`${URL}/${propertyId}:runReport`, pagePathParams, accessToken);
                    let rowCount = result?.data?.rowCount ? result?.data?.rowCount : 0
                    if (rowCount === 0) {
                        test1_summary = `All custom dimensions are not active. (Learn more about <a href='https://support.google.com/analytics/answer/10075209' target="_blank" >Google Analytics 4 custom definitions</a>.)`
                        status = false
                        pass_summary = ''
                        break;
                    }

                }
            }
        }
    }
    let metricsList = customMetricsList?.customMetrics ? customMetricsList?.customMetrics : []
    if (metricsList.length > 0 && status) {
        for (let i = 0; i < metricsList.length; i++) {
            let parameterName = metricsList[i]?.parameterName ? metricsList[i]?.parameterName : ''
            if (parameterName !== '') {
                const pagePathParams = JSON.stringify({
                    "metrics": [
                        { "name": `countCustomEvent:${parameterName}` }
                    ],
                    "dateRanges": [
                        {
                            "startDate": "30daysAgo",
                            "endDate": "yesterday"
                        }
                    ],
                });
                let result = await GA4Report(`${URL}/${propertyId}:runReport`, pagePathParams, accessToken);
                let rowCount = result?.data?.rowCount ? result?.data?.rowCount : 0
                if (rowCount === 0) {
                    test1_summary = `All custom metrics are not active. (Learn more about <a href='https://support.google.com/analytics/answer/10075209' target="_blank" >Google Analytics 4 custom definitions</a>.)`
                    status = false
                    pass_summary = ''
                    break;
                }
            }
        }
    }

    if (status) {
        test1_summary = ''
        test1_extra = ''
    }

    let data = { test_name: 'Custom Definitions', status: status, test_id: 'GA07', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary, pass_summary: pass_summary, fail_extra: test1_extra }
    return data
}
