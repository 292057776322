import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box, Button, Grid, Container, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import PageHead from './PageHead';
import Chip from '@mui/material/Chip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { backendApi } from '../../../components/Api';
// Setting Test
import { ContentGroupTest } from '../../UA/ContentGroupTest';
import { CampaignTest } from '../../UA/CampaignTest';
import { EventTest } from '../../UA/EventTest';
import { ConversionsTest } from '../../UA/ConversionsTest';
import { SiteSearchTest } from '../../UA/SiteSearchTest';
import { InternalTrafficTest } from '../../UA/InternalTrafficTest';
import { CustomDefinitionsTest } from '../../UA/CustomDefinitionsTest';
import { ToolsIntegrationTest } from '../../UA/ToolsIntegrationTest';
import { PropertyTest } from '../../UA/PropertyTest';
import { EcommerceTest } from '../../UA/EcommerceTest';

// Behaviour Test
import { MonthOnMonthBRTest } from '../../UA/MonthOnMonthBRTest';
import { BounceSpikeTest } from '../../UA/BounceSpikeTest';
import { MonthOnMonthTrafficTest } from '../../UA/MonthOnMonthTrafficTest';
import { TrafficSpikeTest } from '../../UA/TrafficSpikeTest';
import { LandingPagesTest } from '../../UA/LandingPagesTest';
import { ExitSpikesTest } from '../../UA/ExitSpikesTest';
import { ReferralDropOffTest } from '../../UA/ReferralDropOffTest';
import { DataFragmentationTest } from '../../UA/DataFragmentationTest';
import { ConversionRateTest } from '../../UA/ConversionRateTest';
// import { GTMImplementation } from '../../UA/GTMImplementation';
import { GTMDeployment } from '../../UA/GTMDeployment';
import { ThreeDots } from 'react-loader-spinner'

// GA4 settings
import { PropertyTestGA4 } from '../../GA4/PropertyTestGA4';
import { EnhancementTestGA4 } from '../../GA4/EnhancementTestGA4';
import { EventTestGA4 } from '../../GA4/EventTestGA4';
import { ConversionsTestGA4 } from '../../GA4/ConversionsTestGA4';
import { CustomDefinitionsTestGA4 } from '../../GA4/CustomDefinitionsTestGA4';
import { ToolsIntegrationTestGA4 } from '../../GA4/ToolsIntegrationTestGA4';
import { ContentGroupTestGA4 } from '../../GA4/ContentGroupTestGA4';
import { CampaignTestGA4 } from '../../GA4/CampaignTestGA4';
import { EcommerceTestGA4 } from '../../GA4/EcommerceTestGA4';

// GA4 behaviour
import { MonthOnMonthBRTestGA4 } from '../../GA4/MonthOnMonthBRTestGA4';
import { BounceSpikeTestGA4 } from '../../GA4/BounceSpikeTestGA4';
import { MonthOnMonthTrafficTestGA4 } from '../../GA4/MonthOnMonthTrafficTestGA4';
import { TrafficSpikeTestGA4 } from '../../GA4/TrafficSpikeTestGA4';
import { LandingPagesTestGA4 } from '../../GA4/LandingPagesTestGA4';
import { ReferralDropOffTestGA4 } from '../../GA4/ReferralDropOffTestGA4';
import { DataFragmentationTestGA4 } from '../../GA4/DataFragmentationTestGA4';
import { ConversionRateTestGA4 } from '../../GA4/ConversionRateTestGA4';
// import { GTMImplementationGA4 } from '../../GA4/GTMImplementationGA4';
import { GTMDeploymentGA4 } from '../../GA4/GTMDeploymentGA4';
const serverUrl = process.env.REACT_APP_SERVER_URL

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function CustomizedAccordions() {
    const location = useLocation();
    const navigate = useNavigate();
    let accessToken = location?.state?.accessToken ? location?.state?.accessToken : localStorage.getItem('access_token')
    // const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = useState(false);
   
    const handleClose = () => {
        setOpen(false)
        // setErrorMessage("")
        navigate('/')
    };
    // Light Tooltip
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    let userDetails = localStorage.getItem('userDetails')
    userDetails = userDetails !== 'undefined' ? JSON.parse(userDetails) : userDetails

    let accountDetails = localStorage.getItem('accountDetails')
    accountDetails = accountDetails !== 'undefined' ? JSON.parse(accountDetails) : accountDetails

    var reports =
    [
        { test_name: 'Property', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA01', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 1, outline: 'What we review: Property settings, view settings, and existence of data.' },
        { test_name: 'Site Search', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA02', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 2, outline: 'What we review: Site Search settings.' },
        { test_name: 'Events', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA03', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 3, outline: 'What we review: Event count.' },
        { test_name: 'Conversions', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA04', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 4, outline: 'What we review: Goals and goal completion count.' },
        { test_name: 'Internal Traffic', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA05', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 5, outline: 'What we review: IP view filter/s.' },
        { test_name: 'Custom Definitions', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA07', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 7, outline: 'What we review: Custom dimension and metric configurations.' },
        { test_name: 'Tools Integration', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA08', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 8, outline: 'What we review: Google Ads and/or Search Console if applicable.' },
        { test_name: 'Content Group', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA09', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 9, outline: 'What we review: Content groups and configurations.' },
        { test_name: 'Campaigns', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA10', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 10, outline: 'What we review: Campaign tracking data, including click IDs (e.g., fbclid, gclid)' },
        { test_name: 'Ecommerce', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA11', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 11, outline: 'What we review: Purchase and other ecommerce event data.' },
        { test_name: 'Month On Month Bounce Rate', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA12', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 12, outline: 'What we review: Month-on-month bounce rate.' },
        { test_name: 'Bounce Spike', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA13', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 13, outline: 'What we review: Last 60-day bounce rate.' },
        { test_name: 'Month On Month Traffic', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA14', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 14, outline: 'What we review: Month-on-month traffic of the past 60 days.' },
        { test_name: 'Traffic Spikes', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA15', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 15, outline: 'What we review: Month-on-month traffic of the past 60 days.' },
        { test_name: 'Landing Pages', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA16', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 16, outline: 'What we review: Landing page traffic in the past 60 days.' },
        { test_name: 'Exit Spikes', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA17', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 17, outline: 'What we review: Landing page exits in the past 60 days.' },
        { test_name: 'Referral Drop Off', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA18', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 18, outline: 'What we review: Referral source traffic in the past 60 days.' },
        { test_name: 'Data Fragmentation', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA19', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 19, outline: 'What we review: Page, Source, Medium, Campaign, and Event Category dimension values.' },
        { test_name: 'Conversion Rate', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA20', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 20, outline: 'What we review: Conversion rates in the past 60 days.' },
        { test_name: 'Google Tag Manager Deployment', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'UA21', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 21, outline: 'What we review: Google Tag Manager container in the top landing pages of major domains or subdomains.' },
        { test_name: 'Google Tag Manager Implementation', status: 2, test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'UA22', tool: 'UA', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 22, outline: 'What we review: Universal Analytics pageview tag, tracking ID, and UA event tags in Google Tag Manager container.' }
    ]

    var ga4Reports = [
        { test_name: 'Property', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA01', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 1, outline: 'What we review: Property settings, data stream configurations, and existence of data.' },
        { test_name: 'Enhancement Measurements', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA02', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 2, outline: 'What we review: Enhanced measurement setup.' },
        { test_name: 'Events', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA03', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 3, outline: 'What we review: Automatic, enhanced measurement, and advanced measurement events (custom/recommended).' },
        { test_name: 'Conversions', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA04', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 4, outline: 'What we review: Conversion events data in the last 30 days.' },
        { test_name: 'Custom Definitions', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA07', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 7, outline: 'What we review: Custom dimension and metric configurations.' },
        { test_name: 'Tools Integration', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA08', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 8, outline: 'What we review: Google Ads and/or Search Console if applicable.' },
        { test_name: 'Content Group', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA09', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 9, outline: 'What we review: Content groups.' },
        { test_name: 'Campaigns', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA10', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 10, outline: 'What we review: Campaign tracking data, including click IDs (e.g., fbclid, gclid)' },
        { test_name: 'Ecommerce', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA11', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 11, outline: 'What we review: Purchase and other ecommerce event data.' },
        { test_name: 'Month On Month Engagement Rate', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA12', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 12, outline: 'What we review: Month-on-month engagement rate.' },
        { test_name: 'Bounce Spike', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA13', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 13, outline: 'What we review: Last 60-day bounce rate.' },
        { test_name: 'Month On Month Traffic', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA14', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 14, outline: 'What we review: Month-on-month traffic of the past 60 days.' },
        { test_name: 'Traffic Spikes', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA15', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 15, outline: 'What we review: Month-on-month traffic of the past 60 days.' },
        { test_name: 'Landing Pages', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA16', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 16, outline: 'What we review: Landing page traffic in the past 60 days.' },
        { test_name: 'Referral Drop Off', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA18', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 18, outline: 'What we review: Referral source traffic in the past 60 days.' },
        { test_name: 'Data Fragmentation', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA19', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 19, outline: 'What we review: Page path, Source, Medium, Campaign, and Event name values.' },
        { test_name: 'Conversion Rate', status: 'Loading', test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA20', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 20, outline: 'What we review: Conversion rates in the past 60 days.' },
        { test_name: 'Google Tag Manager Deployment', status: 'Loading', test_section: 1, user_id: userDetails?.id, account_id: '', test_id: 'GA21', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 21, outline: 'What we review: Google Tag Manager container in the top landing pages of major domains or subdomains.' },
        { test_name: 'Google Tag Manager Implementation', status: 2, test_section: 2, user_id: userDetails?.id, account_id: '', test_id: 'GA22', tool: 'GA4', difficulty_level: '', fail_summary: '', pass_summary: '', fail_extra: '', order: 22, outline: 'What we review: GA4 configuration tag, measurement ID, and GA4 event tags in Google Tag Manager container.' },
    ]

    const [testSection, setTestSection] = useState(0);
    const [percentageData, setPercentageData] = useState({});
    const [downloadData, setDownloadData] = useState({});
    const [dfficultyLevel, setDfficultLevel] = useState(0);
    const [optionalTest, setOptionalTest] = useState(location?.state?.optionalTest);
    const [testReport, setTestReport] = useState(reports);
    const [resultCount, setResultCount] = useState({
        all: 0,
        failed: 0,
        passed: 0
    });
    let updatedAccData = location?.state?.averageScore?.accountdata

    const [resultType, setResultType] = useState(0);
    let propertyId = updatedAccData?.property
    let viewId = updatedAccData?.view
    let accountId = updatedAccData?.account
    let account_id = updatedAccData?.account_id

    const section = (section) => {
        if (localStorage.getItem(`isTestRun${updatedAccData?.account_id}`) === 'false') {
            setTestSection(section)
            filterData(section, dfficultyLevel, resultType)
        }
    }

    const level = (level) => {
        if (localStorage.getItem(`isTestRun${updatedAccData?.account_id}`) === 'false') {
            setDfficultLevel(level)
            filterData(testSection, level, resultType)
        }
    }

    const resultTpes = (result) => {
        if (localStorage.getItem(`isTestRun${updatedAccData?.account_id}`) === 'false') {
            setResultType(result)
            filterData(testSection, dfficultyLevel, result)
        }
    }
    const getReportPercentage = async () => {
        let body = {
            user_id: userDetails?.id,
            account_id: updatedAccData?.account_id,
            tool: propertyId?.startsWith('properties') ? "GA4" : "UA"
        }
        let response = await backendApi(`${serverUrl}api/analyticsreport/percentage`, body, userDetails?.jwt_access_token)
        setPercentageData(response)
        setDownloadData(response)
        if (Object.keys(response).length !== 0 && localStorage.getItem(`isTestRun${updatedAccData?.account_id}`) != 'false') {
            localStorage.setItem(`isTestRun${updatedAccData?.account_id}`, false)
            createReport(response.percentage, response.text)
        }
    }
    const createReport = async (percentage, text) => {
        let body = {
            user_id: userDetails?.id,
            account_id: updatedAccData?.account_id,
            percentage: percentage,
            average_text: text
        }
        let results = await backendApi(`${serverUrl}api/user/download_report`, body, userDetails?.jwt_access_token)
        setDownloadData(results)
    }
    const filterData = async (section, level, result, isFinal) => {

        let body = {
            test_section: section,
            difficulty_level: level,
            user_id: userDetails?.id,
            account_id: updatedAccData?.account_id,
            result_type: result
        }
        let res = await backendApi(`${serverUrl}api/analyticsreport/filter`, body, userDetails?.jwt_access_token)

        if (section == 0 && level == 0 && result == 0 && isFinal == 0) {
            getReportPercentage()
        }
        setTestReport(res.data)
        setResultCount({ ...resultCount, all: res.all, failed: res.failed, passed: res.passed })
    }

    useEffect(() => {
        if (!userDetails) {
            navigate('/')
        }
        if (localStorage.getItem(`isTestRun${updatedAccData?.account_id}`) != 'false') {
            // for GA4
            if (!optionalTest?.toolsIntegration) {
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === "GA08" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 1, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(ga4Reports)
            }
            if (!optionalTest?.ecommerce) {
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === "GA11" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 1, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(ga4Reports)
            }
            if (!optionalTest?.siteSearch) {
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === "GA02" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 1, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(ga4Reports)
            }
            if (!optionalTest?.campaigns) {
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === "GA10" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(ga4Reports)
            }
            // for UA
            if (!optionalTest?.toolsIntegration) {
                reports = reports.map(obj =>
                    obj.test_id === "UA08" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 1, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(reports)
            }

            if (!optionalTest?.siteSearch) {
                reports = reports.map(obj =>
                    obj.test_id === "UA02" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 1, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(reports)
            }
            if (!optionalTest?.campaigns) {
                reports = reports.map(obj =>
                    obj.test_id === "UA10" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
                );
                setTestReport(reports)
            }
            if (!optionalTest?.ecommerce) {
                reports = reports.map(obj =>
                    obj.test_id === "UA11" ? {
                        ...obj, account_id: account_id, status: 2, difficulty_level: 1, fail_summary: '',
                        pass_summary: '', fail_extra: '', outline: obj.outline
                    } : obj
                );
                setTestReport(reports)
            }
            testReportFun()
        } else {
            filterData(0, 0, 0, 0)
        }
        window.scrollTo(0, 0)
    }, []);

    const testReportFun = async () => {
        if (propertyId?.startsWith('properties')) {
            let PropertyResult = await PropertyTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === PropertyResult.test_id ? { ...obj, status: PropertyResult.status, account_id: account_id, test_id: PropertyResult.test_id, tool: PropertyResult.tool, difficulty_level: PropertyResult.difficulty_level, fail_summary: PropertyResult.fail_summary, pass_summary: PropertyResult.pass_summary, fail_extra: PropertyResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            if (optionalTest?.siteSearch) {
                let EnhancementTestGA4Result = await EnhancementTestGA4(propertyId, accessToken)
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === EnhancementTestGA4Result.test_id ? { ...obj, status: EnhancementTestGA4Result.status, account_id: account_id, test_id: EnhancementTestGA4Result.test_id, tool: EnhancementTestGA4Result.tool, difficulty_level: EnhancementTestGA4Result.difficulty_level, fail_summary: EnhancementTestGA4Result.fail_summary, pass_summary: EnhancementTestGA4Result.pass_summary, fail_extra: EnhancementTestGA4Result.fail_extra, outline: obj.outline } : obj
                );
                setTestReport(ga4Reports)
            }
            let EventResult = await EventTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === EventResult.test_id ? { ...obj, status: EventResult.status, account_id: account_id, test_id: EventResult.test_id, tool: EventResult.tool, difficulty_level: EventResult.difficulty_level, fail_summary: EventResult.fail_summary, pass_summary: EventResult.pass_summary, fail_extra: EventResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            let GoalResult = await ConversionsTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === GoalResult.test_id ? { ...obj, status: GoalResult.status, account_id: account_id, test_id: GoalResult.test_id, tool: GoalResult.tool, difficulty_level: GoalResult.difficulty_level, fail_summary: GoalResult.fail_summary, pass_summary: GoalResult.pass_summary, fail_extra: GoalResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            let CustomDefinitionsResult = await CustomDefinitionsTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === CustomDefinitionsResult.test_id ? { ...obj, status: CustomDefinitionsResult.status, account_id: account_id, test_id: CustomDefinitionsResult.test_id, tool: CustomDefinitionsResult.tool, difficulty_level: CustomDefinitionsResult.difficulty_level, fail_summary: CustomDefinitionsResult.fail_summary, pass_summary: CustomDefinitionsResult.pass_summary, fail_extra: CustomDefinitionsResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            if (optionalTest?.toolsIntegration) {
                let ToolsIntegrationResult = await ToolsIntegrationTestGA4(propertyId, accessToken)
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === ToolsIntegrationResult.test_id ? { ...obj, status: ToolsIntegrationResult.status, account_id: account_id, test_id: ToolsIntegrationResult.test_id, tool: ToolsIntegrationResult.tool, difficulty_level: ToolsIntegrationResult.difficulty_level, fail_summary: ToolsIntegrationResult.fail_summary, pass_summary: ToolsIntegrationResult.pass_summary, fail_extra: ToolsIntegrationResult.fail_extra, outline: obj.outline } : obj
                );
                setTestReport(ga4Reports)
            }

            let ContentGroupResult = await ContentGroupTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === ContentGroupResult.test_id ? { ...obj, status: ContentGroupResult.status, account_id: account_id, test_id: ContentGroupResult.test_id, tool: ContentGroupResult.tool, difficulty_level: ContentGroupResult.difficulty_level, fail_summary: ContentGroupResult.fail_summary, pass_summary: ContentGroupResult.pass_summary, fail_extra: ContentGroupResult.fail_extra, outline: obj.outline } : obj
            );

            setTestReport(ga4Reports)
            if (optionalTest?.campaigns) {
                let CampaignResult = await CampaignTestGA4(propertyId, accessToken)
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === CampaignResult.test_id ? { ...obj, status: CampaignResult.status, account_id: account_id, test_id: CampaignResult.test_id, tool: CampaignResult.tool, difficulty_level: CampaignResult.difficulty_level, fail_summary: CampaignResult.fail_summary, pass_summary: CampaignResult.pass_summary, fail_extra: CampaignResult.fail_extra, outline: obj.outline } : obj
                )
            }

            if (optionalTest?.ecommerce) {
                let EcommerceResult = await EcommerceTestGA4(propertyId, accessToken)
                ga4Reports = ga4Reports.map(obj =>
                    obj.test_id === EcommerceResult.test_id ? { ...obj, status: EcommerceResult.status, account_id: account_id, test_id: EcommerceResult.test_id, tool: EcommerceResult.tool, difficulty_level: EcommerceResult.difficulty_level, fail_summary: EcommerceResult.fail_summary, pass_summary: EcommerceResult.pass_summary, fail_extra: EcommerceResult.fail_extra, outline: obj.outline } : obj
                )
                setTestReport(ga4Reports)
            }

            // behaviour test start from here
            let MonthOnMonthBRResult = await MonthOnMonthBRTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === MonthOnMonthBRResult.test_id ? { ...obj, status: MonthOnMonthBRResult.status, account_id: account_id, test_id: MonthOnMonthBRResult.test_id, tool: MonthOnMonthBRResult.tool, difficulty_level: MonthOnMonthBRResult.difficulty_level, fail_summary: MonthOnMonthBRResult.fail_summary, pass_summary: MonthOnMonthBRResult.pass_summary, fail_extra: MonthOnMonthBRResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            let BounceSpikeResult = await BounceSpikeTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === BounceSpikeResult.test_id ? { ...obj, status: BounceSpikeResult.status, account_id: account_id, test_id: BounceSpikeResult.test_id, tool: BounceSpikeResult.tool, difficulty_level: BounceSpikeResult.difficulty_level, fail_summary: BounceSpikeResult.fail_summary, pass_summary: BounceSpikeResult.pass_summary, fail_extra: BounceSpikeResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            let MonthOnMonthTrafficResult = await MonthOnMonthTrafficTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === MonthOnMonthTrafficResult.test_id ? { ...obj, status: MonthOnMonthTrafficResult.status, account_id: account_id, test_id: MonthOnMonthTrafficResult.test_id, tool: MonthOnMonthTrafficResult.tool, difficulty_level: MonthOnMonthTrafficResult.difficulty_level, fail_summary: MonthOnMonthTrafficResult.fail_summary, pass_summary: MonthOnMonthTrafficResult.pass_summary, fail_extra: MonthOnMonthTrafficResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            let TrafficSpikeResult = await TrafficSpikeTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === TrafficSpikeResult.test_id ? { ...obj, status: TrafficSpikeResult.status, account_id: account_id, test_id: TrafficSpikeResult.test_id, tool: TrafficSpikeResult.tool, difficulty_level: TrafficSpikeResult.difficulty_level, fail_summary: TrafficSpikeResult.fail_summary, pass_summary: TrafficSpikeResult.pass_summary, fail_extra: TrafficSpikeResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)

            let RefrerralDropOffResult = await ReferralDropOffTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === RefrerralDropOffResult.test_id ? { ...obj, status: RefrerralDropOffResult.status, account_id: account_id, test_id: RefrerralDropOffResult.test_id, tool: RefrerralDropOffResult.tool, difficulty_level: RefrerralDropOffResult.difficulty_level, fail_summary: RefrerralDropOffResult.fail_summary, pass_summary: RefrerralDropOffResult.pass_summary, fail_extra: RefrerralDropOffResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            let DataFragmentationResult = await DataFragmentationTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === DataFragmentationResult.test_id ? { ...obj, status: DataFragmentationResult.status, account_id: account_id, test_id: DataFragmentationResult.test_id, tool: DataFragmentationResult.tool, difficulty_level: DataFragmentationResult.difficulty_level, fail_summary: DataFragmentationResult.fail_summary, pass_summary: DataFragmentationResult.pass_summary, fail_extra: DataFragmentationResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)

            let ConversionRateresult = await ConversionRateTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === ConversionRateresult.test_id ? { ...obj, status: !GoalResult.status ? GoalResult.status : ConversionRateresult.status, account_id: account_id, test_id: ConversionRateresult.test_id, tool: ConversionRateresult.tool, difficulty_level: ConversionRateresult.difficulty_level, fail_summary: !GoalResult.status ? `${GoalResult.fail_summary} <br/>${ConversionRateresult.fail_summary}` : ConversionRateresult.fail_summary, pass_summary: !GoalResult.status ? '' : ConversionRateresult.pass_summary, fail_extra: !GoalResult.status ? `${GoalResult.fail_extra} <br/>${ConversionRateresult.fail_extra}` : ConversionRateresult.fail_extra, outline: obj.outline } : obj
            )
            setTestReport(ga4Reports)

            let LandingPagesResult = await LandingPagesTestGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === LandingPagesResult.test_id ? { ...obj, status: LandingPagesResult.status, account_id: account_id, test_id: LandingPagesResult.test_id, tool: LandingPagesResult.tool, difficulty_level: LandingPagesResult.difficulty_level, fail_summary: LandingPagesResult.fail_summary, pass_summary: LandingPagesResult.pass_summary, fail_extra: LandingPagesResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)

            let deploymentResult = await GTMDeploymentGA4(propertyId, accessToken)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === deploymentResult.test_id ? { ...obj, status: deploymentResult.status, account_id: account_id, test_id: deploymentResult.test_id, tool: deploymentResult.tool, difficulty_level: deploymentResult.difficulty_level, fail_summary: deploymentResult.fail_summary, pass_summary: deploymentResult.pass_summary, fail_extra: deploymentResult.fail_extra, outline: obj.outline } : obj
            )
            setTestReport(ga4Reports)
            ga4Reports = ga4Reports.map(obj =>
                obj.test_id === "GA22" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
            );
            setTestReport(ga4Reports)
            ga4Reports.map(async (re) => {
                let rep = await backendApi(`${serverUrl}api/analyticsreport`, re, userDetails?.jwt_access_token)
            })
            setTimeout(() => {
                filterData(0, 0, 0, 0)
            }, 1500);
        } else {
            let PropertyResult = await PropertyTest(accountId, propertyId, viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === PropertyResult.test_id ? { ...obj, status: PropertyResult.status, account_id: account_id, test_id: PropertyResult.test_id, tool: PropertyResult.tool, difficulty_level: PropertyResult.difficulty_level, fail_summary: PropertyResult.fail_summary, pass_summary: PropertyResult.pass_summary, fail_extra: PropertyResult.fail_extra, outline: obj.outline } : obj
            )
            setTestReport(reports)

            if (optionalTest?.siteSearch) {
                let SiteSearchResult = await SiteSearchTest(accountId, propertyId, viewId, accessToken)
                reports = reports.map(obj =>
                    obj.test_id === SiteSearchResult.test_id ? { ...obj, status: SiteSearchResult.status, account_id: account_id, test_id: SiteSearchResult.test_id, tool: SiteSearchResult.tool, difficulty_level: SiteSearchResult.difficulty_level, fail_summary: SiteSearchResult.fail_summary, pass_summary: SiteSearchResult.pass_summary, fail_extra: SiteSearchResult.fail_extra, outline: obj.outline } : obj
                )
                setTestReport(reports)
            }

            let EventResult = await EventTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === EventResult.test_id ? { ...obj, status: EventResult.status, account_id: account_id, test_id: EventResult.test_id, tool: EventResult.tool, difficulty_level: EventResult.difficulty_level, fail_summary: EventResult.fail_summary, pass_summary: EventResult.pass_summary, fail_extra: EventResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)

            let GoalResult = await ConversionsTest(accountId, propertyId, viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === GoalResult.test_id ? { ...obj, status: GoalResult.status, account_id: account_id, test_id: GoalResult.test_id, tool: GoalResult.tool, difficulty_level: GoalResult.difficulty_level, fail_summary: GoalResult.fail_summary, pass_summary: GoalResult.pass_summary, fail_extra: GoalResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let InternalTrafficResult = await InternalTrafficTest(accountId, propertyId, viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === InternalTrafficResult.test_id ? { ...obj, status: InternalTrafficResult.status, account_id: account_id, test_id: InternalTrafficResult.test_id, tool: InternalTrafficResult.tool, difficulty_level: InternalTrafficResult.difficulty_level, fail_summary: InternalTrafficResult.fail_summary, pass_summary: InternalTrafficResult.pass_summary, fail_extra: InternalTrafficResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let CustomDefinitionsResult = await CustomDefinitionsTest(accountId, propertyId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === CustomDefinitionsResult.test_id ? { ...obj, status: CustomDefinitionsResult.status, account_id: account_id, test_id: CustomDefinitionsResult.test_id, tool: CustomDefinitionsResult.tool, difficulty_level: CustomDefinitionsResult.difficulty_level, fail_summary: CustomDefinitionsResult.fail_summary, pass_summary: CustomDefinitionsResult.pass_summary, fail_extra: CustomDefinitionsResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            if (optionalTest?.toolsIntegration) {
                let ToolsIntegrationResult = await ToolsIntegrationTest(accountId, propertyId, viewId, accessToken)
                reports = reports.map(obj =>
                    obj.test_id === ToolsIntegrationResult.test_id ? { ...obj, status: ToolsIntegrationResult.status, account_id: account_id, test_id: ToolsIntegrationResult.test_id, tool: ToolsIntegrationResult.tool, difficulty_level: ToolsIntegrationResult.difficulty_level, fail_summary: ToolsIntegrationResult.fail_summary, pass_summary: ToolsIntegrationResult.pass_summary, fail_extra: ToolsIntegrationResult.fail_extra, outline: obj.outline } : obj
                )
                setTestReport(reports)
            }

            let ContentGroupResult = await ContentGroupTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === ContentGroupResult.test_id ? { ...obj, status: ContentGroupResult.status, account_id: account_id, test_id: ContentGroupResult.test_id, tool: ContentGroupResult.tool, difficulty_level: ContentGroupResult.difficulty_level, fail_summary: ContentGroupResult.fail_summary, pass_summary: ContentGroupResult.pass_summary, fail_extra: ContentGroupResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            if (optionalTest?.campaigns) {
                let CampaignResult = await CampaignTest(viewId, accessToken)
                reports = reports.map(obj =>
                    obj.test_id === CampaignResult.test_id ? { ...obj, status: CampaignResult.status, account_id: account_id, test_id: CampaignResult.test_id, tool: CampaignResult.tool, difficulty_level: CampaignResult.difficulty_level, fail_summary: CampaignResult.fail_summary, pass_summary: CampaignResult.pass_summary, fail_extra: CampaignResult.fail_extra, outline: obj.outline } : obj
                )
                setTestReport(reports)
            }
            if (optionalTest?.ecommerce) {
                let EcommerceResult = await EcommerceTest(viewId, accessToken)
                reports = reports.map(obj =>
                    obj.test_id === EcommerceResult.test_id ? { ...obj, status: EcommerceResult.status, account_id: account_id, test_id: EcommerceResult.test_id, tool: EcommerceResult.tool, difficulty_level: EcommerceResult.difficulty_level, fail_summary: EcommerceResult.fail_summary, pass_summary: EcommerceResult.pass_summary, fail_extra: EcommerceResult.fail_extra, outline: obj.outline } : obj
                )
                setTestReport(reports)
            }

            // behaviour test start from here
            let MonthOnMonthBRResult = await MonthOnMonthBRTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === MonthOnMonthBRResult.test_id ? { ...obj, status: MonthOnMonthBRResult.status, account_id: account_id, test_id: MonthOnMonthBRResult.test_id, tool: MonthOnMonthBRResult.tool, difficulty_level: MonthOnMonthBRResult.difficulty_level, fail_summary: MonthOnMonthBRResult.fail_summary, pass_summary: MonthOnMonthBRResult.pass_summary, fail_extra: MonthOnMonthBRResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let BounceSpikeResult = await BounceSpikeTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === BounceSpikeResult.test_id ? { ...obj, status: BounceSpikeResult.status, account_id: account_id, test_id: BounceSpikeResult.test_id, tool: BounceSpikeResult.tool, difficulty_level: BounceSpikeResult.difficulty_level, fail_summary: BounceSpikeResult.fail_summary, pass_summary: BounceSpikeResult.pass_summary, fail_extra: BounceSpikeResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let MonthOnMonthTrafficResult = await MonthOnMonthTrafficTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === MonthOnMonthTrafficResult.test_id ? { ...obj, status: MonthOnMonthTrafficResult.status, account_id: account_id, test_id: MonthOnMonthTrafficResult.test_id, tool: MonthOnMonthTrafficResult.tool, difficulty_level: MonthOnMonthTrafficResult.difficulty_level, fail_summary: MonthOnMonthTrafficResult.fail_summary, pass_summary: MonthOnMonthTrafficResult.pass_summary, fail_extra: MonthOnMonthTrafficResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let TrafficSpikeResult = await TrafficSpikeTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === TrafficSpikeResult.test_id ? { ...obj, status: TrafficSpikeResult.status, account_id: account_id, test_id: TrafficSpikeResult.test_id, tool: TrafficSpikeResult.tool, difficulty_level: TrafficSpikeResult.difficulty_level, fail_summary: TrafficSpikeResult.fail_summary, pass_summary: TrafficSpikeResult.pass_summary, fail_extra: TrafficSpikeResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)

            let RefrerralDropOffResult = await ReferralDropOffTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === RefrerralDropOffResult.test_id ? { ...obj, status: RefrerralDropOffResult.status, account_id: account_id, test_id: RefrerralDropOffResult.test_id, tool: RefrerralDropOffResult.tool, difficulty_level: RefrerralDropOffResult.difficulty_level, fail_summary: RefrerralDropOffResult.fail_summary, pass_summary: RefrerralDropOffResult.pass_summary, fail_extra: RefrerralDropOffResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let DataFragmentationResult = await DataFragmentationTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === DataFragmentationResult.test_id ? { ...obj, status: DataFragmentationResult.status, account_id: account_id, test_id: DataFragmentationResult.test_id, tool: DataFragmentationResult.tool, difficulty_level: DataFragmentationResult.difficulty_level, fail_summary: DataFragmentationResult.fail_summary, pass_summary: DataFragmentationResult.pass_summary, fail_extra: DataFragmentationResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)

            if (!GoalResult.status) {
                reports = reports.map(obj =>
                    obj.test_id === 'UA20' ? { ...obj, status: GoalResult.status, account_id: account_id, test_id: 'UA20', tool: GoalResult.tool, difficulty_level: 2, fail_summary: GoalResult.fail_summary, pass_summary: GoalResult.pass_summary, fail_extra: GoalResult.fail_extra, outline: obj.outline } : obj
                );
                setTestReport(reports)
            } else {
                let ConversionRateresult = await ConversionRateTest(accountId, propertyId, viewId, accessToken)
                reports = reports.map(obj =>
                    obj.test_id === ConversionRateresult.test_id ? { ...obj, status: ConversionRateresult.status, account_id: account_id, test_id: ConversionRateresult.test_id, tool: ConversionRateresult.tool, difficulty_level: ConversionRateresult.difficulty_level, fail_summary: ConversionRateresult.fail_summary, pass_summary: ConversionRateresult.pass_summary, fail_extra: ConversionRateresult.fail_extra, outline: obj.outline } : obj
                )
                setTestReport(reports)
            }


            let LandingPagesResult = await LandingPagesTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === LandingPagesResult.test_id ? { ...obj, status: LandingPagesResult.status, account_id: account_id, test_id: LandingPagesResult.test_id, tool: LandingPagesResult.tool, difficulty_level: LandingPagesResult.difficulty_level, fail_summary: LandingPagesResult.fail_summary, pass_summary: LandingPagesResult.pass_summary, fail_extra: LandingPagesResult.fail_extra, outline: obj.outline } : obj
            );
            setTestReport(reports)
            let ExitSpikesResult = await ExitSpikesTest(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === ExitSpikesResult.test_id ? { ...obj, status: ExitSpikesResult.status, account_id: account_id, test_id: ExitSpikesResult.test_id, tool: ExitSpikesResult.tool, difficulty_level: ExitSpikesResult.difficulty_level, fail_summary: ExitSpikesResult.fail_summary, pass_summary: ExitSpikesResult.pass_summary, fail_extra: ExitSpikesResult.fail_extra, outline: obj.outline } : obj
            );

            setTestReport(reports)
            let deploymentResult = await GTMDeployment(viewId, accessToken)
            reports = reports.map(obj =>
                obj.test_id === deploymentResult.test_id ? { ...obj, status: deploymentResult.status, account_id: account_id, test_id: deploymentResult.test_id, tool: deploymentResult.tool, difficulty_level: deploymentResult.difficulty_level, fail_summary: deploymentResult.fail_summary, pass_summary: deploymentResult.pass_summary, fail_extra: deploymentResult.fail_extra, outline: obj.outline } : obj
            )
            setTestReport(reports)
            reports = reports.map(obj =>
                obj.test_id === "UA22" ? { ...obj, account_id: account_id, status: 2, difficulty_level: 2, fail_summary: '', pass_summary: '', fail_extra: '', outline: obj.outline } : obj
            );
            setTestReport(reports)
            reports.map(async (report) => {
                let rep = await backendApi(`${serverUrl}api/analyticsreport`, report, userDetails?.jwt_access_token)
            })
            setTimeout(() => {
                filterData(0, 0, 0, 0)
            }, 1500);
        }
    }
    const [optionalCount, setOptionalCount] = useState(0);
    const [statusTrue, setStatusTrue] = useState(0);
    const [statusFail, setStatusFail] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        let optionalCount = optionalTest ? Object.values(optionalTest) : []
        optionalCount = optionalCount?.filter(value => value == false)
        let statusTrue = testReport?.filter(function (obj) {
            if (obj.status && obj.status != "Loading" && obj.status != 2) {
                return obj.status
            }
        });

        let statusFail = testReport?.filter(function (obj) {
            if ((obj.status === 0 || obj.status === false) && obj.status != "Loading" && obj.status != 2) {
                return 'false'
            }
        });

        setOptionalCount(optionalCount?.length)
        let testCount = (statusTrue?.length + statusFail?.length)

        setStatusTrue(statusTrue?.length)
        setStatusFail(statusFail?.length)
        if (Object.keys(percentageData).length === 0) {
            setResultCount({ ...resultCount, all: testCount, failed: statusFail?.length, passed: statusTrue?.length })
        }
    }, [testReport])
    return (
        <Box id="fullPage" className="analyticsReport-page">
            <Box>
                <PageHead percentageData={percentageData} accountDetails={accountDetails} downloadData={downloadData} statusTrue={statusTrue} statusFail={statusFail} optionalCount={optionalCount} />
            </Box>
            <Container className='space'>
                <Box display={'flex'} marginBottom={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={5}>
                            <Modal
                                open={open}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className="errorWarning-popup">
                                    <IconButton
                                        className='modal-close-btn'
                                        edge="start"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <h2 id="parent-modal-title">PERMISSION DENIED</h2>
                                    <p id="parent-modal-description">
                                        You do not have the sufficient permission for test !
                                    </p>
                                </Box>
                            </Modal>
                            <Box>
                                <Typography
                                    variant="p"
                                    component="p"
                                    fontSize={{ xs: 18, sm: 20 }}
                                    marginBottom={1}
                                >
                                    Test types
                                </Typography>
                                <Box className="report-testTypes-wrap">
                                    <Button className={`theme-btn ${testSection === 0 ? '' : 'outline'}`} onClick={() => section(0)} >All</Button>
                                    <Button className={`theme-btn ${testSection === 1 ? '' : 'outline'}`} onClick={() => section(1)} >Settings</Button>
                                    <Button className={`theme-btn ${testSection === 2 ? '' : 'outline'}`} onClick={() => section(2)}>Behavior</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                            <Box>
                                <Typography
                                    variant="p"
                                    component="p"
                                    fontSize={{ xs: 18, sm: 20 }}
                                    marginBottom={1}
                                >
                                    Difficulty
                                    <BootstrapTooltip title="Difficulty to fix" arrow placement="top">
                                        <ErrorOutlineIcon sx={{ fontSize: 18, marginLeft: 0.8, verticalAlign: 'middle' }} />
                                    </BootstrapTooltip>
                                </Typography>
                                <Box className="report-testTypes-wrap">
                                    <Button className={`theme-btn ${dfficultyLevel === 0 ? '' : 'outline'}`} onClick={() => level(0)} >All</Button>
                                    <Button className={`theme-btn ${dfficultyLevel === 1 ? '' : 'outline'}`} onClick={() => level(1)} >Simple</Button>
                                    <Button className={`theme-btn ${dfficultyLevel === 2 ? '' : 'outline'}`} onClick={() => level(2)}>Complex</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={{ xs: 1, md: 2 }} marginBottom={5} className="filterResult-btn-wrap">
                        <Grid item xs={12} md={4}>
                            <Button className={`theme-btn ${resultType === 0 ? '' : 'outline'}`} onClick={() => resultTpes(0)}>
                                <Typography variant="p" textTransform={'capitalize'}><FileCopyIcon sx={{ fontSize: 26, marginRight: 1, verticalAlign: 'middle' }} /> All results </Typography>
                                <Typography variant="p" fontSize={18} sx={{ fontWeight: 700 }} align={'center'}>
                                    {resultCount?.all !== 'NaN' ? resultCount?.all : ''}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button className={`theme-btn ${resultType === 1 ? '' : 'outline'}`} onClick={() => resultTpes(1)}>
                                <Typography variant="p" textTransform={'capitalize'}><CheckCircleIcon sx={{ fontSize: 30, marginRight: 1, verticalAlign: 'middle' }} /> Passed </Typography>
                                <Typography variant="p" fontSize={18} sx={{ fontWeight: 700 }} align={'center'}>
                                    {resultCount.passed}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button className={`theme-btn ${resultType === 2 ? '' : 'outline'}`} onClick={() => resultTpes(2)}>
                                <Typography variant="p" textTransform={'capitalize'}><CancelIcon sx={{ fontSize: 30, marginRight: 1, verticalAlign: 'middle' }} /> Failed </Typography>
                                <Typography variant="p" fontSize={18} sx={{ fontWeight: 700 }} align={'center'}>
                                    {resultCount.failed}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {/* Property Settings */}
                {testReport?.map((report, index) =>
                    <>
                        {report.status === 2 || report.status === "2" ? "" :
                            <Box marginBottom={2} className="analyticsReport-tab">
                                <Accordion
                                    allowMultipleExpanded={true} >
                                    <AccordionSummary aria-controls="panel1d-content" id={`panel${index}-header`} sx={{ paddingX: { md: 3 }, backgroundColor: 'transparent' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }} alignItems={'center'} justifyContent={'space-between'} width={1}>
                                            <Box>
                                                <Typography variant='h6' component={'h6'} fontWeight={'bold'} fontSize={{ xs: 18, sm: 20 }} lineHeight={{ xs: '24px', sm: '26px' }}>
                                                    {report.test_name}
                                                    <BootstrapTooltip title={report.outline} arrow placement="top">
                                                        <ErrorOutlineIcon sx={{ fontSize: { xs: 16, sm: 18 }, marginLeft: 0.8, verticalAlign: 'middle' }} />
                                                    </BootstrapTooltip>
                                                    <Box component={'span'} display={{ xs: 'none', sm: 'initial' }}>
                                                        <Chip label={report.test_section === 1 || report.test_section === "1" ? 'Setting' : 'Behavior'} variant="outlined" sx={{ marginLeft: { xs: 0, sm: 1 }, height: 28, lineHeight: '28px', minWidth: { md: 84 }, fontWeight: 400 }} />
                                                        <Chip label={report.difficulty_level === 1 || report.difficulty_level === "1" ? 'Simple' : 'Complex'} variant="outlined" sx={{ marginLeft: 1, height: 28, lineHeight: '28px', minWidth: { md: 84 }, fontWeight: 400 }} />
                                                    </Box>
                                                </Typography>
                                            </Box>
                                            <Box className="ar-status-group">
                                                <Box component={'span'} display={{ xs: 'initial', sm: 'none' }}>
                                                    <Chip label={report.test_section === 1 || report.test_section === "1" ? 'Setting' : 'Behavior'} variant="outlined" sx={{ marginRight: 0.5, marginY: 0.2, height: 24, lineHeight: '24px', fontWeight: 400, fontSize: 12 }} />
                                                    <Chip label={report.difficulty_level === 1 || report.difficulty_level === "1" ? 'Simple' : 'Complex'} variant="outlined" sx={{ marginRight: 0.5, marginY: 0.2, height: 24, lineHeight: '24px', fontWeight: 400, fontSize: 12 }} />
                                                </Box>
                                                {
                                                    report.status === true || report.status === 1 || report.status === "1" ?
                                                        <Typography fontWeight={800} textTransform={'uppercase'} fontSize={{ xs: 13, sm: 16 }} minWidth={{ xs: 66, sm: 90 }} display={'inline-block'} paddingLeft={{ sm: 1 }}>
                                                            <CheckCircleIcon sx={{ fontSize: { xs: 25, sm: 30 }, marginRight: { xs: 0.3, sm: 0.5 }, verticalAlign: 'middle', color: "green" }} />
                                                            Pass
                                                        </Typography>
                                                        : report.status === false || report.status === 0 || report.status === "0" ?
                                                            <Typography fontWeight={800} textTransform={'uppercase'} fontSize={{ xs: 13, sm: 16 }} minWidth={{ xs: 66, sm: 90 }} display={'inline-block'} paddingLeft={{ sm: 1 }}>
                                                                <CancelIcon sx={{ fontSize: { xs: 25, sm: 30 }, marginRight: { xs: 0.3, sm: 0.5 }, verticalAlign: 'middle', color: "red" }} />
                                                                Fail
                                                            </Typography> :
                                                            report.status === 2 || report.status === "2" ?
                                                                "" :
                                                                <ThreeDots
                                                                    height="15"
                                                                    width="60"
                                                                    radius="9"
                                                                    color="#FF5A27"
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClassName=""
                                                                    visible={true}
                                                                />
                                                }
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ paddingX: { xs: 0, md: 3 }, paddingY: 1.5, marginX: 3 }}>
                                        {report?.pass_summary != "" ?
                                            <Box>
                                                <Typography dangerouslySetInnerHTML={{ __html: report?.pass_summary }} fontSize={{ xs: 14, sm: 16 }}>
                                                </Typography>
                                            </Box> :
                                            <Box>
                                                <Typography variant='h6' component={'h6'} fontWeight={'bold'} fontSize={{ xs: 18, sm: 20 }}>
                                                    {report?.fail_summary ? `Why have I failed?` : ''}
                                                </Typography>
                                                <Typography dangerouslySetInnerHTML={{ __html: report?.fail_summary }} marginTop={{ xs: 0.5, sm: 1 }} fontSize={{ xs: 14, sm: 16 }}>
                                                </Typography>
                                                <Typography dangerouslySetInnerHTML={{ __html: report?.fail_extra }} marginTop={{ xs: 0.5, sm: 1 }} fontSize={{ xs: 14, sm: 16 }}>
                                                </Typography>
                                            </Box>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        }
                    </>
                )}
            </Container>
        </Box>
    );
}