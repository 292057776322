import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const MonthOnMonthTrafficTestGA4 = async (propertyId, accessToken) => {
    const body = JSON.stringify({
        "dateRanges": [{ "startDate": "60daysAgo", "endDate": "31daysAgo" },
        { "startDate": "30daysAgo", "endDate": "yesterday" }],
        "metrics": [{ "name": "sessions" }]
    });

    let resultData1 = await GA4Report(`${URL}/${propertyId}:runReport`, body, accessToken);
    // checking for error or no permission
    if (resultData1.code) {
        let data = { test_name: 'Month On Month Traffic', status: false, test_id: 'GA14', tool: 'GA4', difficulty_level: 2, fail_summary: "No permission.", pass_summary: '', fail_extra: '' }
        return data;
    }
    // checking for data
    if (!resultData1 || !resultData1.data || !resultData1.data.rows) {
        let data = { test_name: 'Month On Month Traffic', status: false, test_id: 'GA14', tool: 'GA4', difficulty_level: 2, fail_summary: "No data available.", pass_summary: '', fail_extra: '' }
        return data;
    }

    let obj = resultData1.data.rows
    let before30days = obj[0]?.metricValues[0].value;  // (60daysAgo to 31daysAgo)
    let last30days = obj[1]?.metricValues[0].value;    // (30daysAgo to yesterday)

    // check for month on month sessions decreased by 10%
    let check = parseFloat(before30days - last30days) > parseFloat(before30days * 0.1) ? false : true;

    if (before30days && !check) {
        let test1_summary_data = `Month-on-month traffic has decreased by ${((before30days - last30days) * 100 / before30days).toFixed(2)}% in last 30 days compared to previous period. This may indicate that marketing campaigns over the last month have been less effective, or a competitor's marketing campaigns have increased in effectiveness.`;

        let test1_extra_data = "A decrease in month-on-month traffic indicates that your marketing campaigns-be it via organic referrals, organic search engine traffic, or paid advertising-has become less effective or stopped. While overall conversions are generally regarded as the more important metric, less usage of your site could still pose long-term problems.";

        let data = { test_name: 'Month On Month Traffic', status: check, test_id: 'GA14', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: test1_extra_data }
        return data;
    } else {
        let data = { test_name: 'Month On Month Traffic', status: check, test_id: 'GA14', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: "There is no significant decrease in your month-on-month traffic in the past 60 days.", fail_extra: '' }
        return data;
    }
}
