/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {Container, List, ListItem} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import anayticsImg01 from '../../../../assets/images/anaytics1.png';
import anayticsImg02 from '../../../../assets/images/anaytics2.png';
import anayticsImg03 from '../../../../assets/images/anaytics3.png';
import analyticsVector from './../../../../assets/images/home-analytics-vector.png';

const mock = [
  {
    title: 300,
    subtitle:
      '300 + component compositions, which will help you to build any page easily.',
    suffix: '+',
  },
  {
    title: 45,
    subtitle:
      '45 + landing and supported pages to Build a professional website.',
    suffix: '+',
  },
  {
    title: 99,
    subtitle: '99% of our customers rated 5-star our themes over 5 years.',
    suffix: '%',
  },
];

const Features = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
    <Box className="bg-primary-gray">
      <Container className='space'>
        <Box marginBottom={4}>
          <Box>
            <Typography
              variant="h3"
              color="white"
              marginBottom={4}
              align={'center'}
              sx={{
                fontWeight: 700,
              }}
            >
              <Box component={'span'} sx={{position: 'relative'}}>
                Why use Check My Analytics
                <Box
                    sx={{position: 'absolute', bottom: -15, right: -2, width: 218}}
                    display={{xs: 'none', sm: 'block'}}
                    component={'img'}
                    src={analyticsVector}
                    maxWidth={1}
                  />
              </Box>
            </Typography>
            <Typography
              component={'p'}
              fontSize={{xs:16, sm:18, md:20}}
              color="white"
              sx={{ 
                fontWeight: 300,
                marginX: 'auto',
                maxWidth: 550
              }}
              align={'center'}
            >
              Run a Google analytics 4 audit of your site to spot and fix  issues in tracking or odd traffic patterns.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={{xs:2, sm:4}} marginBottom={{xs:2, sm:6}}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={6}
            >
              <Box height={1} width={1}>
              <Box
                maxWidth={1}
                component={'img'}
                src={anayticsImg01}
              />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display={'flex'} alignItems={'center'} width={1} height={1}>
                <Box marginBottom={4}
                  paddingLeft={{ md: 0, lg: 12 }}
                  paddingRight={{ md: 0, lg: 4 }}
                >
                  <Typography className='text-primary' sx={{ fontWeight: 600, lineHeight: 1.25 }} fontSize={{xs:24, sm:28, md:34}} marginBottom={0.6}>
                    Audit your Google Analytics Data in Seconds
                  </Typography>
                  <Box>
                    <List className='star-list'>
                        <ListItem>
                            <Typography color="white" component="p" fontWeight={300}>
                              Don’t spend half a day going through all the setting to find why your Google Analytics data is incorrect
                            </Typography>  
                        </ListItem>
                        <ListItem>
                            <Typography color="white" component="p" fontWeight={300}>
                              Use CheckMyAnalytics to improve the quality of your reporting and drive performance
                            </Typography>  
                        </ListItem>
                        <ListItem>
                            <Typography color="white" component="p" fontWeight={300}>
                              Spot potential privacy issues and ensure your data remains compliant with the main legal framework
                            </Typography>  
                        </ListItem>
                    </List>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={{xs:2, sm:4}} marginBottom={{xs:2, sm:6}} direction={isMd ? 'row' : 'column-reverse'}>
            <Grid item xs={12} md={6}>
              <Box display={'flex'} alignItems={'center'} width={1} height={1}>
                <Box 
                  marginBottom={4}
                  paddingLeft={{ md: 0, lg: 12 }}
                  paddingRight={{ md: 0, lg: 4 }}
                >
                  <Typography className='text-primary' sx={{ fontWeight: 600, lineHeight: 1.25 }} fontSize={{xs:24, sm:28, md:34}} gutterBottom>
                  Trust your data.<br/>Trust your decisions.
                  </Typography>
                  <Typography color="white" fontFamily={300}>
                    Ensure accurate data collection and insights with a Google Analytics audit against both settings and traffic patterns.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={6}
            >
              <Box height={1} width={1}>
                <Box
                  maxWidth={1}
                  component={'img'}
                  src={anayticsImg02}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={{xs:2, sm:4}}>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              md={6}
            >
              <Box height={1} width={1}>
                <Box
                  maxWidth={1}
                  component={'img'}
                  src={anayticsImg03}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display={'flex'} alignItems={'center'} width={1} height={1}>
                <Box 
                  marginBottom={4}
                  paddingLeft={{ md: 0, lg: 12 }}
                  paddingRight={{ md: 0, lg: 4 }}
                >
                  <Typography className='text-primary' sx={{ fontWeight: 600, lineHeight: 1.25 }} fontSize={{xs:24, sm:28, md:34}} gutterBottom>
                  Less Noise.<br/> More Signals.
                  </Typography>
                  <Typography color="white" fontWeight={300}>
                    Bot traffic, duplicates, missing campaign tracking are making GA data difficult to interpret. Check My Analytics checks traffic patterns to identify potential issues beyond on site tracking.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;
