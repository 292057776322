import React from 'react';
import { GoogleLogin } from 'react-google-login';
// "react-google-login": "^5.1.20"
import { backendApi } from '../components/Api';
import { useNavigate } from 'react-router-dom';
import { Button,Box } from '@mui/material';
import Arrow from '../assets/images/white-arrow.png';
const serverUrl = process.env.REACT_APP_SERVER_URL

const Login = ({endIcon}) => {
  const navigate = useNavigate();
  let userDetails;
  const clientId = process.env.REACT_APP_CLIENT_ID
  
  const onSuccess = async (res) => {
    localStorage.setItem('access_token', res.tokenObj.access_token)
    if (res.tokenObj.access_token) {
      const body =
      {
        email: res.profileObj.email,
        firstname: res.profileObj.givenName,
        lastname: res.profileObj.familyName,
        expire_time: Math.round(new Date().getTime() / 1000) + 3480,
        access_token : res.tokenObj.access_token
      }
      userDetails = await backendApi(`${serverUrl}api/auth/signin`, body)
      localStorage.setItem('userDetails', JSON.stringify(userDetails.data))
      if(userDetails?.message === 'Network Error'){
        let error  = userDetails?.message 
        navigate('/', { state: { error } })
      }else {
        userDetails = userDetails.data
        let isAccount = true
        let accessToken = res.tokenObj.access_token
        localStorage.setItem('expire_at', userDetails?.expire_at)
        let expire_at = userDetails?.expire_at
        navigate('/', { state: { userDetails, isAccount, accessToken,expire_at } })
      }
    }
  };
  const onFailure = (error) => {
    navigate('/', { state: { error } })
    console.log('failed:', error);
  };

  return (
    <>
      {!userDetails ?
        <GoogleLogin
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          // accessType="offline"
          offline={true}
          redirectUri='https://www.checkmyanalytics.com'
          responseType="id_token permission code"
          approvalPrompt="force"
          prompt="consent select_account"
          scopes = 'https://www.googleapis.com/auth/analytics.readonly'
          // isSignedIn={true}
        /> : ''}
    </>

  );
};

export default Login;
