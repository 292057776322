import { UAReportBehaviour } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API

export const MonthOnMonthTrafficTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "There is no significant decrease in your month-on-month traffic in the past 60 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Month-on-month traffic has decreased by [INSTERT % CHANGE] for [INSERT MONTH]. This may indicate that marketing campaigns over the last month have been less effective, or a competitor's marketing campaigns have increased in effectiveness.",
        test1_extra: "A decrease in month-on-month traffic indicates that your marketing campaigns—be it via organic referrals, organic search engine traffic, or paid advertising—has become less effective or stopped. While overall conversions are generally regarded as the more important metric, less usage of your site could still pose long-term problems."
    }

    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:sessions" }],
                    "dimensions": [{ "name": "ga:date" }],
                    "dateRanges": [{
                        "startDate": "60daysAgo",
                        "endDate": "31daysAgo"
                    },
                    {
                        "startDate": "30daysAgo",
                        "endDate": "yesterday"
                    }]
                }
            ]
    });
    let resultData = await UAReportBehaviour(url, params, accessToken)
    if (resultData) {
        if (resultData.code) {
            let data = { test_name: 'Month On Month Traffic', status: false, test_id: 'UA14', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData || Object.keys(resultData).length === 0 || !resultData[0] || !resultData[1]) {
        let data = { test_name: 'Month On Month Traffic', status: false, test_id: 'UA14', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    const firstMonthRate = resultData[0].values[0];
    const secondMonthRate = resultData[1].values[0];
    if (firstMonthRate > 0 && secondMonthRate > 0) {
        let check = parseFloat((firstMonthRate - secondMonthRate) * 1.0) > parseFloat(firstMonthRate * 0.1) ? false : true;
        if (!check) {
            let test1_summary_data = `Month-on-month traffic has decreased by ${firstMonthRate > 0 ? parseFloat((firstMonthRate - secondMonthRate) * 100 / firstMonthRate).toFixed(2) : secondMonthRate}% in last 30 days compared to previous period. This may indicate that marketing campaigns over the last month have been less effective, or a competitor's marketing campaigns have increased in effectiveness.`
            let data = { test_name: 'Month On Month Traffic', status: check, test_id: 'UA14', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
            return data;
        }
    }
    else {
        let data = { test_name: 'Month On Month Traffic', status: false, test_id: 'UA14', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let data = { test_name: 'Month On Month Traffic', status: true, test_id: 'UA14', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
