import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

function Loading(props) {
  return (
      <ThreeDots
          height="60"
          width="80"
          radius="9"
          color="#FF5A27"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={props.loading}
      /> 
  )
}

export default Loading