import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Logo from './../../../../assets/images/logo.png'
import Container from '@mui/material/Container';
import Login from '../../../../components/Login'

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  let userDetails = localStorage.getItem('userDetails') ? localStorage.getItem('userDetails') :''

  return (
    <Box className="bg-primary-gray main-footer">
      <Container sx={{paddingY: 2}}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box marginBottom={{ xs: 1, md: 0 }}>
              <Link component="a"
                to="/"
                className="main-logo"
              >
                <Box
                  component={'img'}
                  src={Logo}
                  width={{ xs: 250, md: 290 }}
                />
              </Link>
            </Box>
            <Box textAlign={{ xs: 'center', md: 'right' }} >
              <Box className="footer-menu">
                {!userDetails ?
                  <Box className="ftr-googleButton"><Login /></Box> : ''
                }
                <Link
                  component="a"
                  to="/terms-conditions"
                  color="white"
                >
                  Terms and conditions
                </Link>
                <Link
                  component="a"
                  to="/privacy-policy"
                >
                  Privacy
                </Link>
              </Box>
              <Box marginTop={1}>
                <Typography
                  variant={'caption'}
                  color="white"
                  component={'p'}
                >
                  © Copyright. All rights reserved.
                </Typography>
              </Box>
            </Box>
          </Box>
      </Container>
    </Box>
  );
};

export default Footer;
