import React from 'react';
import {
  useTheme, Avatar, ListItemText, ListItemAvatar, ListItem, Grid,
  Typography, Box, Container
} from '@mui/material';
import Form from './Form';
import sydney from '../../../assets/images/sydney-color.jpg'
import london from '../../../assets/images/london-bw.jpg'
import seattle from '../../../assets/images/seattle-bw.jpg'
import melbourne from '../../../assets/images/melbourne-bw.jpg'
import singapore from '../../../assets/images/singapore-bw.jpg'

const mock = [
  {
    label: 'SYDNEY',
    value: '6 Middlemiss St, Lavender Bay NSW 2060',
    icon: (
      <img
        src={sydney}
      >
      </img>
    ),
  },
  {
    label: 'LONDON',
    value: 'Jubilee House, 197-213 Oxford Street London, W1D 2LF',
    icon: (
      <img
        src={london}
      >
      </img>
    ),
  },
  {
    label: 'SEATTLE',
    value: '450 Alaskan Way South, 95 South Jackson Street, Suite 100, 200 & 300',
    icon: (
      <img
        src={seattle}
      >
      </img>
    ),
  },
  {
    label: 'MELBOURNE',
    value: 'Level 4, 80 Market Street, South Melbourne, VIC, 3205',
    icon: (
      <img
        src={melbourne}
      >
      </img>
    ),
  },
  {
    label: 'SINGAPORE',
    value: 'Suntec Tower 5, 5 Temasek Blvd, Singapore, 038985',
    icon: (<img
      src={singapore}
    >
    </img>),
  },
];

const Contact = () => {
  const theme = useTheme();

  const LeftSide = () => {
    return (
      <Box>
        <Box marginBottom={2}>
          <Typography marginBottom={3}>
            Contact us today and receive a response from our <br></br>Commercial Director in 24hrs.
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <Typography marginBottom={3} variant={'h5'} sx={{ fontWeight: 500 }} color="text.secondary" gutterBottom>
            Get in touch
          </Typography>
          <Typography marginBottom={1} variant={'h5'} sx={{ fontWeight: 500 }} gutterBottom>
            SYDNEY
          </Typography>
          <Typography marginBottom={1}>
            6 Middlemiss St, Lavender Bay NSW 2060
          </Typography>
          <Typography sx={{ fontWeight: 800 }}>
            <a href="tel:+611300957920 ">+61 1300 957 920</a>
          </Typography>
        </Box>
        <Box marginBottom={{ xs: 3, md: 8 }}>
          <Typography marginBottom={3} gutterBottom>
            Or send us an email at <a href="mailto:info@inmarketingwetrust.com.au">info@inmarketingwetrust.com.au</a>
          </Typography>
          <Typography sx={{ fontWeight: 800 }} marginBottom={1}>
            Looking for our ABN?
          </Typography>
          <Typography>
            79 163 066 000
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <Container className='space'>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            position={'relative'}
          >
            <Box
              display={'flex'}
              width={1}
              order={{ xs: 2, md: 1 }}
            >
              <LeftSide />

            </Box>
            <Box
              sx={{
                flex: { xs: '0 0 100%', md: '0 0 50%' },
                position: 'relative',
                maxWidth: { xs: '100%', md: '50%' },
                order: { xs: 1, md: 2 },
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Box className="text"
                    sx={{
                      overflow: 'hidden',
                      left: '0%',
                      width: 1,
                      height: 1,
                    }}
                  >
                    {/* <RightSide /> */}
                    <Form />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box className="bg-gray-400">
        <Container>
          <Box className="contact-location" maxWidth={'1000px'} marginX={'auto'} paddingBottom={{ xs: 6, sm: 7 }} paddingTop={{ xs: 6, sm: 8 }}>
            <Grid container spacing={{ xs: 3, sm: 4 }}>
              {mock.map((item, i) => (
                <Grid item xs={12} sm={6} key={`con-${i}`}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={{ xs: 2, sm: 3 }}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary}
                        width={{ xs: 74, sm: 80, md: 110 }}
                        height={{ xs: 74, sm: 80, md: 110 }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                    <ListItemText className='adress-details' primary={item.label} secondary={item.value} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>

  );
};

export default Contact;
