import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA
export const BounceSpikeTestGA4 = async (propertyId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }
    let reasons = {
        pass_summary: "There are no significant changes in bounce rates in the past 60 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `A spike in bounce rates has been detected over the past [INSERT DATES] at [INSERT BOUNCE %]. Ensure that your website is able to load and is not returning errors. Learn more about bounce rates <a href='https://support.google.com/analytics/answer/12195621' target="_blank" >here</a>.`,
        test1_extra: "A bounce spike indicates that the performance of one or more of your landing pages has changed dramatically. A positive spike might indicate that a landing page has become less optimized, that a new traffic source is sending low-quality leads, or that a landing page is having technical difficulties. A negative bounce spike might indicate that recent changes to a landing page has increased its stickiness or that a source of low-quality leads is no longer sending as much traffic to your site. ",
    }
    const params = {
        "dimensions": [{ "name": "date" }],
        "metrics": [{ "name": "bounceRate" }],
        "dateRanges": [{ "startDate": `60daysAgo`, "endDate": `yesterday` }],
        "keepEmptyRows": true,
        "orderBys": [
            {
                "dimension": {
                    "dimensionName": "date",
                },
                "desc": true
            }
        ]
    };

    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData?.code) {
        let data = { test_name: 'Bounce spike', status: false, test_id: 'GA13', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!resultData || !resultData?.data || !resultData?.data?.rows || !resultData?.data?.rows) {
        let data = { test_name: 'Bounce spike', status: false, test_id: 'GA13', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData.data.rows
    if (obj?.length < 1) {
        let data = { test_name: 'Bounce spike', status: false, test_id: 'GA13', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    // 1 day and 20%
    for (let i = 0; i < 53; i++) {
        if (obj[i]?.metricValues && obj[i + 1]?.metricValues && obj[i + 7]?.metricValues) {
            let before1day = parseFloat(obj[i + 1].metricValues[0].value)
            let last1day = parseFloat(obj[i].metricValues[0].value)
            let before7day = parseFloat(obj[i + 7].metricValues[0].value)

            let check = ((last1day - before1day) > before1day * 20 / 100) ? false : true;
            let check1 = ((last1day - before7day) > before7day * 20 / 100) ? false : true;
            if (!check && !check1) {
                let test1_summary_data;
                test1_summary_data = `A spike in bounce rates has been detected over the past 1 day(${createDate(obj[i].dimensionValues[0].value)}) at ${(last1day * 100).toFixed(2)}%. Ensure that your website is able to load and is not returning errors. Learn more about bounce rates <a href='https://support.google.com/analytics/answer/12195621' target="_blank" >here</a>.`
                let data1 = {
                    test_name: 'Bounce Spike',
                    status: check1, test_id: 'GA13', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
                }
                return data1;
            }
        }
    }

    // 3 day and 30%
    for (let i = 0; i < 55; i++) {
        if (obj[i]?.metricValues && obj[i + 1]?.metricValues && obj[i + 3]?.metricValues && obj[i + 2]?.metricValues && obj[i + 4]?.metricValues && obj[i + 5]?.metricValues) {

            let last3days = parseFloat(obj[i].metricValues[0].value) + parseFloat(obj[i + 1].metricValues[0].value) + parseFloat(obj[i + 2].metricValues[0].value)
            let before3days = parseFloat(obj[i + 3].metricValues[0].value) + parseFloat(obj[i + 4].metricValues[0].value) + parseFloat(obj[i + 5].metricValues[0].value)

            let check2 = (parseFloat(last3days - before3days)) > (parseFloat(before3days) * 30 / 100) ? false : true;

            if (!check2) {
                let test1_summary_data;
                test1_summary_data = `A spike in bounce rates has been detected over the past 3 days(${createDate(obj[i + 2].dimensionValues[0].value)}, ${createDate(obj[i + 1].dimensionValues[0].value)}, ${createDate(obj[i].dimensionValues[0].value)}) at ${(last3days * 100 / 3).toFixed(2)}%. Ensure that your website is able to load and is not returning errors. Learn more about bounce rates <a href='https://support.google.com/analytics/answer/12195621' target="_blank" >here</a>.`

                let data1 = { test_name: 'Bounce Spike', status: check2, test_id: 'GA13', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
                return data1;
            }
        }
    }
    let data = { test_name: 'Bounce Spike', status: true, test_id: 'GA13', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
