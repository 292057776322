import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const EventTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Your Google Analytics 4 property is collecting data.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Your Google Analytics 4 property is not collecting data. (See <a href='https://support.google.com/analytics/answer/9304153' target="blank" >Google Analytics installation</a>  guide.)`,
        test2_summary: `Your Google Analytics 4 property is not properly collecting automatic measurements. (See <a href='https://support.google.com/analytics/answer/9304153' target="blank" >Google Analytics installation</a> guide.)`,
        test3_summary: "Your Google Analytics 4 property is not collecting any advanced measurements. We highly recommend implementing advanced measurements (custom and/or recommended events).",
        test1_extra: "Make sure that the Google tag is properly hard coded or deployed via Google Tag Manager to your web pages to begin seeing data in your new Google Analytics 4 property.",
        test2_extra: "Make sure that the Google tag is properly hard coded or deployed via Google Tag Manager to your web pages to begin seeing data in your new Google Analytics 4 property.",
        test3_extra: `<a href='https://support.google.com/analytics/answer/12229021' target="blank" >Custom events</a> and/or <a href='https://support.google.com/analytics/answer/9267735' target="blank" >recommended events</a> help you measure and report on additional features and behavior that Google Analytics does not collect automatically.`,
    }

    const params = JSON.stringify({
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday",
            }
        ],
        "dimensions": [
            {
                "name": "eventName"
            }
        ],
        "metrics": [
            {
                "name": "eventCount"
            }
        ]
    });
    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData.code) {
        let data = { test_name: 'Events', status: false, test_id: 'GA03', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    //test 1
    if (!resultData || !resultData.data || !resultData.data.rows) {
        let data = { test_name: 'Events', status: false, test_id: 'GA03', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }

    let obj = resultData.data.rows;
    // group 1
    let first_visit = 0, page_view = 0, session_start = 0, user_engagement = 0;
    // group 2
    let click = 0, file_download = 0, form_start = 0, form_submit = 0, scroll = 0, video_complete, video_progress = 0, video_start = 0, view_search_results = 0;
    // group 3
    let group3type = 0;
    obj.forEach(element => {
        let eventname = element.dimensionValues[0].value;
        if (eventname === "first_visit") { first_visit = 1; }
        else if (eventname === "page_view") { page_view = 1; }
        else if (eventname === "session_start") { session_start = 1; }
        else if (eventname === "user_engagement") { user_engagement = 1; }
        else if (eventname === "click") { click = 1; }
        else if (eventname === "file_download") { file_download = 1; }
        else if (eventname === "form_start") { form_start = 1; }
        else if (eventname === "form_submit") { form_submit = 1; }
        else if (eventname === "scroll") { scroll = 1; }
        else if (eventname === "video_complete") { video_complete = 1; }
        else if (eventname === "video_progress") { video_progress = 1; }
        else if (eventname === "video_start") { video_start = 1; }
        else if (eventname === "view_search_results") { view_search_results = 1; }
        else {
            group3type = 1;
        }
    });
    //test 2: check for group 1 
    if (!first_visit || !page_view || !session_start || !user_engagement) {
        let data = { test_name: 'Events', status: false, test_id: 'GA03', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test2_summary, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }
    // test 3: check for group 3
    if (group3type) {
        let data = { test_name: 'Events', status: true, test_id: 'GA03', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
        return data;
    } else {
        let data = { test_name: 'Events', status: false, test_id: 'GA03', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test3_summary, pass_summary: '', fail_extra: reasons.test3_extra }
        return data;
    }
}
