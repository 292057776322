import {useEffect, React} from 'react';
import Main from '../../layouts/Main';
import { useTheme, Box, Container } from '@mui/material';
import Content from './components/Content';
import PageHead from './components/PageHead';

const TermsConditions = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Main>
      <Box>
        <PageHead />
      </Box>
      <Container className="space">
        <Box>
          <Content />
        </Box>
      </Container>
    </Main>
  );
};

export default TermsConditions;
