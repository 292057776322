import { getUAReport, UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_ACCOUNT_API
const batchUrl = process.env.REACT_APP_BATCH_API

export const PropertyTest = async (accountId, webPropertyId, profileId, accessToken) => {

    let reasons = {
        pass_summary: "Your Universal Analytics property and its main view have been properly set and the property collects data.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Your Property Settings > Basic Settings is incomplete.",
        test2_summary: "Your View Settings > Basic Settings is incomplete.",
        test3_summary: `Your view is not collecting data at the moment.See how to set up Universal Analytics for a website <a href='https://support.google.com/analytics/answer/10269537' target="_blank" >here</a>.`,
        test1_extra: "Setting the best applicable industry category helps your property’s performance in comparison to that of your industry peers. Whereas indicating the default URL and choosing a default view supports data reporting.",
        test2_extra: "Choosing the country, time zone and currency directly supports data collection and reporting. Defining the view name and default URL helps organize your analytics property structure.",
        test3_extra: `Make sure that the UA tracking ID is applied to your Universal Analytics tag in Google Tag Manager or added <a href='https://support.google.com/analytics/answer/10269537' target="_blank" >directly to your website</a> to send data to the GA property.`
    }
    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": profileId,
                    "metrics": [{ "expression": "ga:hits" }],
                    "dateRanges": [
                        {
                            "startDate": "30daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });
    let propertyDetaiils = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}`, accessToken)
    let profileDetails = await getUAReport(`${url}/${accountId}/webproperties/${webPropertyId}/profiles/${profileId}`, accessToken)
    let resultData;


    if (!profileDetails || !propertyDetaiils) {
        let data = { test_name: 'Property', status: resultData, test_id: 'UA01', tool: 'UA', difficulty_level: 1, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    // checking for property setting test 1
    if (propertyDetaiils.name && propertyDetaiils.industryVertical !== "UNSPECIFIED" && propertyDetaiils.websiteUrl && propertyDetaiils.defaultProfileId) {
        resultData = true;
    } else {
        resultData = false
        let data = { test_name: 'Property', status: resultData, test_id: 'UA01', tool: 'UA', difficulty_level: 1, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;

    }

    // checking for view setting test 2 
    if (profileDetails.name && profileDetails.websiteUrl && profileDetails.currency && profileDetails.timezone) {
        let activeData = await UAReportBehaviourLandings(batchUrl, params, accessToken)
        if (activeData?.rowCount > 0 && activeData?.rowCount !== 'undefined' && activeData?.rowCount !== '') {
            // checking for test 3
            resultData = true
            let data = { test_name: 'Property', status: resultData, test_id: 'UA01', tool: 'UA', difficulty_level: 1, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
            return data;
        } else {
            resultData = false
            let data = { test_name: 'Property', status: resultData, test_id: 'UA01', tool: 'UA', difficulty_level: 1, fail_summary: reasons.test3_summary, pass_summary: '', fail_extra: reasons.test3_extra }
            return data;
        }
    }
    else {
        resultData = false
        let data = { test_name: 'Property', status: resultData, test_id: 'UA01', tool: 'UA', difficulty_level: 1, fail_summary: reasons.test2_summary, pass_summary: '', fail_extra: reasons.test2_extra }
        return data;
    }
}
