import React, {useEffect} from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from './../../../../../../assets/images/logo.png';
import Login from '../../../../../../components/Login'
import { GoogleLogout } from 'react-google-login';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { gapi } from 'gapi-script';
import { removeToken, backendApi } from '../../../../../../components/Api';

const SidebarNav = ({ accessToken,userDetailsdata }) => {
  const clientId = process.env.REACT_APP_CLIENT_ID
  const apiKey = process.env.REACT_APP_API_KEY
  const serverUrl = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        apiKey: apiKey,
        scope: 'https://www.googleapis.com/auth/analytics.readonly',
        plugin_name: 'streamy'
      });
    };
    gapi.load('client:auth2', initClient);
  });
  let userDetails =  userDetailsdata ? userDetailsdata :''
  const theme = useTheme();
  const navigate = useNavigate();
  const { mode } = theme.palette;

  const logout = async() => {
    const body =
      {
        email: userDetails?.email,
      }
     let logoutData = await backendApi(`${serverUrl}api/auth/logout`, body)
     removeAccess()
    if (gapi?.auth2?.getAuthInstance()) {
      let auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        localStorage.clear()
        navigate('/', { state: {} })
      });
    } else {
      console.log('User logout');
      localStorage.clear()
      navigate('/', { state: {} })
    }
  };

  const removeAccess = async () => {
    let url = await removeToken(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`)
    }


  return (
    <Box>
      <Box paddingX={1.5} lineHeight={'0'} className="sidebar-logo">
        <Link
          display={'flex'}
          component="a"
          to="/"
          title="IMWT"
        >
          <Box
            component={'img'}
            src={Logo}
            width={190}
          />
        </Link>
      </Box>
      <Box paddingX={1.5} paddingY={2} marginTop={1}>
        <Box>
        {window.location.pathname === '/analytics-report' ?
        <Box marginBottom={1}>
            <Link 
                className='side-menu-item'
                underline="none"
                component="a"
                to="/"
              >
                Take Another Test
              </Link>
          </Box> : '' }
          <Box>
            <Link 
                className='side-menu-item'
                underline="none"
                component="a"
                to="/contact"
              >
                Contact us
              </Link>
          </Box>
        </Box>
        {userDetails == '' || userDetails === 'null' || userDetails === 'undefined' || !userDetails ?
          <Box className="header-btn" marginTop={2}>
              <Login endIcon={false}/>
          </Box> : 
            <Box marginTop={2}>
             <GoogleLogout
                className=''
                disabled={false}
                isSignedIn={false}
                onLogoutSuccess={logout}
                render={renderProps => (
                  <Button id="logoutButton" className='theme-btn lite-btn' onClick={renderProps.onClick} size="small">Log out</Button>)}
              />
            </Box> }
      </Box>
    </Box>
  );
};


export default SidebarNav;
