// Import font family
import "@fontsource/archivo";
import "@fontsource/archivo/200.css";
import "@fontsource/archivo/300.css";
import "@fontsource/archivo/500.css";
import "@fontsource/archivo/600.css";
// import "@fontsource/archivo/800.css";

// Import custom style
import './assets/css/base.css';
import './assets/css/custom-style.css';
import './assets/css/responsive.css';
import './App.css';

import { Routing } from './Routing';

function App() {
  return (
    <div>
      <Routing />
    </div>
  );
}

export default App;
