import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {useTheme, useMediaQuery, Box, AppBar, Container, useScrollTrigger} from '@mui/material';
import { useLocation } from "react-router-dom";

import { Header, Sidebar, Footer } from './components';

const Main = ({ children, colorInvert = false, bgcolor = 'transparent' }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  let accessToken = location?.state?.accessToken ? location?.state?.accessToken : localStorage.getItem('access_token')

  let userDetails = location?.state?.userDetails ? location?.state?.userDetails : localStorage.getItem('userDetails')
  return (
    <Box className="main-wrapper"
      paddingTop={{xs: '60px', md: '80px'}}
    >
      <AppBar className='main-header'>
        <Container>
          <Header onSidebarOpen={handleSidebarOpen}  userDetailsdata={userDetails}
          />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        accessToken={accessToken}
        userDetails={userDetails}
      />
      <main className='main-content'>
        {children}
      </main>
      <Box className="main-footer" backgroundColor={theme.palette.common.black}>
        <Footer></Footer>
      </Box>
    </Box>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Main;
