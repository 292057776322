import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const CampaignTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Campaign tracking is properly set up. UTM parameters are properly used to track visitors coming from marketing campaigns.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `UTM parameters are not used. Learn more about custom URLs <a href='https://support.google.com/analytics/answer/10917952' target="_blank" >here</a>.`,
        test2_summary: "Duplicates have been detected in source/medium and/or campaign.",
        test3_summary: "Click parameters, e.g. fbclid, gclid, have been detected in your Google Analytics data.",
        test1_extra: "Custom campaigns (UTM parameters) allow you to track and measure the effectiveness of your marketing campaigns and traffic sources. This information can be extremely valuable in identifying where future marketing spending should go.",
        test2_extra: "Custom campaigns (UTM parameters) allow you to track and measure the effectiveness of your marketing campaigns and traffic sources. This information can be extremely valuable in identifying where future marketing spending should go.",
        test3_extra: "Custom campaigns (UTM parameters) allow you to track and measure the effectiveness of your marketing campaigns and traffic sources. This information can be extremely valuable in identifying where future marketing spending should go.",
    }
    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }
    const params = JSON.stringify({
        "dimensions": [
            {
                "name": "sessionCampaignName"
            }
        ],
        "metrics": [
            {
                "name": "eventCount"
            }
        ],
        "dateRanges": [
            {
                "startDate": "60daysAgo",
                "endDate": "yesterday"
            }
        ]
    });

    const sourceMediumParams = JSON.stringify({
        "dimensions": [
            {
                "name": "sessionSourceMedium"
            }
        ],
        "metrics": [
            {
                "name": "eventCount"
            }
        ],
        "dateRanges": [
            {
                "startDate": "60daysAgo",
                "endDate": "yesterday"
            }
        ]
    });
    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    let sourceMediumData = await GA4Report(`${URL}/${propertyId}:runReport`, sourceMediumParams, accessToken)

    if (resultData.code || sourceMediumData.code) {
        let data = { test_name: 'CampaignTest', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if ((!resultData || !resultData.data || !resultData.data.rows) || (!sourceMediumData || !sourceMediumData.data || !sourceMediumData.data.rows)) {
        let data = { test_name: 'CampaignTest', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }

    let campaignObj = resultData.data.rows
    let sourceObj = sourceMediumData.data.rows

    // Test 1: utm parameter are set
    let test1 = false, test3 = true;
    let sourceMedium = [], campaign = [];
    const campaignIds = ["?fbclid", "?gclid", "?dc_cid", "?cid", "tid", "?msclkid", "?utm_medium", "?utm_source", "?utm_term", "?utm_campaign", "?utm_content", "?utm_id"];
    try {
        campaignObj.forEach(element => {
            if (element.dimensionValues[0].value !== '(not set)') {
                campaign.push(element.dimensionValues[0].value.toLowerCase().replaceAll(" ", ""))
            }
        })

        sourceObj.forEach(element => {
            sourceMedium.push(element.dimensionValues[0].value.toLowerCase().replaceAll(" ", ""))

        })
    } catch (error) {
        let data1 = { test_name: 'Campaigns', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data1;
    }

    if (campaign.length > 0 && sourceMedium.length > 0) {
        test1 = 1
    }
    if (!test1) {
        let data1 = { test_name: 'Campaigns', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data1;
    }
    // Test 2:
    if (hasDuplicates(sourceMedium) || hasDuplicates(campaign)) {
        let data1 = { test_name: 'Campaigns', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test2_summary, pass_summary: '', fail_extra: reasons.test2_extra }
        return data1;
    }


    const paramsPagePath = JSON.stringify({
        "dimensions": [
            {
                "name": "pagePath"
            },
            {
                "name": "unifiedScreenClass"
            }
        ],
        "metrics": [
            {
                "name": "eventCount"
            }
        ],
        "dateRanges": [
            {
                "startDate": "60daysAgo",
                "endDate": "yesterday"
            }
        ]
    });

    let pagePathResult = await GA4Report(`${URL}/${propertyId}:runReport`, paramsPagePath, accessToken)

    if (pagePathResult.code) {
        let data = { test_name: 'CampaignTest', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!pagePathResult || !pagePathResult.data || !pagePathResult.data.rows) {
        let data = { test_name: 'CampaignTest', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let objData = pagePathResult.data.rows;
    objData.forEach(element => {
        if (element.dimensionValues[0] != '') {
            let dimensions = element.dimensionValues[0].value
            let isTrue = campaignIds.some(v => dimensions?.includes(v))
            if (isTrue) {
                test3 = false;
            }
        }
    });

    // Test 3:
    if (!test3) {
        let data1 = { test_name: 'Campaigns', status: false, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test3_summary, pass_summary: '', fail_extra: reasons.test3_extra }
        return data1;
    }

    let data = { test_name: 'Campaigns', status: true, test_id: 'GA10', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
