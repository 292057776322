import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const EcommerceTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Ecommerce tracking is set up, and ecommerce interactions are properly collected.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Ecommerce tracking is not set up on your site. We strongly recommend that you properly <a href='https://developers.google.com/analytics/devguides/collection/ga4/ecommerce' target="_blank" >track ecommerce events in Google Analytics 4</a>.`,
        test2_summary: "Recommended ecommerce event [INSERT EVENT NAME/S] is/are not currently collected.",
        test3_summary: `The current ecommerce implementation is incorrect. (See Google developer guide <a href='https://developers.google.com/analytics/devguides/collection/ga4/ecommerce' target="_blank" >here</a>.) Errors detected: [SHOW ERRORS]`,
        test1_extra: "Tracking ecommerce transactions and other product-related interactions in Google Analytics 4 allows you to utilise the vast power of the Analytics engine to analyse where and how you're getting your conversions, tracing from acquisition to checkout.",
        test2_extra: "Tracking ecommerce transactions and other product-related interactions in Google Analytics 4 allows you to utilise the vast power of the Analytics engine to analyse where and how you're getting your conversions, tracing from acquisition to checkout.",
        test3_extra: "Tracking ecommerce transactions and other product-related interactions in Google Analytics 4 allows you to utilise the vast power of the Analytics engine to analyse where and how you're getting your conversions, tracing from acquisition to checkout."
    }
    const params = JSON.stringify(
        {
            "dateRanges": [
                {
                    "startDate": "30daysAgo",
                    "endDate": "yesterday",
                }
            ],
            "dimensions": [
                {
                    "name": "eventName"
                }
            ],
            "metrics": [
                {
                    "name": "eventCount"
                }
            ]
        });
    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData.code) {
        let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    // checking for empty data or any error
    if (!resultData || !resultData?.data || !resultData?.data?.rows) {
        let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData?.data?.rows
    if (obj?.length < 1) {
        let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let view_item_list = 0, view_item = 0, select_item = 0, add_to_cart = 0, remove_from_cart = 0, view_cart = 0, add_shipping_info = 0, add_payment_info = 0, purchase = 0;

    // loop to check all event values
    let insertList = ["view_item_list", "view_item", "select_item", "add_to_cart", "remove_from_cart", "view_cart", "add_shipping_info", "add_payment_info"]

    obj.forEach(element => {
        if (element.dimensionValues[0].value === 'view_item_list') {
            if (element.metricValues[0].value > 0) {
                view_item_list = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'view_item_list')
            }
        }
        else if (element.dimensionValues[0].value === 'view_item') {
            if (element.metricValues[0].value > 0) {
                view_item = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'view_item')
            }
        }
        else if (element.dimensionValues[0].value === 'select_item') {
            if (element.metricValues[0].value > 0) {
                select_item = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'select_item')
            }
        }
        else if (element.dimensionValues[0].value === 'add_to_cart') {
            if (element.metricValues[0].value > 0) {
                add_to_cart = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'add_to_cart')
            }
        }
        else if (element.dimensionValues[0].value === 'remove_from_cart') {
            if (element.metricValues[0].value > 0) {
                remove_from_cart = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'remove_from_cart')
            }
        }
        else if (element.dimensionValues[0].value === 'view_cart') {
            if (element.metricValues[0].value > 0) {
                view_cart = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'view_cart')
            }
        }
        else if (element.dimensionValues[0].value === 'add_shipping_info') {
            if (element.metricValues[0].value > 0) {
                add_shipping_info = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'add_shipping_info')
            }
        }
        else if (element.dimensionValues[0].value === 'add_payment_info') {
            if (element.metricValues[0].value > 0) {
                add_payment_info = element.metricValues[0].value
                insertList = insertList.filter(a => a !== 'add_payment_info')
            }
        }
        else if (element.dimensionValues[0].value === 'purchase') {
            if (element.metricValues[0].value > 0) {
                purchase = element.metricValues[0].value
            }
        }
    });
    // test 1:
    if (purchase > 0) {
        // test 2:
        if (view_item_list && view_item && select_item && add_to_cart && view_cart && add_shipping_info && add_payment_info && remove_from_cart) {
            // test 3:
            if (!(view_item_list >= view_item && view_item >= select_item) || !(add_to_cart >= remove_from_cart) || !(view_cart >= add_shipping_info && add_shipping_info >= add_payment_info && add_payment_info >= purchase)) {
                if (!(view_item_list >= view_item && view_item >= select_item)) {
                    let list1 = `The current ecommerce implementation is incorrect. (See Google developer guide <a href='https://developers.google.com/analytics/devguides/collection/ga4/ecommerce' target="_blank" >here</a>.) Errors detected: view_item_list >= view_item >= select_item is not true.`
                    let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: list1, pass_summary: '', fail_extra: reasons.test3_extra }
                    return data;
                } else if (!(add_to_cart >= remove_from_cart)) {
                    let list1 = `The current ecommerce implementation is incorrect. (See Google developer guide <a href='https://developers.google.com/analytics/devguides/collection/ga4/ecommerce' target="_blank" >here</a>.) Errors detected: add_to_cart >= remove_from_cart is not true.`
                    let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: list1, pass_summary: '', fail_extra: reasons.test3_extra }
                    return data;
                } else if (!(view_cart >= add_shipping_info && add_shipping_info >= add_payment_info && add_payment_info >= purchase)) {
                    let list1 = `The current ecommerce implementation is incorrect. (See Google developer guide <a href='https://developers.google.com/analytics/devguides/collection/ga4/ecommerce' target="_blank" >here</a>.) Errors detected: view_cart >= add_shipping_info >= add_payment_info >= purchase is not true.`
                    let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: list1, pass_summary: '', fail_extra: reasons.test3_extra }
                    return data;
                }
            } else {
                let data = { test_name: 'Ecommerce', status: true, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
                return data;
            }
        } else {
            let test2_summary_data = `Recommended ecommerce event ${insertList} is/are not currently collected.`
            let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: test2_summary_data, pass_summary: '', fail_extra: reasons.test2_extra }
            return data;
        }
    }
    else {
        let data = { test_name: 'Ecommerce', status: false, test_id: 'GA11', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }
}
