import { getUAReport } from '../../components/Api';
import { UAReportBehaviourLandings } from '../../components/Api';

const url = process.env.REACT_APP_BATCH_API

export const ConversionRateTest = async (accountId, webPropertyId, profileId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }

    let reasons = {
        pass_summary: "There are no significant changes in your conversion rates.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "Your conversion rate is decreasing at an extraordinary rate of [INSERT % VALUE] on [INSERT DATE/S] from the median rate. Recent changes may have decreased conversion rate, or there may be an error preventing conversion.",
        test1_extra: "A decreasing conversion rate may indicate an increase in low-quality traffic, the site's funnels becoming less optimised, or technical difficulties on the site."
    }
    let resultData = await getUAReport(`https://www.googleapis.com/analytics/v3/management/accounts/${accountId}/webproperties/${webPropertyId}/profiles/${profileId}/goals`, accessToken);

    if (resultData) {
        if (resultData === 401 || resultData === 403) {
            let data1 = { test_name: 'Conversion Rate', status: false, test_id: 'UA20', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data1;
        }
    }
    if (!resultData || resultData?.totalResults < 1 || Object.keys(resultData).length === 0) {
        let data1 = { test_name: 'Conversion Rate', status: false, test_id: 'UA20', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data1;
    }
    let resultObj = resultData?.items;
    let Goals = []
    for (let i = 0; i < resultObj.length; i++) {
        if (resultObj[i].active) {
            let flag = 0;
            // 60 days
            const params1 = JSON.stringify({
                "reportRequests":
                    [
                        {
                            "viewId": profileId,
                            "metrics": [{ "expression": `ga:goal${resultObj[i].id}ConversionRate` },
                            { "expression": `ga:sessions` },
                            ],
                            "dimensions": [{ "name": "ga:date" }],
                            "dateRanges": [
                                {
                                    "startDate": `60daysAgo`,
                                    "endDate": `yesterday`
                                }
                            ],
                            "orderBys": [
                                {
                                    "fieldName": "ga:date",
                                    "sortOrder": "DESCENDING"
                                }
                            ]
                        }
                    ]
            });

            let resultData1 = await UAReportBehaviourLandings(url, params1, accessToken)
            if (resultData1) {
                if (resultData1.code) {
                    let data1 = { test_name: 'Conversion Rate', status: false, test_id: 'UA20', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
                    return data1;
                }
            }
            // for 60days
            if (resultData1 && resultData1?.rows) {
                let DataAll = resultData1.rows;
                // 1 day
                for (let k = 0; k < 53; k++) {
                    if (DataAll[k]?.metrics && DataAll[k + 1]?.metrics) {

                        let cr1 = parseFloat(DataAll[k]?.metrics[0]?.values[0]);
                        let cr2 = parseFloat(DataAll[k + 1]?.metrics[0]?.values[0]);
                        let cr7 = parseFloat(DataAll[k + 7]?.metrics[0]?.values[0]);
                        // last 1 day to before 1 day and last 1 day to before 7day
                        if (cr2 - cr1 > cr2 * 0.2 && (cr7 - cr1 > cr7 * 0.2)) {
                            Goals.push(`<br/><span>&bullet;</span> ${((cr2 - cr1) * 100 / cr2).toFixed(2)}% on 1 day(${createDate(DataAll[k].dimensions[0])}) for goal ${resultObj[i].id} ${resultObj[i]?.name ? resultObj[i].name : ''}`)
                            flag = 1;
                            break;
                        }
                    }
                }
                if (flag === 0) {
                    // 3 days
                    for (let k = 0; k < 55; k++) {
                        if (DataAll[k]?.metrics && DataAll[k + 1]?.metrics && DataAll[k + 2]?.metrics && DataAll[k + 3]?.metrics && DataAll[k + 4]?.metrics && DataAll[k + 5]?.metrics) {

                            let cr1 = parseFloat(DataAll[k]?.metrics[0]?.values[0]) + parseFloat(DataAll[k + 1]?.metrics[0]?.values[0]) + parseFloat(DataAll[k + 2]?.metrics[0]?.values[0]);

                            let cr2 = parseFloat(DataAll[k + 3]?.metrics[0]?.values[0]) + parseFloat(DataAll[k + 4]?.metrics[0]?.values[0]) + parseFloat(DataAll[k + 5]?.metrics[0]?.values[0]);

                            if (cr2 - cr1 > cr2 * 0.2) {
                                Goals.push(`<br/><span>&bullet;</span> ${((cr2 - cr1) * 100 / cr2).toFixed(2)}% on 3 days(${createDate(DataAll[k + 2].dimensions[0])}, ${createDate(DataAll[k + 1].dimensions[0])} and ${createDate(DataAll[k].dimensions[0])}) for goal ${resultObj[i].id} ${resultObj[i]?.name ? resultObj[i].name : ''}`);
                                break;
                            }
                        }
                    }
                }
            }
        }
    }


    if (Goals.length > 0) {
        let test1_summary_data = `Your conversion rate is decreasing at an extraordinary rate of ` + `${Goals}` + `<br/>Recent changes may have decreased conversion rate, or there may be an error preventing conversion.`
        let data = { test_name: 'Conversion Rate', status: false, test_id: 'UA20', tool: 'UA', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra }
        return data;
    }

    let data1 = { test_name: 'Conversion Rate', status: true, test_id: 'UA20', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data1;
}
