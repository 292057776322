import React, { useEffect, useState } from 'react';
import { Container, Box, Button, Grid, Modal, Typography, List, ListItem } from '@mui/material';
import FreeOffer from './../../../../assets/images/free-offer.png';
import BannerTestShap from './../../../../assets/images/banner-test-shap.png';
import heroRightImg from './../../../../assets/images/hero_right_image.png';
import Arrow from './../../../../assets/images/white-arrow.png';
import SelectAccount from '../../../GaAccount/components/SelectAccount';
import { useNavigate, useLocation } from "react-router-dom";
import { GetAccountInfo,removeToken } from '../../../../components/Api';
import Login from '../../../../components/Login';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AccountUrl = process.env.REACT_APP_ACCOUNT_API
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleForError = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const Hero = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);

  const [accountList, setAcountList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAccount, setIsAccount] = useState(true);

  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false)
  };
  const handleErrorPopupClose = () => {
    setOpenErrorPopup(false)
    removeAccess()
    localStorage.clear()
    navigate('/', { state: {} })
  };
  let userDetails = localStorage.getItem('userDetails') ? localStorage.getItem('userDetails') : ''

  useEffect(() => {
    if(location?.state !==null && location?.state !==undefined){
      if(Object.keys(location?.state).length === 0 && accountList?.length > 0){
        setAcountList([])
      }
    }
  });

  useEffect(() => {
    if (location?.state?.error && location?.state?.error != '' && location?.state?.error !='undefined') {
      if (location?.state?.error?.details) {
      setOpenErrorPopup(true)
        setErrorMessage(location?.state?.error?.details)
      } 
      if(location?.state?.error === 'Network Error'){
        setOpenErrorPopup(true)
        setErrorMessage("Server is not responding")
      }
      if (location?.state?.error.error === 'access_denied') {
        setOpenErrorPopup(true)
        setErrorMessage("You do not have the sufficient permission.")
      }
    }
  }, [location?.state]);

  let accessToken = location?.state?.accessToken ? location?.state?.accessToken : localStorage.getItem('access_token')
  let expireAt = location?.state?.expire_at ? location?.state?.expire_at : localStorage.getItem('expire_at')
  const removeAccess = async () => {
    let url = await removeToken(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`)
  }
  const accountsList = async () => {
    let accountList = await GetAccountInfo(AccountUrl, accessToken)
    if (accountList === null || accountList === undefined) {
      setErrorMessage("No analytics account available!")
      setOpenErrorPopup(true)
      setIsAccount(false)
      localStorage.setItem('userDetails','')
      setAcountList([])
    } else if (Object.keys(accountList).length === 0) {
        setOpenErrorPopup(true)
        setIsAccount(false)
        localStorage.setItem('userDetails','')
        setErrorMessage("No analytics accounts available!")
        setAcountList([])
    } else{
      if (typeof (accountList) == 'string') {
        setIsAccount(false)
        setAcountList([])
        if (accountList == "UNAUTHENTICATED" || accountList == "PERMISSION_DENIED") {
          setOpenErrorPopup(true)
           setIsAccount(false)
           removeAccess()
           setErrorMessage("You do not have the sufficient permission.")
           localStorage.clear()
           navigate('/', { state: {} })
        }
      }
      else {
        setIsAccount(false)
        localStorage.setItem('accountList', JSON.stringify(accountList))
        accountList.sort((a, b) => {
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
          return 0;
        })
        setAcountList(accountList)
        setTimeout(() => {
          setOpen(accountList ? true : false)
        }, 100);

      }
    }
  }
  // console.log(accountList,userDetails,accessToken,isAccount,expireAt)
  useEffect(() => {
    if ((userDetails == '' || userDetails === 'null' || userDetails === 'undefined' || accessToken === 'null' || accessToken === 'undefined') && (accountList.length > 0 || accountList.length === 0)) {
      setAcountList([])
    }
  },[isAccount]);
  
  useEffect(() => {
    if ((expireAt != undefined || expireAt != null) && Math.round(new Date().getTime() / 1000) < expireAt) {
    if ((accountList.length === 0 || isAccount) && userDetails && userDetails != 'null' && userDetails != '' && userDetails != 'undefined' && accessToken && accessToken != 'undefined' && accessToken != '' ) {
      accountsList()
    }
  }
  });
  return (
    <Box>
      <Box className="banner-wrap"
        paddingY={{ xs: 6, md: 12 }}
      >
        <Modal
          open={openErrorPopup}
          onClose={handleErrorPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleForError} className="errorWarning-popup">
            <IconButton
              className='modal-close-btn'
              edge="start"
              color="inherit"
              onClick={handleErrorPopupClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <p id="parent-modal-description">
              {errorMessage}
            </p>
          </Box>
        </Modal>
        <Container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box position={'relative'}
                paddingRight={{ md: 5, lg: 14 }}
                paddingBottom={{ xs: 8, md: 0 }}
              >
                <Typography
                  variant="h2"
                  position={'relative'}
                  fontSize={{ xs: 30, sm: 40, md: 50, xl: 55 }}
                  // gutterBottom
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  <Box
                    className='bannerTestShap'
                    component={'img'}
                    src={BannerTestShap}
                    maxWidth={1}
                  />
                  Get A Free Google Analytics 4 Audit in Seconds With Our Automated Tool
                </Typography>
                <Box>
                  <List className='star-list'>
                      <ListItem>
                          <Typography component="p" fontSize={{ xs: 16, sm: 20}}>
                            90% of analytics configurations we come across are broken one way or another
                          </Typography>  
                      </ListItem>
                      <ListItem>
                          <Typography component="p" fontSize={{ xs: 16, sm: 20}}>
                            See what has gone wrong and learn how to fix your GA 4 (or UA) setup
                          </Typography>  
                      </ListItem>
                  </List>
                </Box>
                <Box className="googleButton" marginTop={1}>
                  {accountList.length === 0 || userDetails == '' || userDetails === 'null' || userDetails === 'undefined' ?
                    <Login endIcon={true}/>
                    :
                    <Button className='theme-btn' onClick={() => { setOpen(true) }} >Select Account 
                     <Box
                    component={'img'}
                    src={Arrow}
                    marginLeft={1}
                    width={25}
                  />
                   </Button>}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box position={'relative'}>
                <Box>
                  <Box
                    component={'img'}
                    src={heroRightImg}
                    maxWidth={1}
                    width={1}
                  />
                </Box>
                <Box className="offer-img">
                  <Box
                    component={'img'}
                    src={FreeOffer}
                    maxWidth={1}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="selectView-popup">
            <IconButton
              className='modal-close-btn'
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <SelectAccount accounts={accountList} accessToken={accessToken} />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Hero;
