import React, { useEffect, useState } from "react";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Grid, Select, FormControl, MenuItem, Typography, Button, Box, Alert } from '@mui/material';

// Setting Test
import { GetAccountInfo, backendApi } from '../../../components/Api';
import { GetGA4Info } from '../../../components/Api';
import { useNavigate, useLocation } from "react-router-dom";
import uaLogo from './../../../assets/images/ua.png';
import ga4Logo from './../../../assets/images/ga4.png';
import Loading from '../../../components/Loading'
const serverUrl = process.env.REACT_APP_SERVER_URL

export default function SelectAccount(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [account, setAccount] = useState(props.accounts ? props.accounts : []);
  const [property, setProperties] = useState([]);
  const [view, setView] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [viewId, setViewId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = props?.accessToken ? props?.accessToken : localStorage.getItem('access_token');
  const AccountUrl = process.env.REACT_APP_ACCOUNT_API
  const GA4Url = process.env.REACT_APP_GA4_URL
  const apiKey = process.env.REACT_APP_API_KEY
  const [loading, setLoading] = useState(true);
  let userDetails = localStorage.getItem('userDetails')
  userDetails = userDetails !== 'undefined' ? JSON.parse(userDetails) : userDetails
  let [accountName, setAccountName] = useState("")
  let [propertyName, setPropertyName] = useState("")
  let [viewName, setViewName] = useState("")

  const handleSubmit = async () => {
    if ((propertyId?.startsWith('properties') && accountId) || (viewId != "" && propertyId && accountId)) {
      const body =
      {
        user_id: userDetails.id,
        account: accountId,
        property: propertyId,
        view: viewId,
        accountName: accountName,
        propertyName: propertyName,
        viewName: viewName,
        tool: viewId ? "UA" : "GA4"
      }
      setLoading(true)
      let accountDetails = await backendApi(`${serverUrl}api/accounts`, body, userDetails.jwt_access_token)
      localStorage.setItem('accountDetails', JSON.stringify(accountDetails.data))
      localStorage.setItem('access_token', accessToken)
      navigate('/confirm-details', { state: { accountDetails, userDetails, accessToken } })
    }
    else { console.log("All fields required!"); }
  };
  useEffect(() => {
    if (accessToken) {
      setAccount(props.accounts)
    }
    if(account.length === 0){
      setErrorMessage("No analytics accounts available!")
    }
  }, []);

  useEffect(() => {
    if (!userDetails) {
      navigate('/')
    }
  }, []);

  const propertyList = async (e) => {
    let selected_acc = e.target.value.split(/,(.*)/s)
    setProperties([])
    setView([])
    setPropertyId('')
    setViewId('')
    setAccountId((selected_acc[0]))
    setAccountName(selected_acc[1])
    let url = `${AccountUrl}/${selected_acc[0]}/webproperties`;
    let propertyList = await GetAccountInfo(url, accessToken)
    setLoading(true)
    // for GA4
    let params = {
      filter: `parent:accounts/${selected_acc[0]}`,
      key: apiKey
    }
    let GAUrl = `${GA4Url}/properties`;
    let GA4Propertys = await GetGA4Info(GAUrl, params, accessToken)

    if (Object.keys(propertyList).length === 0) {
      if (Object.keys(GA4Propertys).length === 0) {
        setErrorMessage('No properties available in this account!')
        setTimeout(() => {
          setErrorMessage("")
        }, 5000);
        setLoading(false)
        localStorage.clear()
       navigate('/', { state: {} })
      }
    }
    if (propertyList == "PERMISSION_DENIED" || propertyList == "UNAUTHENTICATED") {
      setErrorMessage('Insufficient authentication access')
      setLoading(false)
      localStorage.clear()
     navigate('/', { state: {} })
    } else if (Object.keys(GA4Propertys).length === 0) {
      propertyList.sort((a, b) => {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      })
      setProperties(propertyList)
      setView([])
    }
    else {
      GA4Propertys = GA4Propertys?.properties ? GA4Propertys?.properties : []
      var combinedProperties = [...propertyList, ...GA4Propertys]
      combinedProperties.sort((a, b) => {
        if (a.name < b.name)
          return -1;
        if (a.name > b.name)
          return 1;
        return 0;
      })
      setProperties(combinedProperties)
      setErrorMessage("")
    }
  }
  const viewList = async (e) => {
    let propertyId = e.target.value.split(/,(.*)/s)
    setPropertyId(propertyId[0])
    setPropertyName(propertyId[1])
    setView([])
    setViewId('')
    if (propertyId[0]?.startsWith('properties')) {
      setView([])
    } else {
      let url = `${AccountUrl}/${accountId}/webproperties/${propertyId[0]}/profiles`;
      let viewList = await GetAccountInfo(url, accessToken)
      if (viewList == "PERMISSION_DENIED" || viewList == "UNAUTHENTICATED"){
        setErrorMessage('Insufficient authentication access')
        setLoading(false)
        localStorage.clear()
        navigate('/', { state: {} })
     }
      else
        viewList.sort((a, b) => {
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
          return 0;
        })
      setView(viewList)
    }
  }

  const viewDetails = (e) => {
    let selected_view = e.target.value.split(/,(.*)/s)
    setViewId(selected_view[0])
    setViewName(selected_view[1])
  }

  return (
    <Box>
      <Box>
        <Box marginBottom={{xs:1.5, sm:3, md:4}}>
          <Typography id="transition-modal-title" variant="h4" component="h4" fontWeight={'bold'} fontSize={{xs:22, sm:30, md:34}}>
            Select a view
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            color="error.main"
            gutterBottom
          >
            {errorMessage ?
              <Alert style={{ marginTop: '15px' }} severity="error">{errorMessage}</Alert> : ''}
          </Typography>
          <Grid container spacing={{xs:1, sm:2}}>
            <Grid item xs={12} md={4}>
              <Typography
                fontWeight={700}
                marginBottom={{xs:0.1, sm:0.7}}
              >
                Account
              </Typography>
              <FormControl required sx={{ m: 0, minWidth: 1, maxWidth: 1 }}>
                <Select
                  variant="outlined"
                  onChange={propertyList}
                  fullWidth
                >
                  {account.length > 0 && (account.sort((a, b) => b.name - a.name)).map((acc,index) =>
                    <MenuItem key={`acc-${index}`} value={`${acc.id},${acc.name}`} > {acc.name}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                fontWeight={700}
                marginBottom={{xs:0.1, sm:0.7}}
              >
                Property
              </Typography>

              <FormControl required sx={{ m: 0, minWidth: 1, maxWidth: 1 }}>
                {!property.length > 0 && accountId ?
                  <Loading loading={loading} /> :
                  <Select
                    disabled={!property.length > 0 ? true : false}
                    defaultValue={'Select Property'}
                    variant="outlined"
                    onChange={viewList}
                    fullWidth
                  >
                    {property?.map((pro,index) =>
                      <MenuItem  key={`pro-${index}`} value={pro.id ? `${pro.id},${pro.name}` : `${pro.name},${pro.displayName}`}> {(pro.name)?.startsWith('properties') ? pro.displayName : pro.name}
                        <Box sx={{display: 'inline-block', verticalAlign: 'middle'}}>
                          <Box
                            borderRadius={1}
                            marginLeft={1}
                            component={'img'}
                            src={(pro.name)?.startsWith('properties') ?  ga4Logo : uaLogo}
                            maxWidth={1}
                          />
                        </Box>
                      </MenuItem>
                    )}
                  </Select>
                }
              </FormControl>
            </Grid>
            {view.length > 0 ?
              <Grid item xs={12} md={4}>
                <Typography
                  fontWeight={700}
                  marginBottom={{xs:0.1, sm:0.7}}
                >
                  View
                </Typography>
                <FormControl required sx={{ m: 0, minWidth: 1, maxWidth: 1 }}>
                  <Select
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onChange={viewDetails}
                  >
                    {view?.map((v,index) =>
                      <MenuItem  key={`view-${index}`} value={`${v.id},${v.name}`}> {v.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid> : ""}
          </Grid>
          <Box textAlign={'center'} marginTop={{xs:2, sm:5}}>
            <Button
              className="theme-btn"
              onClick={handleSubmit}
              disabled={(propertyId?.startsWith('properties') && accountId) || (viewId != '' && propertyId && accountId) ? false : true}
              endIcon={<ArrowForward />} sx={{ minWidth: 180 }}>
              <span> Submit </span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}
