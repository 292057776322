import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, Contact, TermsConditions, NotFound, ConfirmDetails, AnalyticsReport, PrivacyPolicy } from './views';

export const Routing = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<><Home /></>} />
                    <Route exact path="/contact" element={<><Contact /></>} />
                    <Route exact path="/terms-conditions" element={<><TermsConditions /></>} />
                    <Route exact path="/privacy-policy" element={<><PrivacyPolicy /></>} />
                    <Route exact path="/confirm-details" element={<><ConfirmDetails /></>} />
                    <Route exact path="/analytics-report" element={<><AnalyticsReport /></>} />
                    <Route exact path="*" element={<><NotFound /></>} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}
