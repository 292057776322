import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const MonthOnMonthBRTestGA4 = async (propertyId, accessToken) => {
    const body = {
        "dateRanges": [{ "startDate": "60daysAgo", "endDate": "31daysAgo" },
        { "startDate": "30daysAgo", "endDate": "yesterday" }],
        "metrics": [{ "name": "engagementRate" }]
    };

    let result = await GA4Report(`${URL}/${propertyId}:runReport`, body, accessToken)

    // checking for error or no permission
    if (result.code) {
        let data = { test_name: 'Month On Month Engagement Rate', status: false, test_id: 'GA12', tool: 'GA4', difficulty_level: 2, fail_summary: "No permission.", pass_summary: '', fail_extra: '' }
        return data;
    }
    // checking for data
    else if (!result || !result?.data || !result?.data?.rows) {
        let data = { test_name: 'Month On Month Engagement Rate', status: false, test_id: 'GA12', tool: 'GA4', difficulty_level: 2, fail_summary: "No data available.", pass_summary: '', fail_extra: '' }
        return data;
    }

    let obj = result?.data?.rows
    let before30days = obj[0]?.metricValues[0].value;  // (60daysAgo to 31daysAgo)
    let last30days = obj[1]?.metricValues[0].value;    // (30daysAgo to yesterday)

    // check for month on month engagement rate decreased by 10%
    let check = parseFloat(before30days - last30days) > parseFloat(before30days * 0.1) ? false : true;

    if (before30days && !check) {
        let test1_summary_data = `The month-on-month engagement rate has decreased by ${((before30days - last30days) * 100 / before30days).toFixed(2)}% in last 30 days compared to previous period. This may indicate that the site is not serving content correctly. Learn more about engagement rate <a href='https://support.google.com/analytics/answer/12195621' target="_blank" >here</a>.`;

        let test1_extra_data = "A decrease in engagement rate can indicate that more users are not finding what they are expecting from the landing page. This could indicate that technical difficulties have become more common, that landing pages have become less optimised, or that you're getting new traffic from a less relevant source.";

        let data = { test_name: 'Month On Month Engagement Rate', status: false, test_id: 'GA12', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: test1_extra_data }
        return data;
    } else {
        let data = { test_name: 'Month On Month Engagement Rate', status: true, test_id: 'GA12', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: "Month-on-month engagement rate is stable.", fail_extra: '' }
        return data;
    }
}