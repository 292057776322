import { UAReportBehaviourLandings } from '../../components/Api';
const url = process.env.REACT_APP_BATCH_API
export const EcommerceTest = async (viewId, accessToken) => {
    let reasons = {
        pass_summary: "Enhanced ecommerce is set up and ecommerce interaction are properly being collected.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Ecommerce is not set up on your site. Learn more about <a href='https://support.google.com/analytics/answer/6014841' target="_blank" >enhanced ecommerce here</a>.`,
        test2_summary: "The event is not currently supported",
        test3_summary: `The current ecommerce implementation is incorrect. (See Google developer guide <a href='https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce' target="_blank" >here</a>.) Errors detected:`,
        test1_extra: "Tracking ecommerce transactions and other product-related interactions in Universal Analytics allows you to utilise the vast power of the Analytics engine to analyse where and how you're getting your conversions, tracing from acquisition to checkout.",
        test2_extra: "Tracking ecommerce transactions and other product-related interactions in Universal Analytics allows you to utilise the vast power of the Analytics engine to analyse where and how you're getting your conversions, tracing from acquisition to checkout.",
        test3_extra: "Tracking ecommerce transactions and other product-related interactions in Universal Analytics allows you to utilise the vast power of the Analytics engine to analyse where and how you're getting your conversions, tracing from acquisition to checkout."
    }

    const params = JSON.stringify({
        "reportRequests":
            [
                {
                    "viewId": viewId,
                    "metrics": [{ "expression": "ga:impressions" },
                    { "expression": "ga:transactions" },
                    { "expression": "ga:transactionRevenue" },
                    { "expression": "ga:productDetailViews" },
                    { "expression": "ga:productCheckouts" },
                    { "expression": "ga:productAddsToCart" },
                    { "expression": "ga:productListClicks" },
                    { "expression": "ga:productListViews" },
                    { "expression": "ga:productRemovesFromCart" }],
                    "dimensions": [{ "name": "ga:date" }],
                    "dateRanges": [
                        {
                            "startDate": "30daysAgo",
                            "endDate": "yesterday"
                        }
                    ]
                }
            ]
    });
    let resultData = await UAReportBehaviourLandings(url, params, accessToken)
    if (resultData) {
        if (resultData.code) {
            let data = { test_name: 'Ecommerce', status: false, test_id: 'UA11', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
            return data;
        }
    }
    if (!resultData || Object.keys(resultData.totals).length === 0) {
        let data = { test_name: 'Ecommerce', status: false, test_id: 'UA11', tool: 'UA', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData.totals[0].values;
    // Test 1: any trasections in last 30 days
    if (resultData.totals[0].values[1] < 1) {
        let data1 = { test_name: 'Ecommerce', status: false, test_id: 'UA11', tool: 'UA', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
        return data1;
    }
    let listOfEvent = ["impressions", "", "", "productDetail", "checkout", "addToCart", "productClick", "productView", "removeFromCart"]

    let InsertEvent = []

    if (obj[0] < 1) {
        InsertEvent.push(' ' + listOfEvent[0])
    }
    for (let i = 3; i < obj.length; i++) {
        if (obj[i] < 1) {
            InsertEvent.push(' ' + listOfEvent[i])
        }
    };

    let test2_summary_data = `The event${InsertEvent.length > 0 ?`s ${InsertEvent} are` : ` ${InsertEvent} is`} not currently supported.`;
    // Test 2: any other events in last 30 days

    if (parseFloat(obj[0]) < 1 || parseFloat(obj[3]) < 1 || parseFloat(obj[4]) < 1
        || parseFloat(obj[5]) < 1 || parseFloat(obj[6]) < 1
        || parseFloat(obj[7]) < 1 || parseFloat(obj[8]) < 1) {
        let data1 = { test_name: 'Ecommerce', status: false, test_id: 'UA11', tool: 'UA', difficulty_level: 2, fail_summary: test2_summary_data, pass_summary: '', fail_extra: reasons.test2_extra }
        return data1;
    }
    // Test 3: checking 3 conditions
    let showError = ''
    if (!(parseFloat(obj[0]) >= parseFloat(obj[7]) && parseFloat(obj[7]) >= parseFloat(obj[3]))) {
        showError = `The current ecommerce implementation is incorrect. (See Google developer guide here.) Errors detected: impressions >= productView >= productDetails is not true.`
    }
    else if (!(parseFloat(obj[5]) >= parseFloat(obj[8]))) {
        showError = `The current ecommerce implementation is incorrect. (See Google developer guide here.) Errors detected: addToCart >= removeFromCart is not true.`
    } else if (!(parseFloat(obj[4]) >= parseFloat(obj[1]))) {
        showError = `The current ecommerce implementation is incorrect. (See Google developer guide here.) Errors detected: checkout >= purchase is not true.`
    }

    if (!(parseFloat(obj[0]) >= parseFloat(obj[7]) && parseFloat(obj[7]) >= parseFloat(obj[3]))
        || !(parseFloat(obj[5]) >= parseFloat(obj[8]))
        || !(parseFloat(obj[4]) >= parseFloat(obj[1]))) {
        let data1 = { test_name: 'Ecommerce', status: false, test_id: 'UA11', tool: 'UA', difficulty_level: 2, fail_summary: showError, pass_summary: '', fail_extra: reasons.test3_extra }
        return data1;
    }

    let data = { test_name: 'Ecommerce', status: true, test_id: 'UA11', tool: 'UA', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}
