import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const ContentGroupTestGA4 = async (propertyId, accessToken) => {
    let reasons = {
        pass_summary: "Related pages in your website are categorized into custom groups.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: `Related pages in your website are not categorized into custom groups. (Learn how to <a href='https://support.google.com/analytics/answer/11523339' target="_blank" >set up a content group</a> using the Google tag or Google Tag Manager.)`,
        test1_extra: "With content groups, you can categorize pages and screens into custom buckets so you can see metrics for related groups of information.",
    }
    const params = JSON.stringify({
        "dateRanges": [
            {
                "startDate": "30daysAgo",
                "endDate": "yesterday",
            }
        ],
        "dimensions": [
            {
                "name": "contentGroup"
            },
            {
                "name": "eventName"
            }
        ],
        "metrics": [
            {
                "name": "eventCount"
            }
        ]
    });

    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params, accessToken)
    if (resultData.code) {
        let data = { test_name: 'Content Group', status: false, test_id: 'GA09', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    // no data for content group then fail
    if (!resultData || !resultData.data || !resultData.data.rows) {
        let data = { test_name: 'Content Group', status: false, test_id: 'GA09', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData.data.rows
    for (let i = 0; i < resultData.data.rowCount; i++) {
        if (obj[i].dimensionValues[0].value !== "(not set)" && obj[i].dimensionValues[1].value === "page_view") {
            // eventName === page_view then pass
            let data = { test_name: 'Content Group', status: true, test_id: 'GA09', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
            return data;
        }
    };
    let data = { test_name: 'Content Group', status: false, test_id: 'GA09', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.test1_summary, pass_summary: '', fail_extra: reasons.test1_extra }
    return data;
}
