import { GA4Report } from '../../components/Api';
const URL = process.env.REACT_APP_GA4_URL_DATA

export const TrafficSpikeTestGA4 = async (propertyId, accessToken) => {
    function createDate(day) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        var today = day.slice(6, 8) + ' ' + monthNames[parseInt(day.slice(4, 6)) - 1] + ' ' + day.slice(0, 4)
        return today;
    }
    let reasons = {
        pass_summary: "There are no significant spikes in your month-on-month traffic in the past 60 days.",
        no_data: "No data available.",
        no_permission: "No permission.",
        test1_summary: "A positive traffic spike has been detected on [INSERT DATE/S] at [INSERT %]. Ensure that your infrastructure is ready to handle increased load.",
        test1_extra: "A traffic spike typically indicates that there has been a recent event which has affected how much traffic is arriving from one of your traffic sources. A negative spike might indicate the end of a marketing campaign, a link from a major referrer being removed or delisting from a search engine. A positive spike might indicate the start of a marketing campaign, a new source of traffic via referrals, or an improvement in ranking on a search engine.",
    }
    const params3 = JSON.stringify({
        "dimensions": [{ "name": "date" }],
        "metrics": [{ "name": "sessions" }],
        "dateRanges": [{ "startDate": `60daysAgo`, "endDate": `yesterday` }],
        "keepEmptyRows": true,
        "orderBys": [
            {
                "dimension": {
                    "dimensionName": "date",
                },
                "desc": true
            }
        ]
    });

    let resultData = await GA4Report(`${URL}/${propertyId}:runReport`, params3, accessToken);
    if (resultData?.code) {
        let data = { test_name: 'Traffic Spikes', status: false, test_id: 'GA15', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_permission, pass_summary: '', fail_extra: '' }
        return data;
    }
    if (!resultData || !resultData?.data || !resultData?.data?.rows) {
        let data = { test_name: 'Traffic Spikes', status: false, test_id: 'GA15', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    let obj = resultData.data.rows
    if (obj.length < 1) {
        let data = { test_name: 'Traffic Spikes', status: false, test_id: 'GA15', tool: 'GA4', difficulty_level: 2, fail_summary: reasons.no_data, pass_summary: '', fail_extra: '' }
        return data;
    }
    // 1 day and 30%
    for (let i = 0; i < 53; i++) {
        if (obj[i]?.metricValues && obj[i + 1]?.metricValues && obj[i + 7]?.metricValues) {

            let last1day = parseFloat(obj[i].metricValues[0].value)
            let before1day = parseFloat(obj[i + 1].metricValues[0].value)
            let before7day = parseFloat(obj[i + 7].metricValues[0].value)

            let check1 = (last1day - before1day) > (before1day * 0.3) ? false : true
            let check2 = (last1day - before7day) > (before7day * 0.3) ? false : true

            if (!check1 && !check2) {
                let test1_summary_data = `A positive traffic spike has been detected on 1 day(${createDate(obj[i].dimensionValues[0].value)}) at  ${before1day > 0 ? parseFloat((last1day - before1day) * 100 / before1day).toFixed(2) : last1day}%. Ensure that your infrastructure is ready to handle increased load.`

                let data1 = {
                    test_name: 'Traffic Spikes',
                    status: check1, test_id: 'GA15', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
                }
                return data1;
            }
        }
    }
    // 3 day and 50%
    for (let i = 0; i < 55; i++) {
        if (obj[i]?.metricValues && obj[i + 1]?.metricValues && obj[i + 3]?.metricValues && obj[i + 2]?.metricValues && obj[i + 4]?.metricValues && obj[i + 5]?.metricValues) {

            let last3days = parseFloat(obj[i].metricValues[0].value) + parseFloat(obj[i + 1].metricValues[0].value) + parseFloat(obj[i + 2].metricValues[0].value)

            let before3days = parseFloat(obj[i + 3].metricValues[0].value) + parseFloat(obj[i + 4].metricValues[0].value) + parseFloat(obj[i + 5].metricValues[0].value)

            let check1 = (parseFloat(last3days - before3days)) > parseFloat(before3days * 0.5) ? false : true

            if (!check1) {
                let test1_summary_data = `A positive traffic spike has been detected on 3 days(${createDate(obj[i + 2].dimensionValues[0].value)}, ${createDate(obj[i + 1].dimensionValues[0].value)}, ${createDate(obj[i].dimensionValues[0].value)}) at ${before3days > 0 ? parseFloat((last3days - before3days) * 100 / before3days).toFixed(2) : parseFloat(last3days / 3).toFixed(2)}%. Ensure that your infrastructure is ready to handle increased load.`
                let data1 = {
                    test_name: 'Traffic Spikes',
                    status: check1, test_id: 'GA15', tool: 'GA4', difficulty_level: 2, fail_summary: test1_summary_data, pass_summary: '', fail_extra: reasons.test1_extra
                }
                return data1;
            }
        }
    }

    let data = { test_name: 'Traffic Spikes', status: true, test_id: 'GA15', tool: 'GA4', difficulty_level: 2, fail_summary: '', pass_summary: reasons.pass_summary, fail_extra: '' }
    return data;
}