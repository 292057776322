import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Container,Alert, Modal } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { backendApi, UAReport,GA4Report,removeToken } from '../../../components/Api';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const serverUrl = process.env.REACT_APP_SERVER_URL
const batchUrl = process.env.REACT_APP_BATCH_API
const ga4Uurl = process.env.REACT_APP_GA4_URL_DATA

const Content = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [optionalTest, setOptionalTest] = useState({
        siteSearch: '',
        toolsIntegration: '',
        campaigns: '',
        ecommerce: ''
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTag, setErrorTag] = useState('');
    const [open, setOpen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const handleClose = () => {
        setOpen(false)
        setErrorMessage("")
        if(isLogout){
            localStorage.clear()
            removeAccess()
            navigate('/', { state: {} })
        }
        navigate('/')
    };
    let accessToken = location?.state?.accessToken ? location?.state?.accessToken : localStorage.getItem('access_token')

    const handleChange = (name, value) => {
        setOptionalTest({ ...optionalTest, [name]: value })
    }
    let userDetails = location?.state?.userDetails
    let accountDetails = location?.state?.accountDetails?.data
    let accountId = accountDetails?.account
    let propertyId = accountDetails?.property
    let viewId = accountDetails?.view
    
    useEffect(() => {
        if (!userDetails || !accountDetails) {
            navigate('/')
        }
        window.scrollTo(0, 0)
        checkTokenExpire()
        if(viewId){
            checkUAPermission()
        } else {
            checkGA4Permission()
        }
    }, []);

    const removeAccess = async () => {
        let url = await removeToken(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`)
      }
    const checkTokenExpire = async () => {
        const body = JSON.stringify({
            "dateRanges": [{ "startDate": "30daysAgo", "endDate": "yesterday" }],
            "dimensions": [{ "name": "date" }],
            "metrics": [{ "name": "eventCount" }]
        });
        let expireTime = await removeToken(`https://oauth2.googleapis.com/tokeninfo?access_token=${accessToken}`)
        if ((expireTime?.data !== undefined  && expireTime?.data?.expires_in<= 305) || expireTime?.error ==='invalid_token') {
            setErrorMessage("Your session will expire within 5 minutes, for further test please re-login !")
            setErrorTag('SESSION ALERT')
            setIsLogout(true)
            setOpen(true)
        }
    }
    const checkUAPermission = async () => {
        const param = JSON.stringify({
            "reportRequests":
                [
                    {
                        "viewId": viewId,
                        "metrics": [{ "expression": "ga:hits" }],
                        "dimensions": [{ "name": "ga:date" }]
                    }
                ]
        });
        let resultData = await UAReport(batchUrl, param, accessToken)
        let reportStatus = resultData?.response?.data?.error?.status ? resultData?.response?.data?.error?.status : ''
        if (resultData?.code && (reportStatus === 'PERMISSION_DENIED' || reportStatus === 'RESOURCE_EXHAUSTED' )) {
            setErrorMessage("You do not have the sufficient permission for test !")
            setErrorTag('PERMISSION_DENIED')
            setOpen(true)
        }
    }

    const checkGA4Permission = async () => {
        const body = JSON.stringify({
            "dateRanges": [{ "startDate": "30daysAgo", "endDate": "yesterday" }],
            "dimensions": [{ "name": "date" }],
            "metrics": [{ "name": "eventCount" }]
        });
        let resultData = await GA4Report(`${ga4Uurl}/${propertyId}:runReport`, body, accessToken)
        let reportStatus = resultData?.response?.data?.error?.status ? resultData?.response?.data?.error?.status : ''
        if (resultData?.code && (reportStatus === 'PERMISSION_DENIED' || reportStatus === 'RESOURCE_EXHAUSTED' )) {
            setErrorMessage("You do not have the sufficient permission for test !")
            setErrorTag('PERMISSION_DENIED')
            setOpen(true)
        }
    }

    const handleSubmit = async () => {
        if(optionalTest.campaigns === '' || optionalTest.ecommerce === '' || optionalTest.siteSearch ==='' || optionalTest.toolsIntegration === ''){
            setErrorMessage("Please select Yes/No")
            setTimeout(() => {
                setErrorMessage("")
              }, 3000);
        } else {
            const body =
            {
                user_id: userDetails.id,
                account_id: accountDetails.account_id,
                tool: viewId ? "UA" : "GA4"
            }
            let averageScore = await backendApi(`${serverUrl}api/analyticsreport/data`, body, userDetails.jwt_access_token)
            setLoading(true)
            localStorage.setItem(`isTestRun${accountDetails?.account_id}`, true)
            navigate('/analytics-report', { state: { optionalTest, accessToken,averageScore, obj: { accountId, propertyId, viewId } } })
        }
    }
    
    return (
        <>
            <Box>
                <Container className='space'>
                    <Box>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className="errorWarning-popup">
                                <IconButton
                                    className='modal-close-btn'
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <h2 id="parent-modal-title">{errorTag}</h2>
                                <p id="parent-modal-description">
                                    {errorMessage}
                                </p>
                            </Box>
                        </Modal>
                        <Box marginBottom={{xs:4, sm:5}}>
                            <Box>
                                <Typography variant='p' component={'p'} fontSize={{xs:16, sm:20, md:24}} lineHeight={1.4}>Can users search your site for content or products using an on-site search bar?</Typography>
                            </Box>
                            <Box marginTop={{xs:2, sm:3}}>
                                <Button className= {optionalTest.siteSearch ? "theme-btn outline active" : optionalTest.siteSearch === ''  ? 'theme-btn outline' : "theme-btn outline" } sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginRight: {xs:1, sm:3}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('siteSearch', true) }}
                                    name="siteSearch"
                                    >Yes</Button>
                                <Button className= {optionalTest.siteSearch === '' ? "theme-btn outline" : optionalTest.siteSearch ? 'theme-btn outline': "theme-btn active" }  sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('siteSearch', false) }}>No</Button>
                            </Box>
                        </Box>
                        <Box marginBottom={{xs:4, sm:5}}>
                            <Box>
                                <Typography variant='p' component={'p'}  fontSize={{xs:16, sm:20, md:24}}>Do you use Google Ads to acquire users?</Typography>
                            </Box>
                            <Box marginTop={{xs:2, sm:3}}>
                                <Button className= {optionalTest.toolsIntegration ? "theme-btn outline active" : optionalTest.toolsIntegration === ''  ? 'theme-btn outline' : "theme-btn outline" } sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginRight: {xs:1, sm:3}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('toolsIntegration', true) }}
                                    name="toolsIntegration"
                                    >Yes</Button>
                                <Button className= {optionalTest.toolsIntegration === '' ? "theme-btn outline" : optionalTest.toolsIntegration ? 'theme-btn outline': "theme-btn active" }  sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('toolsIntegration', false) }}>No</Button>
                            </Box>
                        </Box>
                        <Box marginBottom={{xs:4, sm:5}}>
                            <Box>
                                <Typography variant='p' component={'p'}  fontSize={{xs:16, sm:20, md:24}}>Do you have any non-Google Ads paid marketing campaigns and/or email marketing campaigns?</Typography>
                            </Box>
                            <Box marginTop={{xs:2, sm:3}}>
                                <Button className= {optionalTest.campaigns ? "theme-btn outline active" : optionalTest.campaigns === ''  ? 'theme-btn outline' : "theme-btn outline"} sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginRight: {xs:1, sm:3}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('campaigns', true) }}
                                    name="campaigns"
                                    >Yes</Button>
                                <Button className= {optionalTest.campaigns === '' ? "theme-btn outline" : optionalTest.campaigns ? 'theme-btn outline': "theme-btn active" }  sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('campaigns', false) }}>No</Button>
                            </Box>
                        </Box>
                        <Box marginBottom={{xs:4, sm:5}}>
                            <Box>
                                <Typography variant='p' component={'p'}  fontSize={{xs:16, sm:20, md:24}}>Do you sell any products directly from your site?</Typography>
                            </Box>

                            <Box marginTop={{xs:2, sm:3}}>
                                <Button className= {optionalTest.ecommerce ? "theme-btn outline active" : optionalTest.ecommerce === ''  ? 'theme-btn outline' : "theme-btn outline" } sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginRight: {xs:1, sm:3}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('ecommerce', true) }}
                                    name="ecommerce"
                                    >Yes</Button>
                                <Button className= {optionalTest.ecommerce === '' ? "theme-btn outline" : optionalTest.ecommerce ? 'theme-btn outline': "theme-btn active" }  sx={{ width: 1, maxWidth: {xs:120, sm:200, md:255}, marginBottom: 1 }} disabled={loading}
                                    onClick={() => { handleChange('ecommerce', false) }}>No</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Button className='theme-btn' disabled={loading}
                            onClick={() => { handleSubmit() }}
                            sx={{ width: 1 }}>Submit</Button>
                    </Box>
                    {errorMessage ?
                  <Alert style={{ marginTop: '15px' }} severity="error">{errorMessage}</Alert> : ''}
                </Container>
            </Box>
        </>
    );
};

export default Content;