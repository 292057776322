import { useEffect, React } from 'react';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main/Main';
import Poweredby from './../Poweredby/PoweredBy'
import {
  Features,
  Services,
  Hero, ClientsLogo, MookReport, Testimonials
} from './components';

const IndexView = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Main bgcolor={'background.paper'}>
        <Hero />
        <Box>
          <Box>
            <ClientsLogo />
          </Box>
          <Box>
            <Features />
          </Box>
          <Box>
            <Services />
          </Box>
          <Box>
            <MookReport />
          </Box>
          <Box>
            <Testimonials />
          </Box>
          <Box>
          </Box>
        </Box>
        <Box>
          <Poweredby />
        </Box>
      </Main>
    </Box>
  );
};

export default IndexView;
